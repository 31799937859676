import React, { memo } from 'react';

import * as Component from './styled';

interface Props {
    color?: 'primary' | 'secondary';
    size?: 'small' | 'medium';
    label?: string;
    name?: string;
    value?: string;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputTextSearch: React.FC<Props> = ({
    color,
    size,
    label,
    name,
    value,
    error,
    helperText,
    disabled,
    onChange,
}) => (
    <Component.InputCustom
        color={color}
        size={size}
        fullWidth
        placeholder={label}
        variant="outlined"
        name={name}
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText}
        autoComplete="off"
        disabled={disabled}
        InputProps={{
            endAdornment: (
                <Component.InputAdornment position="end">
                    <Component.SearchIcon />
                </Component.InputAdornment>
            ),
        }}
    />
);

export default memo(InputTextSearch);
