import { useMemo, useState, useContext, createContext, useCallback, useEffect, ChangeEvent, MouseEvent } from 'react';

import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DropResult } from '@hello-pangea/dnd';

import api, { axios } from 'service/api';

import { setPrefix, generateNumber, isEmpty } from 'utils';

import { useNotification } from 'hooks/notification';
import { useAuth } from 'hooks/auth';
import { useDrawer } from 'hooks/drawer';

import {
    PropsFields,
    PropsError,
    PropsConfirm,
    PropsOpenConfirm,
    PropsModules,
    PropsLessons,
    PropsAttachmentLesson,
    PropsModalQuiz,
    PropsModalVideoDynamic,
} from 'pages/modules/ead/course/models';

interface Context {
    loading: boolean;
    loadingForm: boolean;
    course_id: string | undefined;
    fields: PropsFields;
    error: PropsError;
    expanded: number | false;
    expandedLesson: number | false;
    confirm: PropsConfirm;
    quizDetails: PropsModalQuiz;
    videoDynamic: PropsModalVideoDynamic;
    handleGoBack(): void;
    handleAddModule(): void;
    handleToggleModule(value: number): void;
    handleDragEndModule: ({ destination, source }: DropResult) => void;
    handleChange(
        event: ChangeEvent<HTMLInputElement>,
        group?: string,
        module_id?: number,
        lesson_id?: number,
        name?: string,
    ): void;
    handleAddLesson(module_id: number): void;
    handleRemoveAttachment(
        file_id: number | null,
        file_name: string,
        url: string,
        module_id?: number,
        lesson_id?: number,
    ): void;
    handleToggleLesson(value: number): void;
    handleDragEndLesson: ({ destination, source }: DropResult, module_id: number) => void;
    handleDragEndAttachment: ({ destination, source }: DropResult, module_id: number, lesson_id: number) => void;
    handleOpenConfirm(event: MouseEvent, confirm: PropsOpenConfirm): void;
    handleOpenQuiz(
        module_id: number,
        fake_lesson_id: number,
        lesson_id: number | null,
        lesson_name: string,
        lesson_type: string,
        attempts: number,
    ): void;
    handleUpdateQuiz(quizDetails: PropsModalQuiz): void;
    handleCloseConfirm(): void;
    handleCloseQuiz(): void;
    handleOpenVideoDynamic(
        module_id: number,
        fake_lesson_id: number,
        lesson_id: number | null,
        lesson_name: string,
        lesson_type: string,
    ): void;
    handleCloseVideoDynamic(): void;
    handleConfirm(): void;
    handleSaveCourse(event: ChangeEvent<HTMLFormElement>): void;
    handlePublishCourse(publishValue: number): void;
    handleSaveModule(id: number, module_id: number | null, module_name: string): void;
    handleSaveLesson(fake_module_id: number, module_id: number | null, lesson: PropsLessons): void;
    notAllowedToChange(): void;
}

export const CourseContext = createContext<Context>({
    loading: false,
    loadingForm: false,
    course_id: '',
    fields: {
        name: '',
        categories: [],
        category: '',
        companies: [],
        company: '',
        publish: 0,
        publish_at: null,
        instructor: '',
        classification: '',
        amount_points: '',
        enable_time: '0',
        type_time: 'M',
        time_limit: '',
        poster: {
            file: null,
            file_name: '',
            type: null,
            url: null,
        },
        modules: [],
    },
    error: {
        name: '',
        category: '',
        instructor: '',
        classification: '',
        amount_points: '',
        company: '',
        poster: '',
        time_limit: '',
        modules: [],
    },
    expanded: false,
    expandedLesson: false,
    confirm: {
        open: false,
        status: '',
        funcSubmit: '',
        title: '',
        text: '',
        message: '',
        fakeModuleId: '',
        moduleId: '',
        fakeLessonId: '',
        lessonId: '',
        attachmentId: '',
    },
    quizDetails: {
        open: false,
        status: '',
        title: '',
        text: '',
        message: '',
        typeLesson: '',
        attempts: 0,
        fakeModuleId: null,
        moduleId: null,
        fakeLessonId: null,
        lessonId: null,
        lessonName: '',
        fakeQuestionId: null,
        questionId: null,
        fakeAlternativeId: null,
        alternativeId: null,
    },
    videoDynamic: {
        open: false,
        status: '',
        title: '',
        text: '',
        message: '',
        typeLesson: '',
        fakeModuleId: null,
        moduleId: null,
        fakeLessonId: null,
        lessonId: null,
        lessonName: '',
    },
    handleGoBack: () => {},
    handleAddModule: () => {},
    handleToggleModule: (value: number) => {},
    handleDragEndModule: ({ destination, source }: DropResult) => {},
    handleChange: (
        event: ChangeEvent<HTMLInputElement>,
        group?: string,
        module_id?: number,
        lesson_id?: number,
        name?: string,
        time_limit?: string,
    ) => {},
    handleAddLesson: (module_id: number) => {},
    handleRemoveAttachment: (
        file_id: number | null,
        file_name: string,
        url: string,
        module_id?: number,
        lesson_id?: number,
    ) => {},
    handleToggleLesson: (value: number) => {},
    handleDragEndLesson: ({ destination, source }: DropResult, module_id: number) => {},
    handleDragEndAttachment: ({ destination, source }: DropResult, module_id: number, lesson_id: number) => {},
    handleOpenConfirm: (event: MouseEvent, confirm: PropsOpenConfirm) => {},
    handleOpenQuiz: (
        module_id: number,
        fake_lesson_id: number,
        lesson_id: number | null,
        lesson_name: string,
        lesson_type: string,
        attempts: number,
    ) => {},
    handleUpdateQuiz: (quizDetails: PropsModalQuiz) => {},
    handleCloseConfirm: () => {},
    handleCloseQuiz: () => {},
    handleOpenVideoDynamic: (
        module_id: number,
        fake_lesson_id: number,
        lesson_id: number | null,
        lesson_name: string,
        lesson_type: string,
    ) => {},
    handleCloseVideoDynamic: () => {},
    handleConfirm: () => {},
    handleSaveCourse: (event: ChangeEvent<HTMLFormElement>) => {},
    handlePublishCourse: (publishValue: number) => {},
    handleSaveModule: (id: number, module_id: number | null, module_name: string) => {},
    handleSaveLesson: (fake_module_id: number, module_id: number | null, lesson: PropsLessons) => {},
    notAllowedToChange: () => {},
});

export function useCourse() {
    const context = useContext(CourseContext);
    return context;
}

const reorder = (list: Object[], startIndex: number, endIndex: number): Object[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export function useCourseProvider() {
    const { t: translate } = useTranslation();

    const { course_id } = useParams();
    const navigate = useNavigate();

    const { profile, signOut } = useAuth();
    const { onActivated } = useDrawer();
    const { openNotification } = useNotification();

    const inititalFields = useMemo(
        () => ({
            name: '',
            categories: [],
            category: '',
            classification: '',
            amount_points: '',
            companies: [],
            company: '',
            publish: 0,
            publish_at: null,
            instructor: '',
            enable_time: '0',
            type_time: 'M',
            time_limit: '',
            poster: {
                file: null,
                file_name: '',
                type: null,
                url: null,
            },
            modules: [],
        }),
        [],
    );

    const inititalError = useMemo(
        () => ({
            name: '',
            category: '',
            instructor: '',
            classification: '',
            amount_points: '',
            company: '',
            poster: '',
            modules: [],
            time_limit: '',
        }),
        [],
    );

    const initialConfirm = useMemo(
        () => ({
            open: false,
            status: '',
            funcSubmit: '',
            title: '',
            text: '',
            message: '',
            fakeModuleId: '',
            moduleId: '',
            fakeLessonId: '',
            lessonId: '',
        }),
        [],
    );

    const initialQuizDetails = useMemo(
        () => ({
            open: false,
            status: '',
            title: '',
            text: '',
            message: '',
            typeLesson: '',
            attempts: 0,
            fakeModuleId: null,
            moduleId: null,
            fakeLessonId: null,
            lessonId: null,
            fakeQuestionId: null,
            questionId: null,
            fakeAlternativeId: null,
            alternativeId: null,
        }),
        [],
    );

    const initialVideoDynamic = useMemo(
        () => ({
            open: false,
            status: '',
            title: '',
            text: '',
            message: '',
            typeLesson: '',
            fakeModuleId: null,
            moduleId: null,
            fakeLessonId: null,
            lessonId: null,
            lessonName: '',
        }),
        [],
    );

    const [loading, setLoading] = useState(true);
    const [loadingForm, setLoadingForm] = useState(false);
    const [expandedLesson, setExpandedLesson] = useState<number | false>(false);
    const [fields, setFields] = useState<PropsFields>(inititalFields);
    const [error, setError] = useState<PropsError>(inititalError);
    const [expanded, setExpanded] = useState<number | false>(false);
    const [confirm, setConfirm] = useState(initialConfirm as PropsConfirm);
    const [quizDetails, setQuizDetails] = useState(initialQuizDetails as PropsModalQuiz);
    const [videoDynamic, setVideoDynamic] = useState(initialVideoDynamic as PropsModalVideoDynamic);

    const handleGoBack = useCallback(() => {
        navigate(setPrefix('/ead'));
    }, [navigate]);

    const notAllowedToChange = useCallback(() => {
        openNotification(translate('validation.notAllowedToChange'), 'error');
    }, [openNotification, translate]);

    const onResponseShowWithCompanies = useCallback((responses: any) => {
        if (responses[0]) {
            const {
                data: { dataCategory },
            } = responses[0];

            setFields((oldFields) => ({
                ...oldFields,
                categories:
                    dataCategory?.map((item: any) => ({
                        title: item.description,
                        value: item.id,
                    })) ?? [],
            }));
        }
        if (responses[1]) {
            const {
                data: { dataCompany },
            } = responses[1];

            setFields((oldFields) => ({
                ...oldFields,
                companies:
                    dataCompany?.map((item: any) => ({
                        title: item.name,
                        value: item.id,
                    })) ?? [],
            }));
        }
        if (responses[2]) {
            const {
                data: { dataCourse, dataModule },
            } = responses[2];

            setFields((oldFields) => ({
                ...oldFields,
                ...{
                    name: dataCourse?.course_name,
                    category: dataCourse?.category_id,
                    company: isEmpty(dataCourse?.company_id),
                    publish: dataCourse?.publish ?? 0,
                    publish_at: dataCourse?.publish_at ?? null,
                    instructor: dataCourse?.course_teacher_name,
                    classification: isEmpty(dataCourse?.free_to_all),
                    amount_points: isEmpty(dataCourse?.amount_points),
                    enable_time: String(dataCourse?.enable_time ?? '0'),
                    type_time: dataCourse?.type_time ?? 'M',
                    time_limit: isEmpty(dataCourse?.time_limit),
                    poster: {
                        file: null,
                        file_name: '',
                        type: dataCourse?.ext,
                        url: dataCourse?.url,
                    },
                    modules:
                        dataModule?.map((module: any) => {
                            return {
                                id: generateNumber(10000, 100000),
                                module_id: module?.course_module_id,
                                title: module?.module_name,
                                lessons: module.dataLesson.map((lesson: any) => ({
                                    id: generateNumber(10000, 100000),
                                    type: String(lesson?.is_file ?? ''),
                                    lesson_id: lesson.lesson_id,
                                    title: lesson.lesson_name,
                                    attempts: String(lesson.attempts),
                                    video: {
                                        file: null,
                                        file_name: '',
                                        type: lesson?.ext,
                                        url: lesson?.url,
                                    },
                                    attachments: lesson.file?.map((attachment: any) => ({
                                        id: String(attachment.id),
                                        file_id: attachment.file_id,
                                        file_name: attachment.file_name_original,
                                        type: attachment.ext,
                                        url: attachment.url,
                                        file: null,
                                    })),
                                    time_limit: lesson.time_limit,
                                    color: lesson?.color ?? '#000000',
                                })),
                            };
                        }) ?? [],
                },
            }));
        }
    }, []);

    const onResponseShow = useCallback((responses: any) => {
        if (responses[0]) {
            const {
                data: { dataCategory },
            } = responses[0];

            setFields((oldFields) => ({
                ...oldFields,
                categories:
                    dataCategory?.map((item: any) => ({
                        title: item.description,
                        value: item.id,
                    })) ?? [],
            }));
        }
        if (responses[1]) {
            const {
                data: { dataCourse, dataModule },
            } = responses[1];
            setFields((oldFields) => ({
                ...oldFields,
                ...{
                    name: dataCourse?.course_name,
                    category: dataCourse?.category_id,
                    publish: dataCourse?.publish ?? 0,
                    publish_at: dataCourse?.publish_at ?? null,
                    instructor: dataCourse?.course_teacher_name,
                    classification: isEmpty(dataCourse?.free_to_all),
                    amount_points: isEmpty(dataCourse?.amount_points),
                    enable_time: String(dataCourse?.enable_time ?? '0'),
                    type_time: dataCourse?.type_time ?? 'M',
                    time_limit: isEmpty(dataCourse?.time_limit),
                    poster: {
                        file: null,
                        file_name: '',
                        type: dataCourse?.ext,
                        url: dataCourse?.url,
                    },
                    modules:
                        dataModule?.map((module: any) => {
                            return {
                                id: generateNumber(10000, 100000),
                                module_id: module?.course_module_id,
                                title: module?.module_name,
                                lessons: module.dataLesson.map((lesson: any) => ({
                                    id: generateNumber(10000, 100000),
                                    type: String(lesson?.is_file ?? ''),
                                    lesson_id: lesson.lesson_id,
                                    title: lesson.lesson_name,
                                    attempts: String(lesson.attempts),
                                    video: {
                                        file: null,
                                        file_name: '',
                                        type: lesson?.ext,
                                        url: lesson?.url,
                                    },
                                    attachments: lesson.file?.map((attachment: any) => ({
                                        id: String(attachment.id),
                                        file_id: attachment.file_id,
                                        file_name: attachment.file_name_original,
                                        type: attachment.ext,
                                        url: attachment.url,
                                        file: null,
                                    })),
                                    time_limit: lesson.time_limit,
                                    color: lesson?.color ?? '#000000',
                                })),
                            };
                        }) ?? [],
                },
            }));
        }
    }, []);

    const show = useCallback(async () => {
        setLoading(true);

        try {
            const allRequests = [];
            allRequests.push(await api.get(`/ead/category`));

            if (profile?.levelAccess === 1 || (profile?.levelAccess === 2 && profile?.main === 1)) {
                allRequests.push(await api.get(`/company`));
            }

            if (course_id) {
                allRequests.push(await api.get(`/ead/course/module/lesson?course_id=${course_id}`));
            }

            await axios.all(allRequests).then(
                axios.spread((...responses) => {
                    if (profile?.levelAccess === 1 || (profile?.levelAccess === 2 && profile?.main === 1)) {
                        onResponseShowWithCompanies(responses);
                        return;
                    }
                    onResponseShow(responses);
                }),
            );
        } catch (error: any) {
            const { data, message } = error;

            if (message === 'Unauthenticated.') {
                signOut();
                return;
            }

            openNotification(translate(`validation.${data.error}`), 'error');
        } finally {
            setLoading(false);
        }
    }, [
        onResponseShowWithCompanies,
        onResponseShow,
        signOut,
        openNotification,
        translate,
        course_id,
        profile?.levelAccess,
        profile?.main,
    ]);

    const handleAddModule = useCallback(() => {
        const newModule = {
            id: generateNumber(10000, 100000),
            module_id: null,
            title: '',
            lessons: [],
        };

        setFields((oldFields) => ({
            ...oldFields,
            modules: [...oldFields.modules, newModule],
        }));
    }, []);

    const handleAddLesson = useCallback((module_id: number) => {
        const newLesson = {
            id: generateNumber(10000, 100000),
            lesson_id: null,
            type: '',
            title: '',
            attempts: '',
            video: {
                file: null,
                file_name: '',
                type: null,
                url: null,
            },
            attachments: [],
            color: '',
            time_limit: '',
        };

        setFields((oldFields) => ({
            ...oldFields,
            modules: [
                ...oldFields.modules.map((module) => {
                    if (module.id === module_id) {
                        return {
                            ...module,
                            lessons: [...module.lessons, newLesson],
                        };
                    }
                    return module;
                }),
            ],
        }));
    }, []);

    const onSaveSequenceModules = useCallback(
        async (items: any) => {
            try {
                const formData = new FormData();
                formData.append('course_id', String(course_id));

                // eslint-disable-next-line guard-for-in
                for (const key in items) {
                    formData.append(`course_module_id[${key}]`, String(items[key].module_id));
                    formData.append(`module_sequence[${key}]`, String(key));
                }

                const {
                    data: { message },
                } = await api.post('/ead/course/sequence', formData);
                openNotification(translate(`validation.${message}`), 'success');

                if (fields.publish === 0) {
                    await api.put(`/ead/course/module/lesson/cancel?course_id=${course_id}`);
                }
            } catch (error: any) {
                const { data } = error;

                openNotification(translate(`validation.${data.error}`), 'error');
            }
        },
        [openNotification, translate, course_id, fields.publish],
    );

    const onSaveSequenceLesson = useCallback(
        async (items: any) => {
            try {
                const formData = new FormData();
                formData.append('course_id', String(course_id));

                // eslint-disable-next-line guard-for-in
                for (const key in items) {
                    formData.append(`lesson_id[${key}]`, String(items[key].lesson_id));
                    formData.append(`lesson_sequence[${key}]`, String(key));
                }

                const {
                    data: { message },
                } = await api.post('/ead/course/module/lesson/sequence', formData);
                openNotification(translate(`validation.${message}`), 'success');

                if (fields.publish === 0) {
                    await api.put(`/ead/course/module/lesson/cancel?course_id=${course_id}`);
                }
            } catch (error: any) {
                const { data } = error;

                openNotification(translate(`validation.${data.error}`), 'error');
            }
        },
        [openNotification, translate, course_id, fields.publish],
    );

    const onSaveSequenceSliders = useCallback(
        async (items: any) => {
            try {
                const formData = new FormData();

                // eslint-disable-next-line guard-for-in
                for (const key in items) {
                    formData.append(`file_id[${key}]`, String(items[key].file_id));
                    formData.append(`file_sequence[${key}]`, String(key));
                }

                const {
                    data: { message },
                } = await api.post('/ead/file/sequence', formData);
                openNotification(translate(`validation.${message}`), 'success');
            } catch (error: any) {
                const { data } = error;

                openNotification(translate(`validation.${data.error}`), 'error');
            }
        },
        [openNotification, translate],
    );

    const handleDragEndModule = useCallback(
        ({ destination, source }: DropResult) => {
            if (!destination) return;

            const newItems = reorder(fields.modules, source.index, destination.index) as PropsModules[];

            onSaveSequenceModules(newItems);

            setFields((oldFields) => ({
                ...oldFields,
                modules: newItems,
            }));
        },
        [onSaveSequenceModules, fields],
    );

    const handleDragEndLesson = useCallback(
        ({ destination, source }: DropResult, module_id: number) => {
            if (!destination) return;

            const lessons = fields?.modules?.find((item) => item.id === module_id)?.lessons ?? [];
            const newItems = reorder(lessons, source.index, destination.index) as PropsLessons[];

            onSaveSequenceLesson(newItems);

            setFields((oldFields) => ({
                ...oldFields,
                modules: [
                    ...oldFields.modules.map((module) => {
                        if (module.id === module_id) {
                            return { ...module, lessons: newItems };
                        }
                        return module;
                    }),
                ],
            }));
        },
        [onSaveSequenceLesson, fields?.modules],
    );

    const handleDragEndAttachment = useCallback(
        ({ destination, source }: DropResult, module_id: number, lesson_id: number) => {
            if (!destination) return;

            const lessons = fields?.modules?.find((item) => item.id === module_id)?.lessons ?? [];
            const attachments = lessons?.find((item) => item.id === lesson_id)?.attachments ?? [];
            const newItems = reorder(attachments, source.index, destination.index) as PropsAttachmentLesson[];

            onSaveSequenceSliders(newItems);

            setFields((oldFields) => ({
                ...oldFields,
                modules: [
                    ...oldFields.modules.map((module) => {
                        if (module.id === module_id) {
                            return {
                                ...module,
                                lessons: module.lessons?.map((lesson) => {
                                    if (lesson.id === lesson_id) {
                                        return { ...lesson, attachments: newItems };
                                    }
                                    return lesson;
                                }),
                            };
                        }
                        return module;
                    }),
                ],
            }));
        },
        [fields?.modules, onSaveSequenceSliders],
    );

    const handleToggleModule = useCallback((newValue: number) => {
        setExpanded((oldExpanded) => (oldExpanded === newValue ? false : newValue));
    }, []);

    const handleToggleLesson = useCallback((newValue: number) => {
        setExpandedLesson((oldxpandedLesson) => (oldxpandedLesson === newValue ? false : newValue));
    }, []);

    const updateModules = useCallback((newValue: string, module_id?: number) => {
        setFields((oldFields) => ({
            ...oldFields,
            modules: [
                ...oldFields.modules.map((module) => {
                    if (module.id === module_id) {
                        module = { ...module, title: newValue };
                    }
                    return module;
                }),
            ],
        }));

        setError((oldError) => ({
            ...oldError,
            modules: [
                ...oldError.modules.map((module) => {
                    if (module.id === module_id) {
                        module = { ...module, title: '' };
                    }
                    return module;
                }),
            ],
        }));
    }, []);

    const updateErrorLesson = useCallback((module_id: number | undefined, lesson_id: number | undefined) => {
        setError((oldError) => ({
            ...oldError,
            modules: [
                ...oldError.modules.map((error) => {
                    if (error.id === module_id) {
                        return {
                            ...error,
                            lessons: [
                                ...error.lessons.map((lesson) => {
                                    if (lesson.id === lesson_id) {
                                        return { ...lesson, title: '' };
                                    }
                                    return lesson;
                                }),
                            ],
                        };
                    }
                    return error;
                }),
            ],
        }));
    }, []);

    const updateStateVideoOfFields = useCallback((module_id: number, lesson_id: number, name: string, file: any) => {
        const type = file?.type.split('/')[1];
        const url = URL?.createObjectURL(file as Blob);
        setFields((oldFields) => ({
            ...oldFields,
            modules: [
                ...oldFields.modules.map((module) => {
                    if (module.id === module_id) {
                        return {
                            ...module,
                            lessons: [
                                ...module.lessons.map((lesson) => {
                                    if (lesson.id === lesson_id) {
                                        if (name === 'attachments') {
                                            lesson = {
                                                ...lesson,
                                                [name]: [
                                                    ...lesson.attachments,
                                                    {
                                                        id: String(generateNumber(10000, 100000)),
                                                        file_id: null,
                                                        file_name: file?.name,
                                                        type,
                                                        url,
                                                        file,
                                                    },
                                                ],
                                            };

                                            return lesson;
                                        }

                                        lesson = {
                                            ...lesson,
                                            [name]: {
                                                file_name: file?.name,
                                                type,
                                                url,
                                                file,
                                            },
                                        };
                                    }

                                    return lesson;
                                }),
                            ],
                        };
                    }
                    return module;
                }),
            ],
        }));
    }, []);

    const updateVideoOfFields = useCallback(
        (file: any, name: string, module_id?: number, lesson_id?: number) => {
            if (!file) {
                return;
            }
            if (file?.size > 817889280) {
                openNotification(translate('validation.videoExceedsSize'), 'error');
                return;
            }

            updateStateVideoOfFields(module_id!, lesson_id!, name, file);
        },
        [openNotification, translate, updateStateVideoOfFields],
    );

    const updateLessons = useCallback(
        (event: ChangeEvent<HTMLInputElement>, name: string, module_id?: number, lesson_id?: number) => {
            if (event.target.type === 'file') {
                const { files } = event.target as HTMLInputElement;

                // eslint-disable-next-line guard-for-in
                for (const i in files) {
                    updateVideoOfFields(files[Number(i)], event.target.name, module_id, lesson_id);
                }

                event.target.value = '';

                return;
            }

            setFields((oldFields) => ({
                ...oldFields,
                modules: [
                    ...oldFields.modules.map((module) => {
                        if (module.id === module_id) {
                            return {
                                ...module,
                                lessons: [
                                    ...module.lessons.map((lesson) => {
                                        if (lesson.id === lesson_id) {
                                            lesson = {
                                                ...lesson,
                                                [name]: event.target.value,
                                            };
                                        }
                                        return lesson;
                                    }),
                                ],
                            };
                        }
                        return module;
                    }),
                ],
            }));

            updateErrorLesson(module_id, lesson_id);
        },
        [updateErrorLesson, updateVideoOfFields],
    );

    const updateClearError = useCallback((name: string) => {
        setError((oldError) => ({
            ...oldError,
            [name]: '',
        }));
    }, []);

    const onValidateFilesPoster = useCallback((file: string) => {
        const validExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.svg)$/i;
        return validExtensions.exec(file);
    }, []);

    const handleChange = useCallback(
        (
            event: ChangeEvent<HTMLInputElement>,
            group?: string,
            module_id?: number,
            lesson_id?: number,
            name?: string,
        ) => {
            if (group === 'modules') {
                updateModules(event.target.value, module_id);

                return;
            }
            if (group === 'lessons') {
                updateLessons(event, name ?? '', module_id, lesson_id);

                return;
            }
            if (event.target.type === 'file') {
                const { files, value } = event.target as HTMLInputElement;
                const file = files?.item(0);

                if (!onValidateFilesPoster(value)) {
                    openNotification(translate('validation.invalidFileType'), 'error');
                    return;
                }

                if (!file) {
                    return;
                }

                const type = file?.type.split('/')[1];
                const url = URL.createObjectURL(file as Blob);

                setFields((oldFields) => ({
                    ...oldFields,
                    [event.target.name]: {
                        file_name: file?.name,
                        type,
                        url,
                        file,
                    },
                }));

                updateClearError(event.target.name);

                return;
            }

            setFields((oldFields) => ({
                ...oldFields,
                [event.target.name]: event.target.value,
            }));

            updateClearError(event.target.name);
        },
        [updateModules, updateLessons, openNotification, onValidateFilesPoster, updateClearError, translate],
    );

    const handleOpenConfirm = useCallback((event: MouseEvent, confirm: PropsOpenConfirm) => {
        event.stopPropagation();

        setConfirm((oldConfirm) => ({
            ...oldConfirm,
            ...{
                open: true,
                status: '',
                funcSubmit: confirm.funcSubmit,
                title: confirm.title,
                text: confirm.text,
                fakeModuleId: confirm?.fake_module_id ?? '',
                moduleId: confirm?.module_id ?? '',
                fakeLessonId: confirm?.fake_lesson_id ?? '',
                lessonId: confirm?.lesson_id ?? '',
            },
        }));
    }, []);

    const handleCloseConfirm = useCallback(() => {
        setConfirm((oldConfirm) => ({
            ...oldConfirm,
            ...{
                open: false,
                funcSubmit: '',
                fakeModuleId: '',
                moduleID: '',
                fakeLessonId: '',
                lessonID: '',
                attachmentId: '',
            },
        }));
    }, []);

    const handleRemoveAttachment = useCallback(
        (file_id: number | null, file_name: string, url: string, module_id?: number, lesson_id?: number) => {
            if (!file_id) {
                setFields((oldFields) => ({
                    ...oldFields,
                    modules: [
                        ...oldFields.modules.map((module) => {
                            if (module.module_id === module_id || module.id === module_id) {
                                return {
                                    ...module,
                                    lessons: [
                                        ...module.lessons.map((lesson) => {
                                            if (lesson.lesson_id === lesson_id || lesson.id === lesson_id) {
                                                lesson = {
                                                    ...lesson,
                                                    attachments: [
                                                        ...lesson.attachments.filter(
                                                            (attachment) => attachment.url !== url,
                                                        ),
                                                    ],
                                                };
                                            }

                                            return lesson;
                                        }),
                                    ],
                                };
                            }
                            return module;
                        }),
                    ],
                }));

                return;
            }

            setConfirm((oldConfirm) => ({
                ...oldConfirm,
                ...{
                    open: true,
                    status: '',
                    funcSubmit: '',
                    title: translate(`course.confirm.title.attachment`),
                    text: file_name,
                    fakeModuleId: '',
                    moduleId: String(module_id),
                    fakeLessonId: '',
                    lessonId: String(lesson_id),
                    attachmentId: String(file_id),
                },
            }));
        },
        [translate],
    );

    const removeLessonFromModule = useCallback((module_id: string, fake_lesson_id: string, message: string) => {
        setFields((oldFields) => ({
            ...oldFields,
            modules: [
                ...oldFields.modules.map((module) => {
                    if (module.module_id === Number(module_id)) {
                        return {
                            ...module,
                            lessons: [...module.lessons.filter((lesson) => lesson.id !== Number(fake_lesson_id))],
                        };
                    }
                    return module;
                }),
            ],
        }));

        setConfirm((oldConfirm) => ({
            ...oldConfirm,
            ...{
                open: true,
                status: 'success',
                message,
            },
        }));
    }, []);

    const onRemoveLesson = useCallback(
        async (module_id: string, fake_lesson_id: string, lesson_id: string) => {
            if (lesson_id === null || lesson_id === 'null' || lesson_id === '') {
                removeLessonFromModule(module_id, fake_lesson_id, translate(`validation.Lesson deleted successfully`));
                return;
            }

            setConfirm((oldConfirm) => ({
                ...oldConfirm,
                ...{
                    open: true,
                    status: 'loading',
                },
            }));

            try {
                const {
                    data: { success, message },
                } = await api.delete(`/ead/course/module/lesson?course_module_id=${module_id}&lesson_id=${lesson_id}`);

                if (success) {
                    removeLessonFromModule(module_id, fake_lesson_id, translate(`validation.${message}`));
                }
            } catch (error: any) {
                const { data, message } = error;

                setConfirm((oldConfirm) => ({
                    ...oldConfirm,
                    ...{
                        open: true,
                        status: 'error',
                        message: translate(`validation.${data?.message || message}`),
                    },
                }));
            }
        },
        [removeLessonFromModule, translate],
    );

    const removeModule = useCallback((fake_module_id: string, message: string) => {
        setFields((oldFields) => ({
            ...oldFields,
            modules: [...oldFields.modules.filter((module) => module.id !== Number(fake_module_id))],
        }));

        setConfirm((oldConfirm) => ({
            ...oldConfirm,
            ...{
                open: true,
                status: 'success',
                message,
            },
        }));
    }, []);

    const onRemoveModule = useCallback(
        async (fake_module_id: string, module_id: string) => {
            if (module_id === null || module_id === 'null' || module_id === '') {
                removeModule(fake_module_id, translate('validation.Course Module deleted successfully'));
                return;
            }

            try {
                const {
                    data: { success, message },
                } = await api.delete(`/ead/course/module?course_module_id=${module_id}&course_id=${course_id}`);

                if (success) {
                    removeModule(fake_module_id, translate(`validation.${message}`));

                    if (fields.publish === 0) {
                        await api.put(`/ead/course/module/lesson/cancel?course_id=${course_id}`);
                    }
                }
            } catch (error: any) {
                const { data, message } = error;

                setConfirm((oldConfirm) => ({
                    ...oldConfirm,
                    ...{
                        open: true,
                        status: 'error',
                        message: translate(`validation.${data?.message || message}`),
                    },
                }));
            }
        },
        [removeModule, translate, course_id, fields.publish],
    );

    const onRemoveAttachment = useCallback(
        async (module_id: string, lesson_id: string, file_id: string) => {
            try {
                const {
                    data: { success, message },
                } = await api.delete(`/ead/course/module/lesson/file/remove?file_id=${file_id}`);

                if (success) {
                    show();

                    setConfirm((oldConfirm) => ({
                        ...oldConfirm,
                        ...{
                            open: true,
                            status: 'success',
                            message: translate(`validation.${message}`),
                        },
                    }));

                    setExpanded(Number(module_id) || false);
                    setExpandedLesson(Number(lesson_id) || false);

                    if (fields.publish === 0) {
                        await api.put(`/ead/course/module/lesson/cancel?course_id=${course_id}`);
                    }
                }
            } catch (error: any) {
                const { data, message } = error;

                setConfirm((oldConfirm) => ({
                    ...oldConfirm,
                    ...{
                        open: true,
                        status: 'error',
                        message: translate(`validation.${data?.message || message}`),
                    },
                }));
            }
        },
        [show, translate, fields.publish, course_id],
    );

    const onRemoveCourse = useCallback(async () => {
        try {
            const {
                data: { success, message },
            } = await api.delete(`/ead/course?course_id=${course_id}`);

            if (success) {
                navigate(setPrefix('/ead'));
                openNotification(translate(`validation.${message}`), 'success');
            }
        } catch (error: any) {
            const { data, message } = error;

            setConfirm((oldConfirm) => ({
                ...oldConfirm,
                ...{
                    open: true,
                    status: 'error',
                    message: translate(`validation.${data?.message || message}`),
                },
            }));
        }
    }, [navigate, openNotification, translate, course_id]);

    const onValidate = useCallback(() => {
        let total: number = 0;
        const error: PropsError = { ...inititalError };

        if (fields.name === '') {
            error.name = translate('validation.emptyCourseName');
            total++;
        }
        if (fields.category === '') {
            error.category = translate('validation.selectCourseCategory');
            total++;
        }
        if (fields.classification === '') {
            error.classification = translate('validation.selectClassification');
            total++;
        }
        if (
            (profile?.levelAccess === 1 && fields.company === '') ||
            (profile?.levelAccess === 2 && profile?.main === 1 && fields.company === '')
        ) {
            error.company = translate('validation.emptyCompany');
            total++;
        }
        if (fields.enable_time === '1' && fields.time_limit === '') {
            error.time_limit = translate('validation.emptyTimeLimit');
            total++;
        }
        if (fields.amount_points === '') {
            error.amount_points = translate('validation.selectAmountPpoints');
            total++;
        }
        if (!fields.poster.url) {
            error.poster = translate('validation.uploadCoursePoster');
            total++;
        }

        setError({ ...error });

        if (total > 0) {
            setExpanded(false);
        }

        if (total > 1) {
            openNotification(translate(`validation.requiredFields`), 'error');
        }

        return total > 0;
    }, [inititalError, fields, profile?.levelAccess, profile?.main, openNotification, translate]);

    const handleSaveCourse = useCallback(
        async (event: ChangeEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (onValidate()) {
                return;
            }

            setLoadingForm(true);

            const formData = new FormData();
            formData.append('course_name', fields.name);
            formData.append('category_id', fields.category);
            formData.append('amount_points', fields.amount_points);
            formData.append('course_teacher_name', fields.instructor);
            formData.append('free_to_all', fields.classification);
            formData.append('enable_time', fields.enable_time);
            formData.append('publish', String(fields.publish));

            if (fields.company) {
                formData.append('company_id', fields.company);
            }

            if (fields?.enable_time === '1') {
                formData.append('type_time', fields.type_time);
                formData.append('time_limit', fields.time_limit);
            }

            if (fields?.poster?.file) {
                formData.append('course_file', fields?.poster?.file);
                formData.append('course_file_name', fields?.poster?.file_name);
            }

            if (course_id) {
                formData.append('course_id', course_id);
            }

            try {
                const {
                    data: { success, message, dataCourse },
                } = await api.post(`/ead/course`, formData);

                if (success) {
                    openNotification(translate(`validation.${message}`), 'success');
                    if (!course_id) {
                        navigate(setPrefix(`/ead/course/edit/${dataCourse?.id}`));
                    }
                    if (course_id && fields.publish === 0) {
                        await api.put(`/ead/course/module/lesson/cancel?course_id=${course_id}`);
                    }
                }
            } catch (error: any) {
                const { data } = error;

                openNotification(translate(`validation.${data.error}`), 'error');
            } finally {
                setLoadingForm(false);
            }
        },
        [onValidate, openNotification, navigate, translate, course_id, fields],
    );

    const handlePublishCourse = useCallback(
        async (publishValue: number) => {
            if (onValidate()) {
                return;
            }

            setLoadingForm(true);

            const formData = new FormData();
            formData.append('publish', String(publishValue));
            formData.append('course_name', fields.name);
            formData.append('category_id', fields.category);
            formData.append('amount_points', fields.amount_points);
            formData.append('course_teacher_name', fields.instructor);

            if (fields?.poster?.file) {
                formData.append('course_file', fields?.poster?.file);
                formData.append('course_file_name', fields?.poster?.file_name);
            }

            if (course_id) {
                formData.append('course_id', course_id);
            }

            try {
                const {
                    data: { success, message, dataCourse },
                } = await api.post(`/ead/course`, formData);

                if (success) {
                    setFields((oldFields) => ({
                        ...oldFields,
                        ...{
                            publish: Number(dataCourse?.publish),
                            publish_at: dataCourse?.publish_at,
                        },
                    }));

                    if (dataCourse.publish === '1') {
                        setConfirm((oldConfirm) => ({
                            ...oldConfirm,
                            ...{
                                open: true,
                                status: 'success',
                                message: translate(`validation.${message}`),
                            },
                        }));

                        return;
                    }

                    openNotification(translate(`validation.${message}`), 'success');
                }
            } catch (error: any) {
                const { data } = error;

                openNotification(translate(`validation.${data.error}`), 'error');
            } finally {
                setLoadingForm(false);
            }
        },
        [onValidate, openNotification, translate, course_id, fields],
    );

    const handleConfirm = useCallback(() => {
        let func_submit = '';
        let module_id = '';
        let fake_module_id = '';
        let lesson_id = '';
        let fake_lesson_id = '';
        let attachment_id = '';

        setConfirm((oldConfirm) => {
            func_submit = oldConfirm.funcSubmit || '';
            module_id = oldConfirm.moduleId || '';
            fake_module_id = oldConfirm.fakeModuleId || '';
            lesson_id = oldConfirm.lessonId || '';
            fake_lesson_id = oldConfirm.fakeLessonId || '';
            attachment_id = oldConfirm.attachmentId || '';

            return {
                ...oldConfirm,
                status: 'loading',
            };
        });

        if (func_submit === 'remove_course') {
            onRemoveCourse();
            return;
        }

        if (func_submit === 'publish_course') {
            handlePublishCourse(1);
            return;
        }

        if (func_submit === 'remove_module') {
            onRemoveModule(fake_module_id, module_id);
            return;
        }

        if (func_submit === 'remove_lesson') {
            onRemoveLesson(module_id, fake_lesson_id, lesson_id);
            return;
        }

        onRemoveAttachment(module_id, lesson_id, attachment_id);
    }, [onRemoveCourse, onRemoveLesson, onRemoveModule, handlePublishCourse, onRemoveAttachment]);

    const onUpdateStateFieldsModuleId = useCallback(
        (id: number, module_id: number) => {
            let sequenceModules = [] as PropsModules[];
            setFields((oldFields) => {
                const modules = oldFields.modules.map((item) => {
                    if (item.id === id) {
                        item = {
                            ...item,
                            module_id,
                        };
                    }
                    return item;
                });
                sequenceModules = modules;
                return {
                    ...oldFields,
                    modules,
                };
            });

            onSaveSequenceModules(sequenceModules);
        },
        [onSaveSequenceModules],
    );

    const handleSaveModule = useCallback(
        async (id: number, module_id: number | null, module_name: string) => {
            if (module_name === '') {
                openNotification(translate('validation.emptyModuleTitle'), 'error');

                setError((oldError) => {
                    const errorModule = [
                        ...oldError.modules,
                        {
                            id,
                            title: translate('validation.emptyModuleTitle'),
                            lessons: [],
                        },
                    ];
                    return {
                        ...oldError,
                        modules: [
                            ...errorModule.map((item) => {
                                if (item.id === id) {
                                    item = {
                                        ...item,
                                        title: translate('validation.emptyModuleTitle'),
                                    };
                                }
                                return item;
                            }),
                        ],
                    };
                });

                return;
            }

            setLoadingForm(true);

            const formData = new FormData();
            if (course_id) {
                formData.append('course_id', course_id);
            }
            if (module_id) {
                formData.append('course_module_id', String(module_id));
            }
            formData.append('module_name', module_name);

            try {
                const {
                    data: { success, message, dataCourseModule },
                } = await api.post(`/ead/course/module/lesson`, formData);

                if (success) {
                    openNotification(translate(`validation.${message}`), 'success');

                    onUpdateStateFieldsModuleId(id, dataCourseModule?.id);
                }
            } catch (error: any) {
                const { data } = error;

                openNotification(translate(`validation.${data.error}`), 'error');
            } finally {
                setLoadingForm(false);
            }
        },
        [openNotification, translate, onUpdateStateFieldsModuleId, course_id],
    );

    const onValidateLesson = useCallback(
        (lesson: PropsLessons, module_id: number) => {
            let total: number = 0;

            if (lesson.title === '') {
                setError((oldError) => {
                    return {
                        ...oldError,
                        modules: [
                            ...oldError.modules,
                            {
                                id: module_id,
                                title: '',
                                lessons: [
                                    {
                                        id: lesson.id,
                                        title: translate('validation.emptyLessonTitle'),
                                    },
                                ],
                            },
                        ],
                    };
                });
                total++;
            }

            if (total > 1) {
                openNotification(translate(`validation.requiredFields`), 'error');
            }

            return total > 0;
        },
        [openNotification, translate],
    );

    const formDataLesson = useCallback(
        (module_id: number | null, lesson: PropsLessons) => {
            const formData = new FormData();
            if (course_id) {
                formData.append('course_id', course_id);
            }

            if (lesson.lesson_id) {
                formData.append('lesson_id', String(lesson.lesson_id));
            }
            formData.append('course_module_id', String(module_id));
            formData.append('is_file', lesson.type);
            formData.append('lesson_name', String(lesson.title));
            formData.append('time_limit', String(lesson.time_limit));

            if (lesson?.type === '0') {
                formData.append('type_attempts', lesson?.attempts === '0' ? 'open' : 'null');
                formData.append('attempts', lesson?.attempts === '0' ? 'null' : lesson?.attempts);
            }
            if (lesson?.video?.file) {
                formData.append('lesson_file_name', lesson?.video?.file_name);
                formData.append('lesson_file', lesson?.video?.file);
            }
            if (lesson?.color) {
                formData.append('color', String(lesson.color));
            }

            // eslint-disable-next-line guard-for-in
            for (const key in lesson.attachments) {
                if (lesson?.attachments[key]?.file) {
                    formData.append(`file_name[${key}]`, lesson?.attachments[key]?.file_name);
                    formData.append(`file[${key}]`, lesson?.attachments[key]?.file ?? '');
                }
            }

            return formData;
        },
        [course_id],
    );

    const onUpdateStateFieldsLessonId = useCallback(
        (module_fake_id: number, lesson_fake_id: number, lesson_id: number) => {
            let sequenceLessons = [] as PropsLessons[];

            setFields((oldFields) => ({
                ...oldFields,
                modules: [
                    ...oldFields.modules.map((module) => {
                        if (module.id === module_fake_id) {
                            const lessons = module.lessons.map((lesson) => {
                                if (lesson.id === lesson_fake_id) {
                                    lesson = { ...lesson, lesson_id };
                                }
                                return lesson;
                            });

                            module.lessons = lessons;
                            sequenceLessons = lessons;
                        }
                        return module;
                    }),
                ],
            }));

            onSaveSequenceLesson(sequenceLessons);
        },
        [onSaveSequenceLesson],
    );

    const handleSaveLesson = useCallback(
        async (fake_module_id: number, module_id: number | null, lesson: PropsLessons) => {
            if (onValidateLesson(lesson, fake_module_id)) {
                return;
            }

            setLoadingForm(true);

            try {
                const {
                    data: { success, message, lesson_id },
                } = await api.post(`/ead/course/module/lesson`, formDataLesson(module_id, lesson));

                if (success) {
                    onUpdateStateFieldsLessonId(fake_module_id, lesson.id, lesson_id);
                    show();

                    setExpanded(module_id ?? false);
                    setExpandedLesson(lesson_id ?? false);

                    openNotification(translate(`validation.${message}`), 'success');

                    if (fields.publish === 0) {
                        await api.put(`/ead/course/module/lesson/cancel?course_id=${course_id}`);
                    }
                }
            } catch (error: any) {
                const { data, message } = error;

                openNotification(translate(`validation.${data?.error ?? message}`), 'error');
            } finally {
                setLoadingForm(false);
            }
        },
        [
            onValidateLesson,
            formDataLesson,
            show,
            onUpdateStateFieldsLessonId,
            openNotification,
            translate,
            course_id,
            fields.publish,
        ],
    );

    const handleOpenQuiz = useCallback(
        (
            module_id: number | null,
            fake_lesson_id: number,
            lesson_id: number | null,
            lesson_name: string,
            lesson_type: string,
            attempts: number,
        ) => {
            setQuizDetails((oldQuizDetails) => ({
                ...oldQuizDetails,
                ...{
                    open: true,
                    status: '',
                    message: '',
                    moduleId: module_id,
                    fakeLessonId: fake_lesson_id,
                    lessonId: lesson_id,
                    lessonName: lesson_name,
                    typeLesson: lesson_type,
                    attempts,
                },
            }));
        },
        [],
    );

    const handleUpdateQuiz = useCallback((quizDetails: PropsModalQuiz) => {
        setQuizDetails(quizDetails);
    }, []);

    const handleCloseQuiz = useCallback(() => {
        setQuizDetails((oldQuizDetails) => ({
            ...oldQuizDetails,
            ...{
                open: false,
                moduleId: null,
                fakeLessonId: null,
                lessonId: null,
                lessonName: '',
            },
        }));
    }, []);

    const handleOpenVideoDynamic = useCallback(
        (
            module_id: number | null,
            fake_lesson_id: number,
            lesson_id: number | null,
            lesson_name: string,
            lesson_type: string,
        ) => {
            setVideoDynamic((oldQuizDetails) => ({
                ...oldQuizDetails,
                ...{
                    open: true,
                    status: '',
                    message: '',
                    moduleId: module_id,
                    fakeLessonId: fake_lesson_id,
                    lessonId: lesson_id,
                    lessonName: lesson_name,
                    typeLesson: lesson_type,
                },
            }));
        },
        [],
    );

    const handleCloseVideoDynamic = useCallback(() => {
        setVideoDynamic((oldQuizDetails) => ({
            ...oldQuizDetails,
            ...{
                open: false,
                moduleId: null,
                fakeLessonId: null,
                lessonId: null,
                lessonName: '',
            },
        }));
    }, []);

    useEffect(() => {
        if (profile?.client_id === null) {
            navigate(setPrefix('/'));
        }
    }, [profile?.client_id, navigate]);

    useEffect(() => {
        document.title = translate(`course.head.title.${course_id ? 'update' : 'add'}`);

        show();
        onActivated(translate('course.moduleName'), 'ead', 'ead');
    }, [onActivated, translate, show, course_id]);

    const value = useMemo(
        () => ({
            loading,
            loadingForm,
            course_id,
            fields,
            error,
            expanded,
            expandedLesson,
            confirm,
            quizDetails,
            videoDynamic,
            handleGoBack,
            handleAddModule,
            handleToggleModule,
            handleDragEndModule,
            handleChange,
            handleAddLesson,
            handleRemoveAttachment,
            handleToggleLesson,
            handleDragEndLesson,
            handleDragEndAttachment,
            handleOpenConfirm,
            handleCloseConfirm,
            handleConfirm,
            handleSaveCourse,
            handlePublishCourse,
            handleSaveModule,
            handleSaveLesson,
            handleOpenQuiz,
            handleUpdateQuiz,
            handleCloseQuiz,
            handleOpenVideoDynamic,
            handleCloseVideoDynamic,
            notAllowedToChange,
        }),
        [
            loading,
            loadingForm,
            course_id,
            fields,
            error,
            expanded,
            expandedLesson,
            confirm,
            quizDetails,
            videoDynamic,
            handleGoBack,
            handleAddModule,
            handleToggleModule,
            handleDragEndModule,
            handleChange,
            handleAddLesson,
            handleRemoveAttachment,
            handleToggleLesson,
            handleDragEndLesson,
            handleDragEndAttachment,
            handleOpenConfirm,
            handleCloseConfirm,
            handleConfirm,
            handleSaveCourse,
            handlePublishCourse,
            handleSaveModule,
            handleSaveLesson,
            handleOpenQuiz,
            handleUpdateQuiz,
            handleCloseQuiz,
            handleOpenVideoDynamic,
            handleCloseVideoDynamic,
            notAllowedToChange,
        ],
    );

    return {
        value,
    };
}
