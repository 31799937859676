import React, { memo } from 'react';

import * as Component from './styled';

interface PropsNotification {
    open: boolean;
    autoHideDuration?: number;
    message: string;
    status: 'error' | 'warning' | 'info' | 'success';
    handleClose?: () => void;
}

const Notification: React.FC<PropsNotification> = ({ open, autoHideDuration, message, status, handleClose }) => (
    <Component.Snackbar
        open={open}
        autoHideDuration={autoHideDuration ?? 6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
        <Component.Alert onClose={handleClose} severity={status}>
            {message}
        </Component.Alert>
    </Component.Snackbar>
);

export default memo(Notification);
