import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import * as Component from './styled';

interface Props {
    fullscreen: boolean;
    onClick: () => void;
}

const ButtonFullscreen: React.FC<Props> = ({ fullscreen, onClick }) => {
    const { t: translate } = useTranslation();
    return (
        <Component.ButtonFullscreen onClick={onClick}>
            {fullscreen === true ? (
                <>
                    {translate('classContent.button.exitFullscreen')}
                    <Component.FullscreenExitIcon />
                </>
            ) : (
                <>
                    {translate('classContent.button.fullscreen')}
                    <Component.FullscreenIcon />
                </>
            )}
        </Component.ButtonFullscreen>
    );
};

export default memo(ButtonFullscreen);
