import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import * as Component from './styled';

type Error = {
    observation?: string;
};

interface Props {
    description?: string;
    status: 'loading' | 'success' | 'error' | '';
    open: boolean;
    message?: string;
    fields: any;
    error: Error;
    onChange: (event: any, id?: number, name?: string) => void;
    onRemoveAttachment: (url: string) => void;
    onClose: () => void;
    onConfirm: (event: React.ChangeEvent<HTMLFormElement>) => void;
}

const FinalizeSyndication: React.FC<Props> = ({
    description,
    status,
    open,
    message,
    fields,
    error,
    onChange,
    onRemoveAttachment,
    onClose,
    onConfirm,
}) => {
    const { t: translate } = useTranslation();

    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        <Component.ModalTitle>{translate('modal.finalizeSyndication.title')}</Component.ModalTitle>
                        <Component.ModalBody>
                            {description && <Component.ModalText>{description}</Component.ModalText>}
                            <Component.Form onSubmit={onConfirm}>
                                <Component.FormGroup>
                                    <Component.InputText
                                        color="primary"
                                        multiline
                                        minRows={4}
                                        maxRows={2}
                                        label={translate('modal.finalizeSyndication.form.observation')}
                                        name="observation"
                                        value={fields.observation}
                                        onChange={onChange}
                                        error={Boolean(error.observation)}
                                        helperText={error.observation}
                                    />
                                </Component.FormGroup>
                                {fields.attachments.length > 0 && (
                                    <Component.ListAttachments>
                                        {fields.attachments.map((item: any) => (
                                            <Component.Attachment
                                                key={item.file}
                                                ext={item.ext}
                                                url={item.url}
                                                onRemove={onRemoveAttachment}
                                            />
                                        ))}
                                    </Component.ListAttachments>
                                )}
                                <Component.FormGroup>
                                    <Component.ButtonUpload
                                        color="secondary"
                                        name="attachments"
                                        onChange={onChange}
                                        label={translate('modal.finalizeSyndication.form.attachFiles')}
                                    />
                                </Component.FormGroup>
                                <Component.ModalActions>
                                    <Component.Button onClick={onClose} color="primary">
                                        {translate('modal.button.cancel')}
                                    </Component.Button>
                                    <Component.Button type="submit">
                                        {translate('modal.button.finalize')}
                                    </Component.Button>
                                </Component.ModalActions>
                            </Component.Form>
                        </Component.ModalBody>
                    </>
                );
        }
    };

    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title">
            {modalContent()}
        </Component.Modal>
    );
};

export default memo(FinalizeSyndication);
