import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import * as Component from './styled';

type Error = {
    type: string;
    description: string;
    notification_type_id: string;
};

interface Props {
    open: boolean;
    status: 'loading' | 'success' | 'error' | '';
    message?: string;
    fields: any;
    error: Error;
    onChange: (event: any) => void;
    onChangeDescription: (event: any, value: any) => void;
    onChangeTextDescription: (event: any) => void;
    onConfirm: (event: React.ChangeEvent<HTMLFormElement>) => void;
    onGoback: () => void;
    onClose: () => void;
}

const NotificationsDetails: React.FC<Props> = ({
    open,
    status,
    message,
    fields,
    error,
    onChange,
    onChangeDescription,
    onChangeTextDescription,
    onConfirm,
    onGoback,
    onClose,
}) => {
    const { t: translate } = useTranslation();
    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                            <Component.ModalActions>
                                <Component.Button color="primary" onClick={onGoback}>
                                    {translate('modal.button.goback')}
                                </Component.Button>
                            </Component.ModalActions>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        <Component.ModalTitle>{translate('modal.notificationsDetails.title.add')}</Component.ModalTitle>
                        <Component.ModalBody>
                            <Component.Form onSubmit={onConfirm}>
                                <Component.Grid container spacing={3}>
                                    <Component.Grid item xs={12}>
                                        <Component.InputSelect
                                            color="primary"
                                            label={translate('modal.notificationsDetails.form.type')}
                                            name="type"
                                            value={fields.type}
                                            options={[
                                                {
                                                    value: 'departaments',
                                                    title: translate('modal.notificationsDetails.form.departaments'),
                                                },
                                                {
                                                    value: 'users',
                                                    title: translate('modal.notificationsDetails.form.users'),
                                                },
                                            ]}
                                            onChange={onChange}
                                            error={Boolean(error.type)}
                                            helperText={error.type}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12}>
                                        <Component.InputSearch
                                            size="medium"
                                            color="primary"
                                            noOptionsText={translate(
                                                `modal.notificationsDetails.form.description_${fields.type}`,
                                            )}
                                            label={translate('modal.notificationsDetails.form.description')}
                                            name="description"
                                            value={fields.description}
                                            onChange={onChangeDescription}
                                            onChangeText={onChangeTextDescription}
                                            options={fields.optionsDescription}
                                            error={Boolean(error.description)}
                                            helperText={error.description}
                                            disabled={!fields.type}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12}>
                                        <Component.InputSelect
                                            color="primary"
                                            label={translate('modal.notificationsDetails.form.recipientGroup')}
                                            name="notification_type_id"
                                            value={fields.notification_type_id}
                                            options={[
                                                {
                                                    value: '1',
                                                    title: translate(
                                                        'modal.notificationsDetails.form.certified_recipient',
                                                    ),
                                                },
                                                {
                                                    value: '2',
                                                    title: translate('modal.notificationsDetails.form.new_complaint'),
                                                },
                                            ]}
                                            onChange={onChange}
                                            error={Boolean(error.notification_type_id)}
                                            helperText={error.notification_type_id}
                                        />
                                    </Component.Grid>
                                </Component.Grid>
                                <Component.ModalActions>
                                    <Component.Button onClick={onClose} color="primary">
                                        {translate('modal.button.cancel')}
                                    </Component.Button>
                                    <Component.Button type="submit">{translate('modal.button.add')}</Component.Button>
                                </Component.ModalActions>
                            </Component.Form>
                        </Component.ModalBody>
                    </>
                );
        }
    };
    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title" scroll="body">
            {modalContent()}
        </Component.Modal>
    );
};
export default memo(NotificationsDetails);
