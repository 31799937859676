import React, { memo } from 'react';

import * as Component from './styled';

interface Props {
    label?: string;
    disabled?: boolean;
    onClick?: () => void;
}

const ButtonFilter: React.FC<Props> = ({ label, disabled, onClick }) => {
    return (
        <Component.ButtonFilter color="primary" onClick={onClick} disabled={disabled}>
            <Component.IconFilter />
            {label}
        </Component.ButtonFilter>
    );
};

export default memo(ButtonFilter);
