import styled, { keyframes } from 'styled-components';

interface PropsContainer {
    inner?: boolean;
}

const AnimateDescription = keyframes`
  0% {
    letter-spacing: 1px;
    transform: translateX(0px);
  }

  40% {
    letter-spacing: 2px;
    transform: translateX(26px);
  }

  80% {
    letter-spacing: 1px;
    transform: translateX(32px);
  }

  90% {
    letter-spacing: 2px;
    transform: translateX(0px);
  }

  100% {
    letter-spacing: 1px;
    transform: translateX(0px);
  }
`;

const AnimateProgress1 = keyframes`
  0% {
    width: 16px;
    transform: translateX(0px);
  }

  40% {
    width: 100%;
    transform: translateX(0px);
  }

  80% {
    width: 16px;
    transform: translateX(64px);
  }

  90% {
    width: 100%;
    transform: translateX(0px);
  }

  100% {
    width: 16px;
    transform: translateX(0px);
  }
`;

const AnimateProgress2 = keyframes`
  0% {
    transform: translateX(0px);
    width: 16px;
  }

  40% {
    transform: translateX(0%);
    width: 80%;
  }

  80% {
    width: 100%;
    transform: translateX(0px);
  }

  90% {
    width: 80%;
    transform: translateX(15px);
  }
  100% {
    transform: translateX(0px);
    width: 16px;
  }
`;

export const Container = styled.section.attrs({
    className: 'my-container-loading',
})<PropsContainer>`
    width: 100%;
    height: 100%;
    background-color: ${(props) =>
        props.inner ? props.theme.palette.loading.bgInner : props.theme.palette.loading.bg};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
`;
export const Content = styled.div`
    width: 80px;
    height: 50px;
    position: relative;
`;

export const Description = styled.p`
    top: 0;
    padding: 0;
    margin: 0;
    color: ${(props) => props.theme.palette.loading.light};
    animation: ${AnimateDescription} 3.5s ease both infinite;
    font-size: 12px;
    letter-spacing: 1px;
`;

export const Progress = styled.span`
    background-color: ${(props) => props.theme.palette.loading.dark};
    border-radius: 50px;
    display: block;
    height: 16px;
    width: 16px;
    bottom: 0;
    position: absolute;
    transform: translateX(64px);
    animation: ${AnimateProgress1} 3.5s ease both infinite;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-color: ${(props) => props.theme.palette.loading.light};
        border-radius: inherit;
        animation: ${AnimateProgress2} 3.5s ease both infinite;
    }
`;
