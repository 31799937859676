import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import * as Component from './styled';

interface Props {
    title: string;
    description?: string;
    status: 'loading' | 'success' | 'error' | '';
    open: boolean;
    message?: string;
    observation: string;
    onChangeText: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClose: () => void;
    onConfirm: (event: React.ChangeEvent<HTMLFormElement>) => void;
}

const ConfirmWithObservation: React.FC<Props> = ({
    title,
    description,
    status,
    open,
    message,
    observation,
    onChangeText,
    onClose,
    onConfirm,
}) => {
    const { t: translate } = useTranslation();

    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        <Component.ModalTitle>{title}</Component.ModalTitle>
                        <Component.ModalBody>
                            {description && <Component.ModalText>{description}</Component.ModalText>}
                            <Component.Form onSubmit={onConfirm}>
                                <Component.FormGroup>
                                    <Component.InputText
                                        color="primary"
                                        multiline
                                        minRows={4}
                                        maxRows={2}
                                        label={translate('modal.confirmWithObservation.form.observation')}
                                        name="observation"
                                        value={observation}
                                        onChange={onChangeText}
                                    />
                                </Component.FormGroup>
                                <Component.ModalActions>
                                    <Component.Button onClick={onClose} color="primary">
                                        {translate('modal.button.cancel')}
                                    </Component.Button>
                                    <Component.Button type="submit">
                                        {translate('modal.button.confirm')}
                                    </Component.Button>
                                </Component.ModalActions>
                            </Component.Form>
                        </Component.ModalBody>
                    </>
                );
        }
    };

    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title">
            {modalContent()}
        </Component.Modal>
    );
};

export default memo(ConfirmWithObservation);
