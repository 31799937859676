import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const ButtonCustom = styled(Button)`
    width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
    height: 50px;
    border-radius: 10px;
    padding: 12px 20px;
    font-weight: 700;
    background-color: ${({ theme }: any) => theme.palette.button.default.light[0]};
    background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.default.light})`};

    &:not(:disabled):hover {
        background-color: ${({ theme }: any) => theme.palette.button.default.dark[0]};
        background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.default.dark})`};
    }

    &,
    .MuiButton-label {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .MuiButton-label {
        color: ${({ theme }: any) => theme.palette.button.default.main};
    }

    &.MuiButton-containedPrimary {
        background-color: ${({ theme }: any) => theme.palette.button.primary.light[0]};
        background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.primary.light})`};

        .MuiButton-label {
            color: ${({ theme }: any) => theme.palette.button.primary.main};
        }

        &:not(:disabled):hover {
            background-color: ${({ theme }: any) => theme.palette.button.primary.dark[0]};
            background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.primary.dark})`};
        }
    }

    &.MuiButton-containedSecondary {
        background-color: ${({ theme }: any) => theme.palette.button.secondary.light[0]};
        background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.secondary.light})`};

        .MuiButton-label {
            color: ${({ theme }: any) => theme.palette.button.secondary.main};
        }

        &:not(:disabled):hover {
            background-color: ${({ theme }: any) => theme.palette.button.secondary.dark[0]};
            background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.secondary.dark})`};
        }
    }

    &:disabled {
        opacity: 0.3;
    }
`;
