import React, { Suspense, lazy } from 'react';
import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';
import { setPrefix } from 'utils';

import Loading from 'components/loading';

const Login = lazy(() => import('pages/login'));
const ResetPassword = lazy(() => import('pages/resetPassword'));
const RegistrationSupplier = lazy(() => import('pages/registrationSupplier'));
const RedirectEadGrupoAltum = lazy(() => import('pages/redirectEadGrupoAltum'));
const ExternalQuiz = lazy(() => import('pages/modules/lgpd/externalQuiz'));
const ExternalEad = lazy(() => import('pages/modules/ead/externalEad'));
const ExternalEadClassroom = lazy(() => import('pages/modules/ead/classroom/ExternalClassroom'));

const Public: React.FC = () => (
    <Suspense fallback={<Loading />}>
        <Router>
            <Routes>
                <Route path={setPrefix('/')} element={<Login />} />
                <Route path={setPrefix('/altum-app/:token')} element={<RedirectEadGrupoAltum />} />
                <Route path={setPrefix('/reset-password/:code/:email')} element={<ResetPassword />} />
                <Route path={setPrefix('/registration-supplier/:uuid/:user_id')} element={<RegistrationSupplier />} />
                <Route path={setPrefix('/registration-lgpd-quiz/:user_id/:uuid')} element={<ExternalQuiz />} />
                <Route path={setPrefix('/external-ead/:user_id/:uuid')} element={<ExternalEad />} />
                <Route path={setPrefix('/external-ead/:user_id/:uuid/:course_id')} element={<ExternalEadClassroom />} />
                <Route
                    path={setPrefix('/external-ead/:user_id/:uuid/:course_id/:module_id/:lesson_id')}
                    element={<ExternalEadClassroom />}
                />
                <Route path="*" element={<Navigate to={setPrefix('/')} replace />} />
            </Routes>
        </Router>
    </Suspense>
);

export default Public;
