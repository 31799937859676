import React, { memo } from 'react';

import * as Component from './styled';

interface Props {
    color?: 'primary' | 'secondary' | 'default';
    name?: string;
    label?: string;
    accept?: string;
    file?: any;
    type?: any;
    fixed?: boolean;
    icon?: boolean;
    className?: string;
    error?: boolean;
    helperText?: string;
    multiple?: boolean;
    onChange?: (event: any) => void;
    onRemove?: () => void;
}

const DEFAULT_TYPES = '.png,.jpg,.jpeg,.svg,.gif.xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf';

const hasImage = (type: string) => {
    if (type === 'png' || type === 'jpg' || type === 'jpeg' || type === 'svg' || type === 'gif') {
        return null;
    }

    return type;
};

const ButtonUpload: React.FC<Props> = ({
    color,
    name,
    label,
    accept,
    file,
    type,
    fixed,
    icon,
    className,
    error,
    helperText,
    multiple,
    onChange,
    onRemove,
}) => (
    <Component.Container>
        <Component.Group>
            {icon ? (
                <Component.ButtonIcon className={className}>
                    <Component.IconAttach />
                    <input
                        accept={accept ?? DEFAULT_TYPES}
                        multiple={multiple ?? false}
                        type="file"
                        name={name}
                        onChange={onChange}
                        alt=""
                        title=""
                    />
                </Component.ButtonIcon>
            ) : (
                <Component.ButtonCustom className={className} color={color}>
                    {label}
                    <input
                        accept={accept ?? DEFAULT_TYPES}
                        multiple={multiple ?? false}
                        type="file"
                        name={name}
                        onChange={onChange}
                        alt=""
                        title=""
                    />
                </Component.ButtonCustom>
            )}
            {helperText && <Component.FormHelperText error={error}>{helperText}</Component.FormHelperText>}
        </Component.Group>
        {file && (
            <Component.HasFile
                className="list__files"
                type={type}
                fixed={fixed}
                style={{ backgroundImage: `url(${file})` }}
            >
                <Component.ButtonRemove color={color} className="btn__remove" onClick={onRemove}>
                    <Component.IconRemove />
                </Component.ButtonRemove>
                {hasImage(type) && <Component.LabelType>{type}</Component.LabelType>}
            </Component.HasFile>
        )}
    </Component.Container>
);

export default memo(ButtonUpload);
