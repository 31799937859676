import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import * as Component from './styled';

type Error = {
    id?: string;
    name?: string;
};

interface Props {
    open: boolean;
    status: 'loading' | 'success' | 'error' | '';
    message?: string;
    fields: any;
    error: Error;
    onChange: (event: any) => void;
    onConfirm: (event: React.ChangeEvent<HTMLFormElement>) => void;
    onGoback: () => void;
    onClose: () => void;
}

const QuestionsGroupLgpd: React.FC<Props> = ({
    open,
    status,
    message,
    fields,
    error,
    onChange,
    onConfirm,
    onGoback,
    onClose,
}) => {
    const { t: translate } = useTranslation();
    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                            <Component.ModalActions>
                                <Component.Button color="primary" onClick={onGoback}>
                                    {translate('modal.button.goback')}
                                </Component.Button>
                            </Component.ModalActions>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        <Component.ModalTitle>
                            {fields.id !== ''
                                ? translate('modal.questionGroupLgpd.title.update')
                                : translate('modal.questionGroupLgpd.title.add')}
                        </Component.ModalTitle>
                        <Component.ModalBody>
                            <Component.Form onSubmit={onConfirm}>
                                <Component.Grid container spacing={3}>
                                    <Component.Grid item xs={12}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.questionGroupLgpd.form.name')}
                                            name="name"
                                            value={fields.name}
                                            onChange={onChange}
                                            error={Boolean(error.name)}
                                            helperText={error.name}
                                        />
                                    </Component.Grid>
                                </Component.Grid>
                                <Component.ModalActions>
                                    <Component.Button onClick={onClose} color="primary">
                                        {translate('modal.button.cancel')}
                                    </Component.Button>
                                    <Component.Button type="submit">
                                        {fields.id !== ''
                                            ? translate('modal.button.update')
                                            : translate('modal.button.add')}
                                    </Component.Button>
                                </Component.ModalActions>
                            </Component.Form>
                        </Component.ModalBody>
                    </>
                );
        }
    };
    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title" scroll="body">
            {modalContent()}
        </Component.Modal>
    );
};
export default memo(QuestionsGroupLgpd);
