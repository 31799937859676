import styled from 'styled-components';

export const Container = styled.span`
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:before {
        width: 16px;
        height: 16px;
        content: '';
        margin-right: 8px;
        border-radius: 8px;
    }

    &.initiated {
        &:before {
            color: #fff;
            background-color: #222222;
            background-image: linear-gradient(180deg, #222222, #414141);
        }
    }
    &.under_analysis,
    &.in_progress {
        &:before {
            color: #000;
            background-color: #e6b800;
            background-image: linear-gradient(180deg, #e6b800, #f1d255);
        }
    }
    &.open {
        &:before {
            color: #fff;
            background-color: #0264bd;
            background-image: linear-gradient(180deg, #0264bd, #2f92d9);
        }
    }
    &.reproved,
    &.pending,
    &.not_enabled {
        &:before {
            color: #fff;
            background-color: #850202;
            background-image: linear-gradient(180deg, #850202, #e91b1b);
        }
    }
    &.approved,
    &.finished,
    &.enabled {
        &:before {
            color: #fff;
            background-color: #036c1a;
            background-image: linear-gradient(180deg, #036c1a, #19a128);
        }
    }
    &.expired {
        &:before {
            color: #fff;
            background-color: #ed4407;
            background-image: linear-gradient(180deg, #ed4407, #d45c28);
        }
    }
    &.canceled {
        &:before {
            color: #fff;
            background-color: #e3093c;
            background-image: linear-gradient(180deg, #e3093c, #f72f5e);
        }
    }
    &.waiting_change {
        &:before {
            color: #fff;
            background-color: #4f04db;
            background-image: linear-gradient(180deg, #4f04db, #8d54f7);
        }
    }
    &.waiting_answer {
        &:before {
            color: #fff;
            background-color: #026ccf;
            background-image: linear-gradient(180deg, #026ccf, #1e8ef7);
        }
    }
    &.answered {
        &:before {
            color: #000;
            background-color: #859104;
            background-image: linear-gradient(180deg, #859104, #9dab07);
        }
    }
    &.question_answered {
        &:before {
            color: #000;
            background-color: #04b7c7;
            background-image: linear-gradient(180deg, #04b7c7, #65ebf7);
        }
    }
`;
