import React, { memo } from 'react';

import * as Component from './styled';

interface Props {
    type?: 'date' | 'time';
    variant?: any;
    color?: 'primary' | 'secondary';
    className?: string;
    label: string;
    name?: string;
    value: string;
    error?: any;
    helperText?: any;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputText: React.FC<Props> = ({
    type,
    variant,
    className,
    color,
    label,
    name,
    value,
    error,
    helperText,
    disabled,
    onChange,
}) => {
    return (
        <Component.InputCustom
            type={type ?? 'datetime-local'}
            variant={variant ?? 'outlined'}
            className={className ?? ''}
            color={color}
            label={label}
            name={name}
            defaultValue={value}
            onChange={onChange}
            error={error}
            helperText={helperText}
            autoComplete="off"
            disabled={disabled}
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
};

export default memo(InputText);
