import React, { memo } from 'react';

import * as Component from './styled';

import useModerator from './useModerator';

interface PropsModerator {
    user: {
        name: string;
        password: string;
        activeModerator: boolean;
    };
    message?: string;
    open: boolean;
    openRoom: string;
    status: 'loading' | 'success' | 'error' | '';
    onClose: () => void;
    onCreateModerator: () => void;
    onUpdateModerator: () => void;
}

const Moderator: React.FC<PropsModerator> = ({
    user,
    message,
    open,
    openRoom,
    status,
    onClose,
    onCreateModerator,
    onUpdateModerator,
}) => {
    const { translate, goToPageRoom } = useModerator();

    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        <Component.ModalTitle>
                            {translate(
                                user?.activeModerator ? 'modal.moderator.title.update' : 'modal.moderator.title.create',
                            )}
                        </Component.ModalTitle>
                        <Component.ModalBody>
                            {user?.activeModerator && (
                                <>
                                    <Component.UserInfo>
                                        {translate('modal.moderator.user')}:{' '}
                                        <Component.Strong>{user.name}</Component.Strong>
                                    </Component.UserInfo>
                                    <Component.UserInfo>
                                        {translate('modal.moderator.password')}:{' '}
                                        <Component.Strong>{user.password}</Component.Strong>
                                    </Component.UserInfo>
                                </>
                            )}
                        </Component.ModalBody>
                        <Component.ModalActions>
                            <Component.GroupGenaratorUser>
                                <Component.Button
                                    fullWidth
                                    onClick={user?.activeModerator ? onUpdateModerator : onCreateModerator}
                                >
                                    {translate(
                                        user?.activeModerator
                                            ? 'modal.moderator.button.generatenewPassword'
                                            : 'modal.moderator.button.generatePassword',
                                    )}
                                </Component.Button>
                                {user?.activeModerator && !!openRoom && (
                                    <Component.Button
                                        fullWidth
                                        onClick={() => goToPageRoom(openRoom)}
                                        color="secondary"
                                    >
                                        <Component.MeetingRoomIcon />
                                        {translate('modal.moderator.button.enterTheRoom')}
                                    </Component.Button>
                                )}
                                <Component.Button fullWidth onClick={onClose} color="primary">
                                    {translate('modal.button.cancel')}
                                </Component.Button>
                            </Component.GroupGenaratorUser>
                        </Component.ModalActions>
                    </>
                );
        }
    };

    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title">
            {modalContent()}
        </Component.Modal>
    );
};

export default memo(Moderator);
