import React, { memo } from 'react';

import NotImage from 'assets/images/image-default.svg';

import * as Component from './styled';

interface PropsTitle {
    className?: string;
    src: string;
    srcSet?: string;
    alt?: any;
    loading?: any;
}

const Image: React.FC<PropsTitle> = ({ className, src, srcSet, alt, loading }) => (
    <Component.Img
        className={className}
        src={src}
        srcSet={srcSet}
        alt={alt}
        loading={loading}
        onError={(event: any) => {
            event.target.onerror = null;
            event.target.src = NotImage;
        }}
    />
);

export default memo(Image);
