import { ReactNode } from 'react';
import { useVideoDynamicProvider, VideoDynamicContext } from './useVideoDynamic';

interface ChildrenProps {
    children?: ReactNode;
}

const VideoDynamic = ({ children }: ChildrenProps) => {
    const { value } = useVideoDynamicProvider();

    return <VideoDynamicContext.Provider value={value}>{children}</VideoDynamicContext.Provider>;
};

export default VideoDynamic;
