import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import * as Component from './styled';

interface PropsError {
    opinion_id: string;
    observation: string;
}

interface Props {
    status: 'loading' | 'success' | 'error' | '';
    open: boolean;
    message?: string;
    opinion_id: string;
    observation: string;
    error: PropsError;
    onChange: (event: React.ChangeEvent<any>) => void;
    onClose: () => void;
    onConfirm: (event: React.ChangeEvent<HTMLFormElement>) => void;
}

const ConfirmOpinion: React.FC<Props> = ({
    status,
    open,
    message,
    opinion_id,
    observation,
    error,
    onChange,
    onClose,
    onConfirm,
}) => {
    const { t: translate } = useTranslation();

    const opinionOptions = useMemo(
        () => [
            {
                title: translate('modal.opinion.form.notEnabled'),
                value: '11',
            },
            {
                title: translate('modal.opinion.form.enabled'),
                value: '10',
            },
        ],
        [translate],
    );

    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        <Component.ModalTitle>{translate('modal.opinion.title')}</Component.ModalTitle>
                        <Component.ModalText>{translate('modal.opinion.description')}</Component.ModalText>
                        <Component.ModalBody>
                            <Component.Form onSubmit={onConfirm}>
                                <Component.FormGroup>
                                    <Component.InputSelect
                                        color="primary"
                                        name="opinion_id"
                                        label={translate('modal.opinion.form.selectOpinion')}
                                        options={opinionOptions}
                                        value={opinion_id}
                                        onChange={onChange}
                                        error={Boolean(error.opinion_id !== '')}
                                    />
                                </Component.FormGroup>
                                <Component.FormGroup>
                                    <Component.InputText
                                        color="primary"
                                        multiline
                                        minRows={4}
                                        maxRows={2}
                                        label={translate('modal.opinion.form.observation')}
                                        name="observation"
                                        value={observation}
                                        onChange={onChange}
                                        error={Boolean(error.observation !== '')}
                                    />
                                </Component.FormGroup>
                                <Component.ModalActions>
                                    <Component.Button onClick={onClose} color="primary">
                                        {translate('modal.button.cancel')}
                                    </Component.Button>
                                    <Component.Button type="submit">
                                        {translate('modal.button.confirm')}
                                    </Component.Button>
                                </Component.ModalActions>
                            </Component.Form>
                        </Component.ModalBody>
                    </>
                );
        }
    };

    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title">
            {modalContent()}
        </Component.Modal>
    );
};

export default memo(ConfirmOpinion);
