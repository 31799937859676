import React, { memo } from 'react';

import useVideoDynamicAdmin from './useVideoDynamicAdmin';

import * as Component from './styled';

interface PropsVideo {
    src: string;
}

interface PropsVolumeIcon {
    value: number;
}

const VolumeIcon: React.FC<PropsVolumeIcon> = ({ value }) => {
    if (value === 0) {
        return <Component.VolumeOffIcon />;
    }
    if (value <= 0.5) {
        return <Component.VolumeDownIcon />;
    }

    return <Component.VolumeUpIcon />;
};

const VideoDynamicAdmin: React.FC<PropsVideo> = ({ src }) => {
    const {
        videoRef,
        sliderRef,
        sliderVolumeRef,
        player,
        data,
        translate,
        onLoadedMetadata,
        onTimeUpdate,
        onVolumeChange,
        onPlay,
        onPause,
        onMarkerBar,
        handleTogglePlay,
        handleChangeProgress,
        handleChangeVolume,
        handleEditContent,
        handleOpenModalConfirm,
    } = useVideoDynamicAdmin();

    return (
        <Component.Container>
            <Component.Wrap>
                <Component.Video
                    ref={videoRef}
                    controlsList="nodownload noremoteplayback"
                    onContextMenu={(e) => e.preventDefault()}
                    onLoadedMetadata={onLoadedMetadata}
                    onPlay={onPlay}
                    onPause={onPause}
                    onTimeUpdate={onTimeUpdate}
                    onVolumeChange={onVolumeChange}
                    src={src}
                />
                <Component.Controls>
                    <Component.IconButton onClick={handleTogglePlay}>
                        <Component.Visible condition={!player.isPlaying}>
                            <Component.IconPlay />
                        </Component.Visible>
                        <Component.Visible condition={player.isPlaying}>
                            <Component.IconPause />
                        </Component.Visible>
                    </Component.IconButton>
                    <Component.Time>
                        {player.currentTime} / {player.durationFormated}
                    </Component.Time>
                    <Component.WrapSlider ref={sliderRef}>
                        <Component.Slider
                            min={0}
                            max={100}
                            step={0.5}
                            value={player.progress}
                            onChange={handleChangeProgress}
                        />
                        {data?.map((item) => (
                            <Component.MarkerSlider key={item.fake_id!} left={onMarkerBar(item.start_time)}>
                                <Component.Marker
                                    type={item.type!}
                                    status={null}
                                    hasClick
                                    onClick={() => handleEditContent(item.start_time)}
                                />
                                <Component.MenuOptions>
                                    <Component.ItemHeader>{item.time_Formated}</Component.ItemHeader>
                                    <Component.ItemButton
                                        type="button"
                                        size="small"
                                        onClick={() => handleEditContent(item.start_time)}
                                    >
                                        {translate('modal.videoDynamicDetailsEad.button.edit')}
                                    </Component.ItemButton>
                                    <Component.ItemButton
                                        type="button"
                                        size="small"
                                        onClick={() => handleOpenModalConfirm(item.start_time)}
                                    >
                                        {translate('modal.videoDynamicDetailsEad.button.remove')}
                                    </Component.ItemButton>
                                </Component.MenuOptions>
                            </Component.MarkerSlider>
                        ))}
                    </Component.WrapSlider>
                    <Component.WrapVolume>
                        <Component.IconButton>
                            <VolumeIcon value={player.volume} />
                        </Component.IconButton>
                        <Component.SliderVertical
                            orientation="vertical"
                            ref={sliderVolumeRef}
                            min={0}
                            max={1}
                            value={player.volume}
                            step={0.15}
                            onChange={handleChangeVolume}
                        />
                    </Component.WrapVolume>
                </Component.Controls>
            </Component.Wrap>
        </Component.Container>
    );
};

export default memo(VideoDynamicAdmin);
