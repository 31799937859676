import React, { memo, ReactNode } from 'react';

import * as Component from './styled';

interface Props {
    fullWidth?: boolean;
    type?: 'submit' | 'button';
    variant?: 'outlined' | 'contained';
    size?: 'small' | 'medium' | 'large';
    color?: 'primary' | 'secondary';
    className?: string;
    onClick?: any;
    disabled?: boolean;
    children: ReactNode;
}

const Button: React.FC<Props> = ({ fullWidth, type, variant, size, color, className, onClick, disabled, children }) => (
    <Component.ButtonCustom
        fullWidth={fullWidth ?? false}
        type={type ?? 'button'}
        variant={variant ?? 'contained'}
        size={size ?? 'medium'}
        color={color ?? 'default'}
        className={className}
        onClick={onClick}
        disabled={disabled}
    >
        {children}
    </Component.ButtonCustom>
);

export default memo(Button);
