import { PropsLesson, TypeTotalTimeCourse } from 'models';

/**
 * Função que seta um prefixo em todas as páginas
 * @method setPrefix
 * @param {string} link recebe a url uma url
 */
export function setPrefix(link: string) {
    if (process.env.REACT_APP_BASE_URL !== '/') {
        return `/${process.env.REACT_APP_BASE_URL?.toString()}${link}`;
    }

    return link;
}

/**
 * Função que linca para uma página
 * @method goToPage
 * @param {string} link recebe a url da página
 */
export function goToPage(link: string) {
    return link;
}

/**
 * Função que válida um e-mail
 * @method validateEmail
 * @param { string } email recebe o E-mail
 */
export function validateEmail(email: string) {
    const regexEmail = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (regexEmail.test(email)) {
        return true;
    }
    return false;
}

/**
 * Função que linca para uma página
 * @method debounce
 * @param {Function} callback recebe uma função
 * @param {number} delay recebe um tempo em segundos
 */
export function debounce(callback: (...params: any[]) => any, delay: number) {
    let inDebounce: ReturnType<typeof setTimeout>;

    return function fn(this: any, ...args: any[]) {
        clearTimeout(inDebounce);
        inDebounce = setTimeout(() => callback.apply(this, args), delay);
    };
}

/**
 * Função que retorna um texto curto
 * @method shortText
 * @param {string} text recebe uma string
 * @param {number} limit recebe o total de caracteres para retornar
 */
export function shortText(text: string, limit: number) {
    if (text.length > limit) {
        return `${text.substring(0, limit)}...`;
    }

    return text;
}

/**
 * Função que converte um arquvio base64
 * @method generateBase64
 * @param {any} file recebe uma string
 */
export function generateBase64(file: any) {
    return new Promise((resolve) => {
        let baseURL = null as any;
        const reader = new FileReader() as any;

        reader.readAsDataURL(file);

        reader.onload = () => {
            const { result } = reader;
            baseURL = result.split(';base64,');
            resolve(baseURL[1]);
        };
    });
}

/**
 * Função que retorna o primeiro e o último nome
 * @method shortName
 * @param {string} fullname recebe uma string
 */
export function shortName(fullname: string) {
    const fullName = fullname.split(' ');
    const firstName = fullName[0];
    const lastName = fullName.pop();

    if (lastName) {
        return `${firstName} ${lastName}`;
    }
    return firstName;
}

/**
 * Função que retorna o nome curto da empresa
 * @method shortNameCompany
 * @param {string} fullname recebe uma string
 */
export function shortNameCompany(fullname: string) {
    const fullName = fullname.split(' ');

    if (fullName[0] && !!fullName[1] && fullName[1]?.toLowerCase() !== 'de') {
        return `${fullName[0].charAt(0)}. ${fullName[1].substring(0, 5)}`;
    }

    if (fullName[0].toLowerCase() === 'grupo' && fullName[1]) {
        return `G. ${fullName[1]}`;
    }

    return fullName[0];
}

/**
 * Função que retorna as letras iniciais do nome para o avatar
 * @method nameInitials
 * @param {string} fullname recebe uma string
 */
export function nameInitials(fullname: string) {
    const fullName = fullname.split(' ');
    const firstName = fullName[0];
    const lastName = fullName.pop();

    if (lastName) {
        return `${firstName.charAt(0)}${lastName.charAt(0)}`;
    }

    return `${firstName.charAt(0)}${firstName.charAt(1)}`;
}

/**
 * Função que retorna o valor sem a mascara
 * @method unmask
 * @param {string} value recebe uma string
 */
export function unmask(value: string) {
    return value.replace(/[^\d]+/g, '');
}

/**
 * Função que retorna uma string com o formato titilize
 * @method toSlugText
 * @param {string} value recebe uma string
 */
export function toSlugText(value: string) {
    return value
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9 ]/g, '')
        .replace(/\s+/g, '-');
}

/**
 * Função que retorna uma string sem a acentuação
 * @method textNormalizeAccents
 * @param {string} value recebe uma string
 */
export function textNormalizeAccents(value: string) {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

/**
 * Função que retorna a extensão do arquivo
 * @method getExtension
 */
export function getExtension() {
    return /\.([0-9a-z]+)(?:[?#]|$)/i;
}

/**
 * Função que retorna o link para redirecionar para o módulos correto
 * @method linkRedirectPage
 * @param {number} module_id recebe uma string
 */
export function linkRedirectPage(module_id: number) {
    let page = setPrefix('/');
    if (module_id === 1) {
        page = setPrefix('/complaints');
    }
    if (module_id === 2) {
        page = setPrefix('/due-diligence');
    }
    if (module_id === 3) {
        page = setPrefix('/users');
    }
    if (module_id === 7) {
        page = setPrefix('/syndication');
    }
    if (module_id === 8) {
        page = setPrefix('/ead');
    }
    if (module_id === 9) {
        page = setPrefix('/lgpd');
    }
    return page;
}

/**
 * Função que verifica se o valor está vazio
 * @method isEmpty
 * @param {string | null} value recebe uma string
 */
export function isEmpty(value: string | null) {
    if (value === '' || value === null) {
        return '';
    }

    return value;
}

/**
 * Função que retorna um número inteiro randomico
 * @method generateNumber
 * @param {number} min recebe um número mínino
 * @param {number} max recebe um número máximo
 */
export function generateNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min)) + min;
}

/**
 * Função que retorna o tempo formatado "00:00:00"
 * @method formatTimeForCourse
 * @param {string} time recebe uma string do tempo
 */
export function formatTimeForCourse(time: string) {
    if (time === '') return;

    const newTime = time.split(':');

    newTime[0] = newTime[0] !== '00' ? `${Number(newTime[0])}h` : '';
    newTime[1] = newTime[1] !== '00' ? `${newTime[1]}m` : '';
    newTime[2] = newTime[2] !== '00' ? `${newTime[2]}s` : '';

    if (newTime[0] || newTime[1]) {
        return `${newTime[0]} ${newTime[1]} ${newTime[2]}`;
    }

    return `${newTime[1]} ${newTime[2]}`;
}

/**
 * Função que retorna o tempo em segundos
 * @method convertTimeToSeconds
 * @param {string} time recebe uma string no formato "00:00:00"
 */
export function convertTimeToSeconds(time: string) {
    return time
        ?.split(':')
        .reverse()
        .reduce((prev, curr, i) => prev + Number(curr) * 60 ** i, 0);
}

/**
 * Função que retorna o tempo formatado
 * @method formatTime
 * @param {string} time recebe o tempo
 * @param {auto} typeFormat recebe um número mínino
 */
export function formatTime(time: number, typeFormat: 'auto' | '' = '') {
    const hours = Math.floor(time / (60 * 60));
    const minutes = Math.floor(time / 60) % 60;
    const seconds = Math.floor(time % 60);

    let labelHours = String(hours);
    let labelSeconds = String(seconds);
    let labelMinutes = String(minutes);

    if (hours < 10) {
        labelHours = `0${hours}`;
    }

    if (minutes < 10) {
        labelMinutes = `0${minutes}`;
    }
    if (seconds < 10) {
        labelSeconds = `0${seconds}`;
    }

    if (typeFormat === 'auto') {
        return hours > 1 ? `${labelHours}:${labelMinutes}:${labelSeconds}` : `${labelMinutes}:${labelSeconds}`;
    }

    return `${labelHours}:${labelMinutes}:${labelSeconds}`;
}

/**
 * Função que retorna o horário formatado
 * @method getFormatTime
 * @param {string} time recebe o elemento nó do vídeo
 */
export function getFormatTime(time: string) {
    const newTime = time.split(':');
    return `${newTime[0]}:${newTime[1]}`;
}

/**
 * Função que retorna o fullscreen para o vídeo
 * @method launchIntoFullscreen
 * @param {any} element recebe o elemento nó do vídeo
 */
export function launchIntoFullscreen(element: any) {
    element.requestFullscreen();
}

/**
 * Função que retorna o tempo formatado
 * @method exitFullscreen
 */
export function exitFullscreen() {
    document.exitFullscreen();
}

/**
 * Função que retorna o total de aulas finalizadas
 * @method totalOfCompletedLesson
 * @param {PropsLesson} lessons recebe um array de módulus
 */
export function totalOfCompletedLesson(lessons: PropsLesson[]) {
    return lessons.filter((item) => item.status === 'finished')?.length;
}

/**
 * Função que retorna o total de aulas finalizadas
 * @method totalOfCompletedLesson
 * @param {number | null} type recebe um array de módulus
 */
export function getTypeLesson(type: number | null) {
    if (type === 0) {
        return 'quiz';
    }
    if (type === 1) {
        return 'video';
    }
    if (type === 2) {
        return 'attachments';
    }
    if (type === 3) {
        return 'sliders';
    }
    if (type === 4) {
        return 'dynamicVideo';
    }
    return null;
}

/**
 * Função que retorna o total de aulas finalizadas
 * @method getNameModules
 * @param {string | undefined} module recebe um array de módulus
 */
export function getNameModules(module: string | undefined) {
    if (module === 'due-diligence') {
        return 'diligence';
    }

    return module;
}

/**
 * Função que retorna o texto com a primeira letra em caixa alta
 * @method capitalizeFirstLetter
 * @param {string} word recebe uma string
 */
export function capitalizeFirstLetter(word: string) {
    const text = word.toLowerCase();

    return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
}

/**
 * Função que retorna o texto em caixa alta
 * @method capitalize
 * @param {string} text recebe uma string
 */
export function capitalize(text: string) {
    return text
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

/**
 * Função que retorna a carga total do curso
 * @method textTotalTimeCourse
 * @param {TypeTotalTimeCourse} value recebe uma string
 */
export function textTotalTimeCourse(value: TypeTotalTimeCourse) {
    const textData: string[] = [];
    if (value?.hours! > 0) {
        textData.push(`${value?.hours}h`);
    }
    if (value?.minutes! > 0) {
        textData.push(`${value?.minutes}m`);
    }

    if (textData.length === 1) {
        return textData[0]?.padStart(2, '0');
    }

    if (textData.length > 1) {
        return `${textData[0]?.padStart(2, '0')} ${textData[1]?.padStart(2, '0')}`;
    }

    return '0';
}
