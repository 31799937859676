import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { toSlugText } from 'utils';

import useDrawer from './useDrawer';
import { PERMISSION_ADMIN } from './helpers';

import * as Component from './styled';

const Drawer: React.FC = () => {
    const { t: translate } = useTranslation();
    const { profile, openDrawer, activated, expanded, handleChange, goToPage, onLogout } = useDrawer();

    return (
        <Component.ThemeProvider theme={Component.themeWhite}>
            <Component.DrawerCustom variant="persistent" anchor="left" open={openDrawer}>
                <Component.DrawerInner>
                    <Component.Accordion expanded={expanded === 'profile'} onChange={handleChange('profile')}>
                        <Component.AccordionSummary>
                            <Component.ListItem button>
                                <Component.IconPerson />
                                <Component.ListItemText primary={translate('navbar.profile')} />
                                <Component.ExpandMoreIcon />
                            </Component.ListItem>
                        </Component.AccordionSummary>
                        <Component.AccordionDetails>
                            <Component.List>
                                <Component.ListItem
                                    button
                                    onClick={() => goToPage('/profile')}
                                    active={Boolean(activated?.submenu === 'profile').toString()}
                                >
                                    <Component.ListItemText primary={translate('navbar.myAccount')} />
                                </Component.ListItem>
                                <Component.ListItem button onClick={onLogout} active="false">
                                    <Component.ListItemText primary={translate('navbar.signOutOfAccount')} />
                                </Component.ListItem>
                            </Component.List>
                        </Component.AccordionDetails>
                    </Component.Accordion>
                    <Component.Visible condition={profile?.levelAccess === 1}>
                        <Component.Accordion expanded={expanded === 'clients'} onChange={handleChange('clients')}>
                            <Component.AccordionSummary>
                                <Component.ListItem button>
                                    <Component.IconClint />
                                    <Component.ListItemText primary={translate('navbar.clients')} />
                                    <Component.ExpandMoreIcon />
                                </Component.ListItem>
                            </Component.AccordionSummary>
                            <Component.AccordionDetails>
                                <Component.List>
                                    <Component.ListItem
                                        button
                                        onClick={() => goToPage('/clients')}
                                        active={Boolean(activated?.submenu === 'clients').toString()}
                                    >
                                        <Component.ListItemText primary={translate('navbar.clients')} />
                                    </Component.ListItem>
                                </Component.List>
                            </Component.AccordionDetails>
                        </Component.Accordion>
                    </Component.Visible>
                    <Component.Visible
                        condition={profile?.levelAccess === 1 || (profile?.levelAccess === 2 && profile?.main === 1)}
                    >
                        <Component.Accordion expanded={expanded === 'companies'} onChange={handleChange('companies')}>
                            <Component.AccordionSummary>
                                <Component.ListItem button>
                                    <Component.IconCompany />
                                    <Component.ListItemText primary={translate('navbar.companies')} />
                                    <Component.ExpandMoreIcon />
                                </Component.ListItem>
                            </Component.AccordionSummary>
                            <Component.AccordionDetails>
                                <Component.List>
                                    <Component.ListItem
                                        button
                                        onClick={() => goToPage('/companies')}
                                        active={Boolean(activated?.submenu === 'companies').toString()}
                                    >
                                        <Component.ListItemText primary={translate('navbar.companies')} />
                                    </Component.ListItem>
                                </Component.List>
                            </Component.AccordionDetails>
                        </Component.Accordion>
                    </Component.Visible>
                    <Component.Visible condition={PERMISSION_ADMIN.includes(profile?.levelAccess as number)}>
                        <Component.Accordion expanded={expanded === 'users'} onChange={handleChange('users')}>
                            <Component.AccordionSummary>
                                <Component.ListItem button>
                                    <Component.IconUsers />
                                    <Component.ListItemText primary={translate('navbar.users')} />
                                    <Component.ExpandMoreIcon />
                                </Component.ListItem>
                            </Component.AccordionSummary>
                            <Component.AccordionDetails>
                                <Component.List>
                                    <Component.ListItem
                                        button
                                        onClick={() => goToPage('/users')}
                                        active={Boolean(activated?.submenu === 'users').toString()}
                                    >
                                        <Component.ListItemText primary={translate('navbar.users')} />
                                    </Component.ListItem>
                                    <Component.Visible
                                        condition={
                                            profile?.levelAccess === 1 ||
                                            (profile?.levelAccess === 2 && profile?.main === 1)
                                        }
                                    >
                                        <Component.ListItem
                                            button
                                            onClick={() => goToPage('/users/departaments')}
                                            active={Boolean(activated?.submenu === 'departaments').toString()}
                                        >
                                            <Component.ListItemText primary={translate('navbar.departaments')} />
                                        </Component.ListItem>
                                        <Component.ListItem
                                            button
                                            onClick={() => goToPage('/users/notifications')}
                                            active={Boolean(activated?.submenu === 'notifications').toString()}
                                        >
                                            <Component.ListItemText primary={translate('navbar.notifications')} />
                                        </Component.ListItem>
                                    </Component.Visible>
                                </Component.List>
                            </Component.AccordionDetails>
                        </Component.Accordion>
                    </Component.Visible>
                    {profile?.modules.map((item) => (
                        <Component.ItemMenuModule
                            key={item.id}
                            name={toSlugText(item.name)}
                            label={translate(`navbar.${item.name}`)}
                            expanded={expanded === toSlugText(item.name)}
                            active={activated?.submenu ?? null}
                            levelAccess={profile?.levelAccess}
                            onChange={handleChange(toSlugText(item.name))}
                            goToPage={goToPage}
                        />
                    ))}
                </Component.DrawerInner>
            </Component.DrawerCustom>
        </Component.ThemeProvider>
    );
};

export default memo(Drawer);
