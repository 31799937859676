import React, { memo } from 'react';

import * as Component from './styled';

interface Props {
    label: string;
    order: 'asc' | 'desc' | undefined;
    onClick: () => void;
}

const ButtonOrder: React.FC<Props> = ({ label, order, onClick }) => {
    return (
        <Component.ButtonOrder size="small" color="primary" onClick={onClick}>
            <Component.Group order={order}>
                <Component.IconUp />
                <Component.IconDown />
            </Component.Group>
            {label}
        </Component.ButtonOrder>
    );
};

export default memo(ButtonOrder);
