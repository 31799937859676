import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import * as Component from './styled';

type PropsFinalizeError = {
    type: boolean;
    description: boolean;
};

interface Props {
    open: boolean;
    status: 'loading' | 'success' | 'error' | '';
    message?: string;
    fields: any;
    error: PropsFinalizeError;
    onChangeText: (event: any) => void;
    onChangeType: (event: any) => void;
    onChangeAttachment: (event: any) => void;
    onRemoveAttachment: () => void;
    onConfirmFinalize: (event: React.ChangeEvent<HTMLFormElement>) => void;
    onGoback: () => void;
    onClose: () => void;
}

const FinalizeComplaint: React.FC<Props> = ({
    open,
    status,
    message,
    fields,
    error,
    onChangeText,
    onChangeType,
    onChangeAttachment,
    onRemoveAttachment,
    onConfirmFinalize,
    onGoback,
    onClose,
}) => {
    const { t: translate } = useTranslation();

    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                            <Component.ModalActions>
                                <Component.Button color="primary" onClick={onGoback}>
                                    {translate('modal.button.goback')}
                                </Component.Button>
                            </Component.ModalActions>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        <Component.ModalTitle>{translate('modal.finalizeComplaint.title')}</Component.ModalTitle>
                        <Component.ModalBody>
                            <Component.Form onSubmit={onConfirmFinalize}>
                                <Component.GroupButtonRadio error={error.type}>
                                    <Component.ButtonRadio
                                        label={translate('modal.finalizeComplaint.positive')}
                                        active={fields.type === 1}
                                        onClick={() => onChangeType(1)}
                                    />
                                    <Component.ButtonRadio
                                        label={translate('modal.finalizeComplaint.negative')}
                                        active={fields.type === 2}
                                        onClick={() => onChangeType(2)}
                                    />
                                    <Component.ButtonRadio
                                        label={translate('modal.finalizeComplaint.openInquiry')}
                                        active={fields.type === 3}
                                        onClick={() => onChangeType(3)}
                                    />
                                </Component.GroupButtonRadio>
                                <Component.FormGroup>
                                    <Component.InputText
                                        color="primary"
                                        label={translate('modal.finalizeComplaint.placeholderText')}
                                        name="description"
                                        multiline
                                        minRows={4}
                                        maxRows={8}
                                        value={fields.description}
                                        onChange={onChangeText}
                                        error={error.description}
                                    />
                                </Component.FormGroup>
                                <Component.GroupButtonAttach>
                                    <Component.ButtonUpload
                                        color="primary"
                                        label={translate('modal.button.attach')}
                                        type={fields?.attachment?.type}
                                        file={fields?.attachment?.url}
                                        onChange={onChangeAttachment}
                                        onRemove={onRemoveAttachment}
                                    />
                                </Component.GroupButtonAttach>
                                <Component.ModalActions>
                                    <Component.Button onClick={onClose} color="primary">
                                        {translate('modal.button.cancel')}
                                    </Component.Button>
                                    <Component.Button type="submit">
                                        {translate('modal.button.confirm')}
                                    </Component.Button>
                                </Component.ModalActions>
                            </Component.Form>
                        </Component.ModalBody>
                    </>
                );
        }
    };

    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title" scroll="body">
            {modalContent()}
        </Component.Modal>
    );
};

export default memo(FinalizeComplaint);
