import React from 'react';
import Loading from 'components/loading';

import { useAuth } from 'hooks/auth';
import Public from './Public';
import FirstAcsess from './FirstAccess';
import Admin from './Admin';
import AdminMain from './AdminMain';
import Customer from './Customer';
import Supplier from './Supplier';

const PERMISSION_CUSTOMER = [3];
const PERMISSION_SUPPLIER = [4];

const Routes: React.FC = () => {
    const { loadingPage, signed, profile } = useAuth();

    if (loadingPage) {
        return <Loading />;
    }

    if (!loadingPage && profile?.firstAccess === 1) {
        return <FirstAcsess />;
    }

    if (!loadingPage && signed && (profile?.levelAccess === 1 || (profile?.levelAccess === 2 && profile?.main === 1))) {
        return <AdminMain />;
    }

    if (!loadingPage && signed && profile?.levelAccess === 2 && profile?.main === 0) {
        return <Admin />;
    }

    if (!loadingPage && signed && PERMISSION_CUSTOMER.includes(profile?.levelAccess as number)) {
        return <Customer modules={profile?.modules ?? []} />;
    }

    if (!loadingPage && signed && PERMISSION_SUPPLIER.includes(profile?.levelAccess as number)) {
        return <Supplier />;
    }

    if (!loadingPage && !signed) {
        return <Public />;
    }

    return null;
};

export default Routes;
