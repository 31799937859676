import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import * as Component from './styled';

type Error = {
    id?: string;
    question_id?: string;
    description?: string;
    mask?: string;
    field?: string;
    type_regime?: string;
};

interface Props {
    open: boolean;
    status: 'loading' | 'success' | 'error' | '';
    message?: string;
    fields: any;
    error: Error;
    onChange: (event: any) => void;
    onConfirm: (event: React.ChangeEvent<HTMLFormElement>) => void;
    onGoback: () => void;
    onClose: () => void;
}

const QuestionsDetails: React.FC<Props> = ({
    open,
    status,
    message,
    fields,
    error,
    onChange,
    onConfirm,
    onGoback,
    onClose,
}) => {
    const { t: translate } = useTranslation();
    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                            <Component.ModalActions>
                                <Component.Button color="primary" onClick={onGoback}>
                                    {translate('modal.button.goback')}
                                </Component.Button>
                            </Component.ModalActions>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        <Component.ModalTitle>
                            {fields.id !== ''
                                ? translate('modal.questionDetails.title.update')
                                : translate('modal.questionDetails.title.add')}
                        </Component.ModalTitle>
                        <Component.ModalBody>
                            <Component.Form onSubmit={onConfirm}>
                                <Component.Grid container spacing={3}>
                                    <Component.Grid item xs={12}>
                                        <Component.InputText
                                            multiline
                                            minRows={4}
                                            maxRows={8}
                                            color="primary"
                                            label={translate('modal.questionDetails.form.description')}
                                            name="description"
                                            value={fields.description}
                                            onChange={onChange}
                                            error={Boolean(error.description)}
                                            helperText={error.description}
                                        />
                                    </Component.Grid>
                                    {fields.id === '' && (
                                        <Component.Grid item xs={12} md={8}>
                                            <Component.InputSelect
                                                color="primary"
                                                label={translate('modal.questionDetails.form.type_field')}
                                                name="field"
                                                options={fields.fields}
                                                value={fields.field}
                                                onChange={onChange}
                                                error={Boolean(error.field)}
                                                helperText={error.field}
                                            />
                                        </Component.Grid>
                                    )}
                                    {fields.id === '' && (
                                        <Component.Grid item xs={12} md={8}>
                                            <Component.InputSelect
                                                color="primary"
                                                label={translate('modal.questionDetails.form.mask')}
                                                name="mask"
                                                options={fields.masks}
                                                value={fields.mask}
                                                onChange={onChange}
                                                error={Boolean(error.mask)}
                                                helperText={error.mask}
                                            />
                                        </Component.Grid>
                                    )}

                                    {fields.id === '' && (
                                        <Component.Grid item xs={12} md={8}>
                                            <Component.InputSelect
                                                color="primary"
                                                label={translate('modal.questionDetails.form.type_regime')}
                                                name="type_regime"
                                                options={fields.type_regimes}
                                                value={fields.type_regime}
                                                onChange={onChange}
                                                error={Boolean(error.type_regime)}
                                                helperText={error.type_regime}
                                            />
                                        </Component.Grid>
                                    )}
                                </Component.Grid>
                                <Component.ModalActions>
                                    <Component.Button onClick={onClose} color="primary">
                                        {translate('modal.button.cancel')}
                                    </Component.Button>
                                    <Component.Button type="submit">
                                        {fields.id !== ''
                                            ? translate('modal.button.update')
                                            : translate('modal.button.add')}
                                    </Component.Button>
                                </Component.ModalActions>
                            </Component.Form>
                        </Component.ModalBody>
                    </>
                );
        }
    };
    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title" scroll="body">
            {modalContent()}
        </Component.Modal>
    );
};
export default memo(QuestionsDetails);
