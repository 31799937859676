import React, { memo } from 'react';

import * as Component from './styled';

interface Props {
    label: string;
    active?: boolean;
    disabled?: boolean;
    onClick?: () => void;
}

const ButtonRadio: React.FC<Props> = ({ label, active, disabled, onClick }) => (
    <Component.ButtonCustom color={active ? 'secondary' : 'primary'} disabled={disabled} onClick={onClick}>
        {label}
    </Component.ButtonCustom>
);

export default memo(ButtonRadio);
