import React, { memo } from 'react';

import { PropsSelect } from 'models';

import * as Component from './styled';

interface Props {
    color?: 'primary' | 'secondary';
    label?: string;
    name?: string;
    options: PropsSelect[];
    value: string;
    error?: boolean;
    helperText?: string;
    onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
    disabled?: boolean;
}

const InputSelect: React.FC<Props> = ({
    color,
    options,
    label,
    name,
    value,
    error,
    helperText,
    onChange,
    disabled,
}) => (
    <Component.ThemeProvider theme={Component.themeSelect}>
        <Component.FormControlCustom color={color} variant="outlined">
            {label && <Component.InputLabelCustom error={error}>{label}</Component.InputLabelCustom>}
            <Component.InputSelectCustom
                color={color}
                disabled={disabled ?? false}
                label={label}
                name={name}
                onChange={onChange}
                value={value}
                error={error}
            >
                {options?.map((item) => (
                    <Component.OptionSelect key={`${item.value}_${item.title}`} value={item.value}>
                        {item.title}
                    </Component.OptionSelect>
                ))}
            </Component.InputSelectCustom>
            {helperText && <Component.FormHelperText error={error}>{helperText}</Component.FormHelperText>}
        </Component.FormControlCustom>
    </Component.ThemeProvider>
);

export default memo(InputSelect);
