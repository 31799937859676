import { useState, useEffect, useContext, createContext, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import api from 'service/api';

import { useNotification } from 'hooks/notification';

import { PropsSelect } from 'models';

type Modules = {
    id: string | number;
    name: string;
};

interface PropsAttachment {
    file: any;
    file_name: string | null;
    url: string | null;
    ext?: string | null;
}

type User = {
    id: string | number;
    name: string;
    profilePicture: PropsAttachment;
    email: string;
    type_document: string;
    document: string;
    levelAccess: number;
    levelAccessName: string;
    firstAccess: number | null;
    main: 0 | 1;
    address: string;
    number: string;
    city: string;
    states: PropsSelect[];
    state: string;
    postal_code: string;
    client_id: string;
    modules: Modules[] | [];
};

interface Props {
    loadingPage: boolean;
    loadingForm: boolean;
    signed: boolean;
    profile: User | null;
    setLoadingPage(value: boolean): void;
    signIn(login: string, password: string): void;
    signOut(): void;
    onLogout(): void;
    onChageProfile({ ...props }): void;
    sendCodeToEmail(email: string, callback?: Function): Promise<void>;
    getInfoUser(): void;
}

export const AuthContext = createContext({} as Props);

export function useAuth() {
    const context = useContext(AuthContext);
    return context;
}

export function useAuthProvider() {
    const { openNotification } = useNotification();

    const [loadingPage, setLoadingPage] = useState(true);
    const [loadingForm, setLoadingForm] = useState(false);
    const [signed, setSigned] = useState(false);
    const [profile, setProfile] = useState<User | null>(null);

    const { t: translate } = useTranslation();

    const signIn = useCallback(
        async (login: string, password: string): Promise<void> => {
            setLoadingForm(true);

            try {
                const { data } = await api.post('/login', {
                    email: login,
                    password,
                });

                const { user, dataProfile } = data;

                if (user) {
                    setLoadingPage(true);

                    setProfile({
                        id: user.id,
                        name: user.name,
                        profilePicture: {
                            url: user.url,
                        },
                        email: user.email,
                        type_document: user?.cpf_cnpj?.length > 11 ? 'PJ' : 'PF',
                        document: user.cpf_cnpj,
                        levelAccess: user.level_access_id,
                        levelAccessName: translate(`navbar.levelAccessName.${user.level_access_id}`),
                        firstAccess: user.first_access,
                        main: user.main,
                        address: user?.address ?? '',
                        number: user?.number ?? '',
                        city: user?.city ?? '',
                        state: user?.state_id ?? '',
                        postal_code: user?.postal_code ?? '',
                        client_id: user?.client_id ? String(user.client_id) : '',
                        modules:
                            dataProfile?.map((item: any) => ({
                                id: item.module_id,
                                name: item.module,
                            })) ?? [],
                    } as User);

                    localStorage.setItem(`@${process.env.REACT_APP_NAME}:access_token`, user.access_token);
                    api.defaults.headers.common.Authorization = `Bearer ${user.access_token}`;

                    setSigned(true);
                }
            } catch (error: any) {
                const { data } = error;
                openNotification(translate(`validation.${data.error}`), 'error');
            } finally {
                setLoadingPage(false);
                setLoadingForm(false);
            }
        },
        [openNotification, translate],
    );

    const signOut = useCallback(() => {
        localStorage.removeItem(`@${process.env.REACT_APP_NAME}:access_token`);
        delete api.defaults.headers.common.Authorization;
        setSigned(false);
        setProfile(null);
        setLoadingPage(false);
    }, []);

    const getInfoUser = useCallback(async () => {
        try {
            const {
                data: { data, dataProfile },
            } = await api.get('/user');

            if (!data.id) {
                setSigned(false);
                setLoadingPage(false);
                return;
            }

            setProfile(
                (oldProfile) =>
                    ({
                        ...oldProfile,
                        ...{
                            id: data.id,
                            name: data.name,
                            profilePicture: {
                                url: data.url,
                            },
                            email: data.email,
                            type_document: data?.cpf_cnpj?.length > 11 ? 'PJ' : 'PF',
                            document: data.cpf_cnpj,
                            levelAccess: data.level_access_id,
                            levelAccessName: translate(`navbar.levelAccessName.${data.level_access_id}`),
                            firstAccess: data.first_access,
                            main: data.main,
                            address: data.address,
                            number: data.number,
                            city: data.city,
                            state: data.state_id,
                            postal_code: data.postal_code,
                            client_id: String(data.client_id),
                            modules:
                                dataProfile?.map((item: any) => ({
                                    id: item.module_id,
                                    name: item.module,
                                })) ?? [],
                        },
                    } as User),
            );
            setSigned(true);
        } catch (error: any) {
            const { data } = error;
            openNotification(translate(`validation.${data.error}`), 'error');
        } finally {
            setLoadingPage(false);
        }
    }, [openNotification, translate]);

    const sendCodeToEmail = useCallback(
        async (email: string, callback?: Function): Promise<void> => {
            setLoadingForm(true);

            try {
                const {
                    data: { success, message },
                } = await api.post('/password/email', {
                    email,
                });

                if (success) {
                    openNotification(translate(`validation.${message}`), 'success');
                    if (callback) callback();
                }
            } catch (error: any) {
                const { data } = error;
                openNotification(translate(`validation.${data.error}`), 'error');
            } finally {
                setLoadingForm(false);
            }
        },
        [openNotification, translate],
    );

    const onLogout = useCallback(async () => {
        setLoadingPage(true);
        const {
            data: { success },
        } = await api.put(`/logout`);
        if (success) {
            setTimeout(() => {
                signOut();
            }, 1000);
        }
    }, [signOut]);

    const onChageProfile = useCallback(({ ...props }) => {
        setProfile(
            (oldProfile) =>
                ({
                    ...oldProfile,
                    ...props,
                } as User),
        );
    }, []);

    useEffect(() => {
        const accessToken = localStorage.getItem(`@${process.env.REACT_APP_NAME}:access_token`);

        if (accessToken && !signed && profile === null) {
            api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

            getInfoUser();
        }

        if (!accessToken && profile === null) {
            setLoadingPage(false);
        }
    }, [signed, profile, getInfoUser]);

    const value = useMemo(
        () => ({
            loadingPage,
            loadingForm,
            signed,
            profile,
            setLoadingPage,
            signIn,
            signOut,
            onLogout,
            onChageProfile,
            sendCodeToEmail,
            getInfoUser,
        }),
        [
            loadingPage,
            loadingForm,
            signed,
            profile,
            setLoadingPage,
            signIn,
            signOut,
            onLogout,
            onChageProfile,
            sendCodeToEmail,
            getInfoUser,
        ],
    );

    return {
        value,
    };
}
