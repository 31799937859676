import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { Button as CustomButton } from 'components/button';

export const ButtonNextSlider = styled(IconButton).attrs({
    className: 'button__slider--ead button__slider--ead-next',
})``;

export const ButtonNextLesson = styled(CustomButton).attrs({
    className: 'button__slider--ead button__slider--ead-next',
})`
    && {
        width: auto;
        background: transparent;
        box-shadow: none;
        margin-bottom: 0;

        &:hover {
            background: transparent;
            box-shadow: none;
        }
        svg {
            margin-right: 5px;
        }
    }
`;

export const IconNextSlider = styled(NavigateNextIcon)``;

export const IconNextLesson = styled(ArrowRightAltIcon)`
    font-size: 18px;
    margin-right: 5px;
`;
