import { useMemo, useState, useCallback, ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import api from 'service/api';
import { capitalize } from 'utils';

import { useDrawer } from 'hooks/drawer';
import { useAuth } from 'hooks/auth';
import { useTheme } from 'hooks/theme';

import { PropsSelect } from 'models';

interface PropsFields {
    customers: PropsSelect[];
    customerSelected: string;
}

export default function useNavbar() {
    const { t: translate } = useTranslation();
    const { openDrawer, activated, onOpenDrawer } = useDrawer();
    const { profile, setLoadingPage, onChageProfile } = useAuth();
    const { haveIt } = useTheme();

    const initialFields = useMemo(
        () => ({
            customers: [],
            customerSelected: profile?.client_id ?? '',
        }),
        [profile?.client_id],
    );

    const [fields, setFields] = useState<PropsFields>(initialFields);

    const show = useCallback(async () => {
        try {
            if (profile?.levelAccess === 1) {
                const {
                    data: { dataClient },
                } = await api.get(`/configuration/client`);

                setFields((oldFields) => ({
                    ...oldFields,
                    ...{
                        customers: dataClient.map((item: any) => ({
                            title: capitalize(item.name),
                            value: item.id,
                        })),
                        customerSelected: profile.client_id,
                    },
                }));
            }
        } catch (error) {
            console.error(error);
        }
    }, [profile?.levelAccess, profile?.client_id]);

    const handleChangeCustomer = useCallback(
        async (event: ChangeEvent<{ value: unknown }>) => {
            onChageProfile({ client_id: event.target.value });
            setFields((oldFields) => ({
                ...oldFields,
                customerSelected: String(event.target.value),
            }));

            try {
                setLoadingPage(true);
                await api.post(`/client/selected?client_id=${event.target.value}`);
            } catch (error: any) {
                console.log(error);
            } finally {
                setLoadingPage(false);
            }
        },
        [setLoadingPage, onChageProfile],
    );

    useEffect(() => {
        show();
    }, [show]);

    return {
        openDrawer,
        activated,
        profile,
        haveIt,
        fields,
        translate,
        onOpenDrawer,
        handleChangeCustomer,
    };
}
