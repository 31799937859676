import React, { Suspense, lazy } from 'react';
import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';

import { setPrefix } from 'utils';

import Loading from 'components/loading';

const Layout = lazy(() => import('components/layout'));

const Profile = lazy(() => import('pages/profile'));

const RegistrationSupplier = lazy(() => import('pages/registrationSupplier'));

// Rotas publicas
const ExternalQuiz = lazy(() => import('pages/modules/lgpd/externalQuiz'));

const FirstAccess: React.FC = () => (
    <Suspense fallback={<Loading />}>
        <Router>
            <Layout>
                <Routes>
                    <Route path={setPrefix('/profile')} element={<Profile />} />
                    <Route
                        path={setPrefix('/registration-supplier/:uuid/:user_id')}
                        element={<RegistrationSupplier />}
                    />
                    <Route path={setPrefix('/registration-lgpd-quiz/:user_id/:uuid')} element={<ExternalQuiz />} />
                    <Route path="*" element={<Navigate to={setPrefix('/profile')} replace />} />
                </Routes>
            </Layout>
        </Router>
    </Suspense>
);

export default FirstAccess;
