import React, { memo, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import * as Component from './styled';

export interface PropsReportEad {
    title?: string;
    text?: string;
    message?: string;
    open: boolean;
    status: 'loading' | 'success' | 'error' | '';
    fields: {
        course_id: number | string;
        start_date: string;
        end_date: string;
    };
    errors: {
        start_date: boolean;
        end_date: boolean;
    };
    onClose: () => void;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onConfirm: (event: React.ChangeEvent<HTMLFormElement>) => void;
}

const ReportEad: React.FC<PropsReportEad> = ({
    title,
    text,
    message,
    open,
    status,
    fields,
    errors,
    onClose,
    onChange,
    onConfirm,
}) => {
    const { t: translate } = useTranslation();

    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        {title && <Component.ModalTitle>{title}</Component.ModalTitle>}
                        <Component.ModalBody>
                            {text && <Component.ModalText>{text}</Component.ModalText>}
                            <Component.Grid container spacing={4}>
                                <Component.Grid item xs={12}>
                                    <Component.InputDateTime
                                        type="date"
                                        color="primary"
                                        label={translate('modal.reportEad.form.start_date')}
                                        name="start_date"
                                        value={fields.start_date}
                                        onChange={onChange}
                                        error={errors.start_date}
                                    />
                                </Component.Grid>
                                <Component.Grid item xs={12}>
                                    <Component.InputDateTime
                                        type="date"
                                        color="primary"
                                        label={translate('modal.reportEad.form.end_date')}
                                        name="end_date"
                                        value={fields.start_date}
                                        onChange={onChange}
                                        error={errors.end_date}
                                    />
                                </Component.Grid>
                            </Component.Grid>
                        </Component.ModalBody>
                        <Component.ModalActions>
                            <Component.Button onClick={onClose} color="primary">
                                {translate('modal.button.cancel')}
                            </Component.Button>
                            <Component.Button onClick={onConfirm}>{translate('modal.button.confirm')}</Component.Button>
                        </Component.ModalActions>
                    </>
                );
        }
    };

    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title">
            {modalContent()}
        </Component.Modal>
    );
};

export default memo(ReportEad);
