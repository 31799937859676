import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import * as Component from './styled';

interface PropsStatus {
    name: string;
}

const Status: React.FC<PropsStatus> = ({ name }) => {
    const { t: translate } = useTranslation();

    return <Component.Container className={name}>{translate(`status.${name}`)}</Component.Container>;
};

export default memo(Status);
