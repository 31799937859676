import React, { memo, ReactNode } from 'react';

import * as Component from './styled';

interface Props {
    children: ReactNode;
    space?: boolean;
}

const FormGroup: React.FC<Props> = ({ children, space = true }, props) => (
    <Component.FormGroupCustom {...props} space={space}>
        {children}
    </Component.FormGroupCustom>
);

export default memo(FormGroup);
