import React, { memo } from 'react';

import * as Component from './styled';

interface PropsAttachment {
    ext: string;
    url: string;
    name?: string;
    id?: string;
    onRemove: (param: string) => void;
}

const AttachmentRemove: React.FC<PropsAttachment> = ({ ext, url, name, id = '', onRemove }) => {
    if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'svg' || ext === 'gif') {
        return (
            <Component.Container>
                <Component.ButtonCancel onClick={() => onRemove(url)}>
                    <Component.IconCancel />
                </Component.ButtonCancel>
                <Component.Wrapper>
                    <Component.FileImage href={url} target="_blank">
                        <Component.Image src={url} />
                    </Component.FileImage>
                </Component.Wrapper>
            </Component.Container>
        );
    }
    if (name) {
        return (
            <Component.Container>
                <Component.GroupFile>
                    <Component.ButtonCancel onClick={() => onRemove(id)}>
                        <Component.IconCancel />
                    </Component.ButtonCancel>
                    <Component.Button color="primary">
                        <Component.FileName href={url} target="_blank">
                            <Component.NameAttachment>{name}</Component.NameAttachment>
                        </Component.FileName>
                    </Component.Button>
                </Component.GroupFile>
            </Component.Container>
        );
    }

    return (
        <Component.Container>
            <Component.ButtonCancel onClick={() => onRemove(url)}>
                <Component.IconCancel />
            </Component.ButtonCancel>
            <Component.Wrapper>
                <Component.File href={url} target="_blank">
                    <Component.IconFile />
                </Component.File>
            </Component.Wrapper>
        </Component.Container>
    );
};

export default memo(AttachmentRemove);
