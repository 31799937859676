import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import * as Component from './styled';

type Error = {
    user?: string;
    type_document?: string;
    document?: string;
    name?: string;
    fantasy_name?: string;
    email?: string;
    address?: string;
    number?: string;
    district?: string;
    city?: string;
    state?: string;
    postal_code?: string;
};

interface Props {
    open: boolean;
    status: 'loading' | 'success' | 'error' | '';
    message?: string;
    fields: any;
    error: Error;
    levelAccess: number | undefined;
    module?: string;
    onChange: (event: any) => void;
    onChangeUserText: (event: any) => void;
    onChangeUser: (event: any) => void;
    onChangeMultiple: (event: any) => void;
    onConfirm: () => void;
    onGoback: () => void;
    onClose: () => void;
}

const PERMISSION_ADMIN = [1, 2, 3];

const SuppliersDetails: React.FC<Props> = ({
    open,
    status,
    message,
    fields,
    error,
    levelAccess,
    module,
    onChange,
    onChangeUserText,
    onChangeUser,
    onChangeMultiple,
    onConfirm,
    onGoback,
    onClose,
}) => {
    const { t: translate } = useTranslation();

    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                            <Component.ModalActions>
                                <Component.Button color="primary" onClick={onGoback}>
                                    {translate('modal.button.goback')}
                                </Component.Button>
                            </Component.ModalActions>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        <Component.ModalTitle>
                            {fields.id !== ''
                                ? translate('modal.suppliersDetails.title.update')
                                : translate('modal.suppliersDetails.title.add')}
                        </Component.ModalTitle>
                        <Component.ModalBody>
                            {fields.id !== '' && (
                                <Component.Table>
                                    <Component.Thead>
                                        <Component.Tr>
                                            <Component.Td>{translate('modal.suppliersDetails.status')}</Component.Td>
                                            <Component.Td>
                                                {module === 'lgpd'
                                                    ? translate('modal.suppliersDetails.date')
                                                    : translate('modal.suppliersDetails.dateExpiration')}
                                            </Component.Td>
                                        </Component.Tr>
                                    </Component.Thead>
                                    <Component.Tbody>
                                        <Component.Tr>
                                            <Component.Td>
                                                {fields.status && <Component.Status name={fields.status} />}
                                            </Component.Td>
                                            <Component.Td>{fields.date}</Component.Td>
                                        </Component.Tr>
                                    </Component.Tbody>
                                </Component.Table>
                            )}
                            <Component.Form>
                                <Component.Grid container spacing={3}>
                                    <Component.Visible
                                        condition={
                                            PERMISSION_ADMIN.includes(levelAccess as number) &&
                                            module === 'due-diligence'
                                        }
                                    >
                                        <Component.Grid item xs={12}>
                                            <Component.Label>
                                                {translate('modal.suppliersDetails.form.user')}
                                            </Component.Label>
                                            <Component.InputSearch
                                                color="primary"
                                                freeSolo
                                                size="medium"
                                                label={translate('modal.suppliersDetails.form.searchUser')}
                                                name="user"
                                                noOptionsText={translate('modal.suppliersDetails.form.userNotFound')}
                                                options={fields.users}
                                                value={fields?.user?.title ?? ''}
                                                onChange={(_event: any, value: any) => onChangeUser(value)}
                                                onChangeText={onChangeUserText}
                                                error={Boolean(error.user)}
                                                helperText={error.user}
                                            />
                                        </Component.Grid>
                                    </Component.Visible>
                                    <Component.Grid item xs={12}>
                                        <Component.GroupDocument>
                                            <Component.GroupRadios>
                                                <Component.InputRadio
                                                    label={translate('modal.suppliersDetails.form.personDocument')}
                                                    name="type_document"
                                                    value="PF"
                                                    checked={fields.type_document === 'PF'}
                                                    onChange={onChange}
                                                />
                                                <Component.InputRadio
                                                    label={translate('modal.suppliersDetails.form.companyDocument')}
                                                    name="type_document"
                                                    value="PJ"
                                                    checked={fields.type_document === 'PJ'}
                                                    onChange={onChange}
                                                />
                                            </Component.GroupRadios>
                                            <Component.InputText
                                                color="primary"
                                                label={
                                                    fields.type_document === 'PJ'
                                                        ? translate('modal.suppliersDetails.form.document.pj')
                                                        : translate('modal.suppliersDetails.form.document.pf')
                                                }
                                                name="document"
                                                value={fields.document}
                                                onChange={onChange}
                                                error={Boolean(error.document)}
                                                helperText={error.document}
                                                mask={fields.type_document === 'PJ' ? 'cnpj' : 'cpf'}
                                            />
                                        </Component.GroupDocument>
                                    </Component.Grid>
                                    <Component.Grid item xs={12}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.suppliersDetails.form.name')}
                                            name="name"
                                            value={fields.name}
                                            onChange={onChange}
                                            error={Boolean(error.name)}
                                            helperText={error.name}
                                        />
                                    </Component.Grid>
                                    {fields?.type_document === 'PJ' && (
                                        <Component.Grid item xs={12}>
                                            <Component.InputText
                                                color="primary"
                                                label={translate('modal.suppliersDetails.form.fantasy_name')}
                                                name="fantasy_name"
                                                value={fields.fantasy_name}
                                                onChange={onChange}
                                                error={Boolean(error.fantasy_name)}
                                                helperText={error.fantasy_name}
                                            />
                                        </Component.Grid>
                                    )}
                                    <Component.Grid item xs={12}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.suppliersDetails.form.email')}
                                            name="email"
                                            value={fields.email}
                                            onChange={onChange}
                                            error={Boolean(error.email)}
                                            helperText={error.email}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.suppliersDetails.form.address')}
                                            name="address"
                                            value={fields.address}
                                            onChange={onChange}
                                            error={Boolean(error.address)}
                                            helperText={error.address}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12} md={4}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.suppliersDetails.form.number')}
                                            name="number"
                                            value={fields.number}
                                            onChange={onChange}
                                            error={Boolean(error.number)}
                                            helperText={error.number}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12} md={8}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.suppliersDetails.form.district')}
                                            name="district"
                                            value={fields.district}
                                            onChange={onChange}
                                            error={Boolean(error.district)}
                                            helperText={error.district}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12} md={6}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.suppliersDetails.form.city')}
                                            name="city"
                                            value={fields.city}
                                            onChange={onChange}
                                            error={Boolean(error.city)}
                                            helperText={error.city}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12} md={6}>
                                        <Component.InputSelect
                                            color="primary"
                                            label={translate('modal.suppliersDetails.form.state')}
                                            name="state"
                                            options={fields.states}
                                            value={fields.state}
                                            onChange={onChange}
                                            error={Boolean(error.state)}
                                            helperText={error.state}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12} md={4}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.suppliersDetails.form.postal_code')}
                                            name="postal_code"
                                            value={fields.postal_code}
                                            onChange={onChange}
                                            error={Boolean(error.postal_code)}
                                            helperText={error.postal_code}
                                            mask="cep"
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12} md={8}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.suppliersDetails.form.complement')}
                                            name="complement"
                                            value={fields.complement}
                                            onChange={onChange}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12}>
                                        <Component.InputSelectMultiple
                                            color="primary"
                                            name="categories_selected"
                                            label={translate('modal.suppliersDetails.form.categories')}
                                            options={fields.categories}
                                            value={fields.categories_selected}
                                            onChange={onChangeMultiple}
                                        />
                                    </Component.Grid>
                                </Component.Grid>
                                <Component.ModalActions>
                                    <Component.Button onClick={onClose} color="primary">
                                        {translate('modal.button.cancel')}
                                    </Component.Button>
                                    <Component.Button onClick={onConfirm}>
                                        {fields.id !== ''
                                            ? translate('modal.button.update')
                                            : translate('modal.button.add')}
                                    </Component.Button>
                                </Component.ModalActions>
                            </Component.Form>
                        </Component.ModalBody>
                    </>
                );
        }
    };

    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title" scroll="body">
            {modalContent()}
        </Component.Modal>
    );
};

export default memo(SuppliersDetails);
