import React, { memo, ChangeEvent } from 'react';

import { TypeStatus, PropsSelect } from 'models';
import useFilterQuestionLgpd from './useFilterQuestionLgpd';

import * as Component from './styled';

interface PropsFields {
    description: string;
    masks: PropsSelect[];
    mask: string;
    type_fields: PropsSelect[];
    type_field: string;
    groups: PropsSelect[];
    group: string;
    type_regimes: PropsSelect[];
    type_regime: string;
}

interface PropsFieldsError {
    description: string;
    mask: string;
    type_field: string;
    group: string;
    type_regime: string;
}

interface PropsFilter {
    message?: string;
    open: boolean;
    status: TypeStatus;
    fields: PropsFields;
    error: PropsFieldsError;
    onClose: () => void;
    onChange: (event: ChangeEvent<any>) => void;
    onFilter: (event: ChangeEvent<HTMLFormElement>) => void;
    onClearFilter: () => void;
}

const FilterQuestionLgpd: React.FC<PropsFilter> = ({
    message,
    open,
    fields,
    error,
    status,
    onClose,
    onChange,
    onFilter,
    onClearFilter,
}) => {
    const { translate } = useFilterQuestionLgpd();

    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        <Component.ModalTitle>{translate('modal.filter.title')}</Component.ModalTitle>
                        <Component.ModalBody>
                            <Component.Form onSubmit={onFilter}>
                                <Component.FormGroup>
                                    <Component.InputTextSearch
                                        color="primary"
                                        label={translate('modal.filter.searchDescription')}
                                        name="description"
                                        value={fields.description}
                                        onChange={onChange}
                                        error={!!error.description}
                                    />
                                </Component.FormGroup>
                                <Component.FormGroup>
                                    <Component.InputSelect
                                        color="primary"
                                        label={translate('modal.questionDetailsLgpd.form.mask')}
                                        name="mask"
                                        options={fields.masks}
                                        value={fields.mask}
                                        onChange={onChange}
                                        error={Boolean(error.mask)}
                                    />
                                </Component.FormGroup>
                                <Component.FormGroup>
                                    <Component.InputSelect
                                        color="primary"
                                        label={translate('modal.questionDetailsLgpd.form.type_field')}
                                        name="type_field"
                                        options={fields.type_fields}
                                        value={fields.type_field}
                                        onChange={onChange}
                                        error={Boolean(error.type_field)}
                                    />
                                </Component.FormGroup>
                                <Component.FormGroup>
                                    <Component.InputSelect
                                        color="primary"
                                        label={translate('modal.questionDetailsLgpd.form.subtitle')}
                                        name="group"
                                        options={fields.groups}
                                        value={fields.group}
                                        onChange={onChange}
                                        error={Boolean(error.group)}
                                    />
                                </Component.FormGroup>
                                <Component.FormGroup>
                                    <Component.InputSelect
                                        color="primary"
                                        label={translate('modal.questionDetailsLgpd.form.type_regime')}
                                        name="type_regime"
                                        options={fields.type_regimes}
                                        value={fields.type_regime}
                                        onChange={onChange}
                                        error={Boolean(error.type_regime)}
                                    />
                                </Component.FormGroup>
                                <Component.FormGroup>
                                    <Component.ModalActions>
                                        <Component.Button type="button" onClick={onClearFilter} color="primary">
                                            {translate('modal.button.removeFilter')}
                                        </Component.Button>
                                        <Component.Button type="submit">
                                            {translate('modal.button.send')}
                                        </Component.Button>
                                    </Component.ModalActions>
                                </Component.FormGroup>
                            </Component.Form>
                        </Component.ModalBody>
                    </>
                );
        }
    };

    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title">
            {modalContent()}
        </Component.Modal>
    );
};

export default memo(FilterQuestionLgpd);
