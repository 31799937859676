import React, { memo } from 'react';

import * as Component from './styled';

interface Props {
    label?: string;
    hasNextLesson: boolean;
    status: string;
    currentSlider: number;
    totalSlider: number;
    onNextSlider?: (e: any) => void;
    onNextLesson?: () => void;
}

const ButtonNextSliderEad: React.FC<Props> = ({
    label,
    hasNextLesson,
    status,
    currentSlider,
    totalSlider,
    onNextSlider,
    onNextLesson,
}) => {
    if (currentSlider === totalSlider && hasNextLesson && status === 'finished') {
        if (label) {
            return (
                <Component.ButtonNextLesson onClick={onNextLesson}>
                    <Component.IconNextLesson />
                    {label}
                </Component.ButtonNextLesson>
            );
        }

        return (
            <Component.ButtonNextSlider onClick={onNextLesson}>
                <Component.IconNextSlider />
            </Component.ButtonNextSlider>
        );
    }

    if (currentSlider !== totalSlider) {
        return (
            <Component.ButtonNextSlider onClick={onNextSlider}>
                <Component.IconNextSlider />
            </Component.ButtonNextSlider>
        );
    }

    return null;
};

export default memo(ButtonNextSliderEad);
