import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #424242;
        border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #424242;
    }

    html, body, #root {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    body {
        background-color: rgba(86, 86, 86, 1);
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }
`;
