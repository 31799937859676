import { useMemo, useState, useCallback, useEffect, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import api from 'service/api';
import { generateNumber } from 'utils';

import { useNotification } from 'hooks/notification';
import { useAuth } from 'hooks/auth';

import { PropsQuiz, PropsAlternatives, PropsErrorQuiz } from 'pages/modules/ead/course/models';
import { useCourse } from 'pages/modules/ead/course/hooks/course';

interface PropsConfig {
    question_limit_view: {
        value: string;
        error: string;
    };
    percent_hits: {
        value: string;
        error: string;
    };
}

export default function useQuizDetails() {
    const { t: translate } = useTranslation();
    const { openNotification } = useNotification();
    const { signOut } = useAuth();

    const { course_id } = useParams();

    const { quizDetails, handleUpdateQuiz, handleCloseQuiz } = useCourse();

    const initialConfig = useMemo(
        () => ({
            question_limit_view: {
                value: '',
                error: '',
            },
            percent_hits: {
                value: '',
                error: '',
            },
        }),
        [],
    );

    const initialFields = useMemo(
        () => [
            {
                id: null,
                fake_id: generateNumber(10000, 100000),
                question: '',
                type: '11',
                alternatives: [
                    {
                        id: null,
                        fake_id: generateNumber(10000, 100000),
                        description: '',
                        is_correct: false,
                    },
                ],
            },
        ],
        [],
    );

    const [hasQuiz, setHasQuiz] = useState<boolean>(false);
    const [configQuiz, setConfigQuiz] = useState<PropsConfig>(initialConfig);
    const [fields, setFields] = useState<PropsQuiz[]>(initialFields);
    const [errors, setErrors] = useState<PropsErrorQuiz[]>([]);
    const [loadingModal, setLoadingModal] = useState(false);

    const show = useCallback(async () => {
        if (quizDetails?.lessonId === null) {
            const newQuestion = [
                {
                    id: null,
                    fake_id: generateNumber(10000, 100000),
                    question: '',
                    type: '11',
                    alternatives: [
                        {
                            id: null,
                            fake_id: generateNumber(10000, 100000),
                            description: '',
                            is_correct: false,
                        },
                    ],
                },
            ];

            setTimeout(() => {
                setFields(newQuestion);
            }, 1000);

            return;
        }

        setLoadingModal(true);

        try {
            const {
                data: { data, lesson },
            } = await api.get(`/ead/course/question/alternative?lesson_id=${quizDetails?.lessonId}`);

            setFields(
                data?.map((field: any) => ({
                    id: field.question_id,
                    fake_id: generateNumber(10000, 100000),
                    question: field.question,
                    type: '11',
                    alternatives: field?.alternatives?.map((alternative: any) => ({
                        id: alternative.alternative_id,
                        fake_id: generateNumber(10000, 100000),
                        description: alternative.alternative_description,
                        is_correct: alternative.alternative_is_correct === 1,
                    })),
                })) ?? [],
            );
            setHasQuiz(data?.length > 0);
            setConfigQuiz({
                question_limit_view: {
                    value: lesson?.question_limit_view,
                    error: '',
                },
                percent_hits: {
                    value: lesson?.percent_hits,
                    error: '',
                },
            });
        } catch (error: any) {
            const { data, message } = error;

            if (message === 'Unauthenticated.') {
                signOut();
                return;
            }

            openNotification(translate(`validation.${data?.error ?? message}`), 'error');
        } finally {
            setLoadingModal(false);
        }
    }, [quizDetails?.lessonId, signOut, openNotification, translate]);

    const handleGoback = useCallback(() => {
        handleUpdateQuiz({ ...quizDetails, status: 'loading' });
        setTimeout(() => {
            handleUpdateQuiz({ ...quizDetails, status: '' });
        }, 500);
    }, [quizDetails, handleUpdateQuiz]);

    const onInitFields = useCallback(() => {
        const newQuestion = {
            id: null,
            fake_id: generateNumber(10000, 100000),
            question: '',
            type: '11',
            alternatives: [
                {
                    id: null,
                    fake_id: generateNumber(10000, 100000),
                    description: '',
                    is_correct: false,
                },
            ],
        };

        setFields((oldFields) => [...oldFields, newQuestion]);
    }, []);

    const handleAddQuestion = useCallback(() => {
        onInitFields();
    }, [onInitFields]);

    const handleAddAlternative = useCallback((fake_question_id: number) => {
        const newAlternative = {
            id: null,
            fake_id: generateNumber(10000, 100000),
            description: '',
            is_correct: false,
        };

        setFields((oldFields) => [
            ...(oldFields?.map((item) => {
                if (item.fake_id === fake_question_id) {
                    item.alternatives = [...item.alternatives, newAlternative];
                }

                return item;
            }) ?? []),
        ]);
    }, []);

    const handleRemoveQuestion = useCallback(
        (question_id: number | null, fake_question_id: number, text: string) => {
            handleUpdateQuiz({ ...quizDetails, status: 'loading' });
            setTimeout(() => {
                handleUpdateQuiz({
                    ...quizDetails,
                    ...{
                        status: 'confirm',
                        title: translate(`modal.quizDetailsEad.title.removeQuestion`),
                        text,
                        questionId: question_id,
                        fakeQuestionId: fake_question_id,
                    },
                });
            }, 500);
        },
        [quizDetails, handleUpdateQuiz, translate],
    );

    const handleRemoveAlternative = useCallback(
        (
            is_correct: boolean,
            fake_question_id: number,
            fake_alternative_id: number,
            alternative_id: number | null,
            text: string,
        ) => {
            if (is_correct) return;
            handleUpdateQuiz({ ...quizDetails, status: 'loading' });
            setTimeout(() => {
                handleUpdateQuiz({
                    ...quizDetails,
                    ...{
                        status: 'confirm',
                        title: translate(`modal.quizDetailsEad.title.removeAlternative`),
                        text,
                        fakeQuestionId: fake_question_id,
                        fakeAlternativeId: fake_alternative_id,
                        alternativeId: alternative_id,
                    },
                });
            }, 500);
        },
        [quizDetails, handleUpdateQuiz, translate],
    );

    const updateTypeField = useCallback((type: string, item: any, name: string, value: string) => {
        if (type === 'radio') {
            return {
                ...item,
                [name]: String(item.fake_id) === String(value),
            };
        }
        return { ...item, [name]: value };
    }, []);

    const clearFieldsError = useCallback((fake_question_id: number, fake_alternative_id?: number) => {
        if (fake_alternative_id) {
            setErrors((oldErrors) => [
                ...(oldErrors?.map((error) => {
                    if (error.id === fake_question_id) {
                        error.alternatives?.map((alternativeError) => {
                            if (alternativeError.id === fake_alternative_id) {
                                alternativeError.alternative = '';
                            }
                            return alternativeError;
                        });
                        error.is_correct = '';
                    }
                    return error;
                }) ?? []),
            ]);

            return;
        }

        setErrors((oldErrors) => [
            ...oldErrors.map((error) => {
                if (error.id === fake_question_id) {
                    error.question = '';
                }
                return error;
            }),
        ]);
    }, []);

    const updateIsCorrect = useCallback((type: string, is_correct: boolean) => {
        return type === 'radio' ? false : is_correct;
    }, []);

    const handleChangeConfig = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setConfigQuiz((oldConfigQuiz) => ({
            ...oldConfigQuiz,
            [event.target.name]: {
                value: event.target.value,
                error: '',
            },
        }));
    }, []);

    const handleChange = useCallback(
        (
            event: ChangeEvent<HTMLInputElement>,
            name: string,
            fake_question_id: number,
            fake_alternative_id?: number,
        ) => {
            if (fake_alternative_id) {
                setFields((oldFields) => [
                    ...(oldFields?.map((item) => {
                        if (item.fake_id === fake_question_id) {
                            item = {
                                ...item,
                                alternatives: item.alternatives?.map((alternative) => {
                                    alternative = {
                                        ...alternative,
                                        is_correct: updateIsCorrect(event.target.type, alternative.is_correct),
                                    };
                                    if (alternative.fake_id === fake_alternative_id) {
                                        alternative = updateTypeField(
                                            event.target.type,
                                            alternative,
                                            name,
                                            event.target.value,
                                        );
                                    }
                                    return alternative;
                                }),
                            };
                        }

                        return item;
                    }) ?? []),
                ]);

                clearFieldsError(fake_question_id, fake_alternative_id);

                return;
            }

            setFields((oldFields) =>
                oldFields?.map((item) => {
                    if (item.fake_id === fake_question_id) {
                        item = updateTypeField(event.target.type, item, name, event.target.value);
                    }
                    return item;
                }),
            );

            clearFieldsError(fake_question_id);
        },
        [updateIsCorrect, updateTypeField, clearFieldsError],
    );

    const handleConfirm = useCallback(async () => {
        handleUpdateQuiz({ ...quizDetails, status: 'loading' });

        if (quizDetails.fakeQuestionId && quizDetails.fakeAlternativeId) {
            if (quizDetails?.alternativeId) {
                await api.delete(`/ead/course/question/alternative?alternative_id=${quizDetails.alternativeId}`);
            }
            setFields((oldFields) =>
                oldFields?.map((item) => {
                    if (item.fake_id === quizDetails.fakeQuestionId) {
                        item.alternatives = item.alternatives?.filter(
                            (alternative) => alternative.fake_id !== quizDetails.fakeAlternativeId,
                        );
                    }

                    return item;
                }),
            );

            handleUpdateQuiz({
                ...quizDetails,
                ...{
                    status: '',
                    fakeQuestionId: null,
                    questionId: null,
                    fakeAlternativeId: null,
                    alternativeId: null,
                },
            });

            if (quizDetails?.alternativeId) show();

            openNotification(translate('validation.Alternative was successfully deleted'), 'success');

            return;
        }

        if (quizDetails?.questionId) {
            await api.delete(`/ead/course/question?question_id=${quizDetails.questionId}`);
        }
        setFields((oldFields) => oldFields?.filter((item) => item.fake_id !== quizDetails.fakeQuestionId));

        setTimeout(() => {
            handleUpdateQuiz({
                ...quizDetails,
                ...{
                    status: '',
                    fakeQuestionId: null,
                    questionId: null,
                    fakeAlternativeId: null,
                    alternativeId: null,
                },
            });

            if (quizDetails?.questionId) show();

            openNotification(translate('validation.Question was deleted successfully'), 'success');
        }, 500);
    }, [quizDetails, show, handleUpdateQuiz, openNotification, translate]);

    const onValidateAlternative = useCallback(
        (alternatives: PropsAlternatives[], fake_id: number, allErrors: PropsErrorQuiz[], total: number) => {
            for (const alternative of alternatives) {
                if (alternative.description === '') {
                    allErrors = allErrors.map((item) => {
                        if (item.id === fake_id) {
                            item.alternatives = [
                                ...item.alternatives,
                                {
                                    id: alternative.fake_id,
                                    alternative: translate('validation.needAtLeastYourAlternatives'),
                                },
                            ];
                        }
                        return item;
                    });
                    total++;
                }
            }

            if (alternatives?.filter((item) => item.is_correct === true).length === 0) {
                allErrors = allErrors.map((item) => {
                    if (item.id === fake_id) {
                        item.is_correct = translate('validation.needToProvideACorrectAnswer');
                    }
                    return item;
                });
                total++;
            }

            return { errors: allErrors, errorTotal: total };
        },
        [translate],
    );

    const onValidate = useCallback(
        (fields: PropsQuiz[]) => {
            let allErrors: PropsErrorQuiz[] = [];
            let total: number = 0;

            for (const field of fields) {
                allErrors = [
                    ...allErrors,
                    {
                        id: field.fake_id,
                        question: '',
                        alternatives: [],
                        is_correct: '',
                    },
                ];
                if (field.question === '') {
                    allErrors = allErrors?.map((error) => {
                        if (error.id === field.fake_id) {
                            error.question = translate('validation.requestQuestion');
                        }
                        return error;
                    });
                    total++;
                }

                const { errors, errorTotal } = onValidateAlternative(
                    field.alternatives,
                    field.fake_id,
                    allErrors,
                    total,
                );
                allErrors = errors;
                total = errorTotal;
            }

            setErrors(allErrors);

            if (total > 1) {
                openNotification(translate('validation.requiredFields'), 'error');
            }

            return total > 0;
        },
        [openNotification, translate, onValidateAlternative],
    );

    const createQuiz = useCallback(async () => {
        try {
            setLoadingModal(true);
            const formData = new FormData();
            formData.append('is_file', '0');
            formData.append('lesson_id', String(quizDetails.lessonId));

            // eslint-disable-next-line guard-for-in
            for (const key in fields) {
                formData.append(`description[${key}]`, fields[key].question);
                formData.append(`field_id[${key}]`, fields[key].type);
                if (fields[key]?.id) formData.append(`question_id[${key}]`, String(fields[key].id));

                // eslint-disable-next-line guard-for-in
                for (const keyAlternative in fields[key].alternatives) {
                    formData.append(
                        `question[${key}][alternative][${keyAlternative}][alternative_description]`,
                        fields[key].alternatives[keyAlternative].description,
                    );
                    formData.append(
                        `question[${key}][alternative][${keyAlternative}][alternative_is_correct]`,
                        fields[key].alternatives[keyAlternative].is_correct ? '1' : '0',
                    );
                    if (fields[key].alternatives[keyAlternative]?.id)
                        formData.append(
                            `question[${key}][alternative][${keyAlternative}][alternative_id]`,
                            String(fields[key].alternatives[keyAlternative]?.id),
                        );
                }
            }

            await api.post(`/ead/course/module/lesson`, {
                course_id: String(course_id),
                course_module_id: String(quizDetails.moduleId),
                lesson_id: String(quizDetails.lessonId),
                is_file: String(quizDetails.typeLesson),
                attempts: String(quizDetails.attempts),
            });

            const {
                data: { success, message },
            } = await api.post(`/ead/course/question/alternative`, formData);

            if (success) {
                show();
                openNotification(translate(`validation.${message}`), 'success');
                handleUpdateQuiz({ ...quizDetails, status: 'saveConfig' });
            }
        } catch (error: any) {
            const { data } = error;

            openNotification(translate(`validation.${data.error}`), 'error');
        } finally {
            setLoadingModal(false);
        }
    }, [openNotification, translate, show, handleUpdateQuiz, course_id, fields, quizDetails]);

    const handleSubmit = useCallback(
        async (event: ChangeEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (onValidate(fields)) {
                return;
            }

            createQuiz();
        },
        [onValidate, createQuiz, fields],
    );

    const onValidateConfig = useCallback(() => {
        let total: number = 0;
        setConfigQuiz((oldConfigQuiz) => {
            console.log(fields.length);
            if (oldConfigQuiz.question_limit_view.value === '') {
                oldConfigQuiz.question_limit_view.error = translate('validation.requestQuestionLimitView');
                total++;
            }
            if (oldConfigQuiz.percent_hits.value === '') {
                oldConfigQuiz.percent_hits.error = translate('validation.requestPercentHits');
                total++;
            }
            if (Number(oldConfigQuiz.question_limit_view.value) <= 0) {
                oldConfigQuiz.question_limit_view.error = translate('validation.needValueGreaterThanZero');
                total++;
            }
            if (Number(oldConfigQuiz.percent_hits.value) <= 0) {
                oldConfigQuiz.percent_hits.error = translate('validation.needValueGreaterThanZero');
                total++;
            }
            if (
                Number(oldConfigQuiz.question_limit_view.value) > 0 &&
                Number(oldConfigQuiz.question_limit_view.value) > fields.length
            ) {
                oldConfigQuiz.question_limit_view.error = translate(
                    'validation.numberQuestionViewGreaterTotalQuestions',
                );
                total++;
            }

            return oldConfigQuiz;
        });

        if (total > 0) {
            openNotification(translate('validation.requiredFields'), 'error');
        }

        return total > 0;
    }, [openNotification, translate, fields]);

    const handleSubmitConfig = useCallback(
        async (event: ChangeEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (onValidateConfig()) {
                return;
            }

            try {
                setLoadingModal(true);

                await api.post(`/ead/course/module/lesson`, {
                    course_id: String(course_id),
                    course_module_id: String(quizDetails.moduleId),
                    lesson_id: String(quizDetails.lessonId),
                    is_file: String(quizDetails.typeLesson),
                    attempts: String(quizDetails.attempts),
                    question_limit_view: configQuiz.question_limit_view.value,
                    percent_hits: configQuiz.percent_hits.value,
                });
                handleUpdateQuiz({ ...quizDetails, status: '' });
                openNotification(translate(`validation.Configuration saved successfully`), 'success');
            } catch (error: any) {
                const { data } = error;

                openNotification(translate(`validation.${data.error}`), 'error');
            } finally {
                setLoadingModal(false);
            }
        },
        [onValidateConfig, handleUpdateQuiz, openNotification, translate, course_id, quizDetails, configQuiz],
    );

    useEffect(() => {
        show();
    }, [show]);

    return {
        loadingModal,
        hasQuiz,
        configQuiz,
        fields,
        errors,
        translate,
        handleGoback,
        handleCloseQuiz,
        handleAddQuestion,
        handleRemoveQuestion,
        handleAddAlternative,
        handleRemoveAlternative,
        handleChangeConfig,
        handleChange,
        handleConfirm,
        handleSubmit,
        handleSubmitConfig,
    };
}
