import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import yellow from '@material-ui/core/colors/yellow';

export { IconButton };

interface PropsMark {
    $status: string | null;
    $hasClick?: boolean;
}

function colorStatus({ ...props }) {
    if (props.$status === 'viewed') {
        return yellow[700];
    }
    if (props.$status === 'incorrect') {
        return props.theme.palette.error.main;
    }
    if (props.$status === 'answered' || props.$status === 'correct' || props.$status === 'finished') {
        return props.theme.palette.success.main;
    }

    return props.theme.palette.grey[900];
}

export const Marker = styled(IconButton)<PropsMark>`
    width: 18px;
    height: 18px;
    padding: 0;
    background-color: ${(props) => colorStatus(props)};
    position: absolute;
    top: 12px;
    left: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    transition: all 0.5s ease-in-out;
    cursor: ${(props) => (props?.$hasClick ? 'pointer' : 'default')};

    &:hover {
        background-color: ${(props) => colorStatus(props)};
    }

    &:after {
        width: 1px;
        height: 22px;
        content: '';
        border-left: 0.2rem dotted #000;
        border-right: 0.2rem dotted #fff;
        position: absolute;
        top: -12px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }

    svg {
        fill: ${(props) => (props?.$status === 'viewed' ? '#000' : '#fff')};

        &.icon__info {
            width: 4px;
        }
        &.icon__check {
            width: 10px;
        }
        &.icon__question {
            width: 8px;
        }
        &.icon__comment {
            width: 10px;
        }
    }
`;
