import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import * as Component from './styled';

interface PropsSelect {
    title: string;
    value: string;
}

interface PropsCompany {
    options: PropsSelect[] | [];
    selected: string;
    error?: boolean;
}

interface Props {
    message?: string;
    open: boolean;
    units: PropsCompany;
    status: 'loading' | 'success' | 'error' | '';
    onChange: (event?: any) => void;
    onClose: () => void;
    onUpdate: (event: any) => void;
}

const UpdateCompany: React.FC<Props> = ({ message, open, units, status, onChange, onClose, onUpdate }) => {
    const { t: translate } = useTranslation();

    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        <Component.ModalTitle>{translate('modal.updateCompany.title')}</Component.ModalTitle>
                        <Component.ModalBody>
                            <Component.ModalText>{translate('modal.updateCompany.description')}</Component.ModalText>
                            <Component.Form onSubmit={onUpdate}>
                                {units?.options.length > 0 && (
                                    <Component.FormGroup>
                                        <Component.InputSelect
                                            color="primary"
                                            name="company"
                                            label={translate('modal.updateCompany.form.selectCompany')}
                                            options={units.options}
                                            value={units.selected}
                                            onChange={onChange}
                                            error={units.error}
                                        />
                                    </Component.FormGroup>
                                )}
                                <Component.ModalActions>
                                    <Component.Button onClick={onClose} color="primary">
                                        {translate('modal.button.cancel')}
                                    </Component.Button>
                                    <Component.Button type="submit">
                                        {translate('modal.button.update')}
                                    </Component.Button>
                                </Component.ModalActions>
                            </Component.Form>
                        </Component.ModalBody>
                    </>
                );
        }
    };

    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title">
            {modalContent()}
        </Component.Modal>
    );
};

export default memo(UpdateCompany);
