import React, { memo } from 'react';

import * as Component from './styled';

interface PropsCustomContent {
    name: string;
    content: unknown;
}

const CustomContent: React.FC<PropsCustomContent> = ({ name, content }) => {
    if (name === 'progress') {
        return <Component.CourseProgress value={content as number} />;
    }

    return null;
};

export default memo(CustomContent);
