import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { TypeOptionRecordCandidate } from 'models';
import * as Component from './styled';

type Error = {
    email?: string;
    uploadFile?: string;
    company?: string;
    courses?: string;
};

interface Props {
    open: boolean;
    status: 'loading' | 'success' | 'error' | '';
    message?: string;
    typeRecord: TypeOptionRecordCandidate;
    fields: any;
    error: Error;
    onChange: (event: any) => void;
    onConfirm: (event: React.ChangeEvent<HTMLFormElement>) => void;
    onTypeRecord: (value: TypeOptionRecordCandidate) => void;
    onGoback: () => void;
    onClose: () => void;
}

const JobCandidatesDetails: React.FC<Props> = ({
    open,
    status,
    message,
    typeRecord,
    fields,
    error,
    onChange,
    onConfirm,
    onTypeRecord,
    onGoback,
    onClose,
}) => {
    const { t: translate } = useTranslation();

    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                            <Component.ModalActions>
                                <Component.Button color="primary" onClick={onGoback}>
                                    {translate('modal.button.goback')}
                                </Component.Button>
                            </Component.ModalActions>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        <Component.ModalTitle>
                            {fields.id !== ''
                                ? translate('modal.jobCandidatesDetails.title.update')
                                : translate('modal.jobCandidatesDetails.title.add')}
                        </Component.ModalTitle>
                        <Component.ModalBody>
                            <Component.Form onSubmit={onConfirm}>
                                <Component.Grid container spacing={3}>
                                    <Component.Visible condition={!fields.id}>
                                        <Component.Grid item xs={12}>
                                            <Component.GroupOptionRecord>
                                                <Component.OptionRecord
                                                    color="secondary"
                                                    disabled={typeRecord === 'email'}
                                                    size="small"
                                                    onClick={() => onTypeRecord('email')}
                                                >
                                                    <Component.MailOutlineIcon />
                                                    Único Usuário
                                                </Component.OptionRecord>
                                                <Component.OptionRecord
                                                    color="secondary"
                                                    disabled={typeRecord === 'spreadsheet'}
                                                    size="small"
                                                    onClick={() => onTypeRecord('spreadsheet')}
                                                >
                                                    <Component.ListAltIcon />
                                                    Mutiplos Usuário
                                                </Component.OptionRecord>
                                            </Component.GroupOptionRecord>
                                        </Component.Grid>
                                        <Component.Grid item xs={12}>
                                            <Component.Visible condition={typeRecord === 'email'}>
                                                <Component.InputText
                                                    type="email"
                                                    color="primary"
                                                    label={translate('modal.jobCandidatesDetails.form.email')}
                                                    name="email"
                                                    value={fields.email}
                                                    onChange={onChange}
                                                    error={Boolean(error.email)}
                                                    helperText={error.email}
                                                />
                                            </Component.Visible>
                                            <Component.Visible condition={typeRecord === 'spreadsheet'}>
                                                <Component.WrapUpload error={Boolean(error.uploadFile)}>
                                                    <Component.ButtonUpload
                                                        accept=".xlsx,.xls"
                                                        color="primary"
                                                        label="Upload da planilha com os e-mails"
                                                        name="uploadFile"
                                                        onChange={onChange}
                                                        error={Boolean(error.uploadFile)}
                                                        helperText={error.uploadFile}
                                                    />
                                                </Component.WrapUpload>
                                                {fields.uploadFile && (
                                                    <Component.LabelUpload>
                                                        {fields.uploadFile.name}
                                                    </Component.LabelUpload>
                                                )}
                                            </Component.Visible>
                                        </Component.Grid>
                                    </Component.Visible>
                                    <Component.Visible condition={!!fields.name}>
                                        <Component.Grid item xs={12}>
                                            <Component.InputText
                                                color="primary"
                                                label={translate('modal.jobCandidatesDetails.form.name')}
                                                value={fields.name}
                                                disabled
                                            />
                                        </Component.Grid>
                                    </Component.Visible>
                                    <Component.Visible condition={!!fields.document}>
                                        <Component.Grid item xs={12}>
                                            <Component.InputText
                                                color="primary"
                                                label={translate('modal.jobCandidatesDetails.form.document')}
                                                value={fields.document}
                                                mask="cpf"
                                                disabled
                                            />
                                        </Component.Grid>
                                    </Component.Visible>
                                    <Component.Visible condition={!!fields.id}>
                                        <Component.Grid item xs={12}>
                                            <Component.InputText
                                                type="email"
                                                color="primary"
                                                label={translate('modal.jobCandidatesDetails.form.email')}
                                                name="email"
                                                value={fields.email}
                                                onChange={onChange}
                                                error={Boolean(error.email)}
                                                helperText={error.email}
                                            />
                                        </Component.Grid>
                                    </Component.Visible>
                                    <Component.Grid item xs={12}>
                                        <Component.InputSelect
                                            color="primary"
                                            name="company"
                                            label={translate('modal.jobCandidatesDetails.form.companies')}
                                            options={fields.optionsCompanies}
                                            value={fields.company}
                                            onChange={onChange}
                                            error={Boolean(error.company)}
                                            helperText={error.company}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12}>
                                        <Component.InputSelectMultiple
                                            color="primary"
                                            name="courses"
                                            label={translate('modal.jobCandidatesDetails.form.courses')}
                                            options={fields.optionsCourses}
                                            value={fields.courses}
                                            onChange={onChange}
                                            error={Boolean(error.courses)}
                                            helperText={error.courses}
                                        />
                                    </Component.Grid>
                                </Component.Grid>
                                <Component.ModalActions>
                                    <Component.Button onClick={onClose} color="primary">
                                        {translate('modal.button.cancel')}
                                    </Component.Button>
                                    <Component.Button type="submit">
                                        {fields.id !== ''
                                            ? translate('modal.button.update')
                                            : translate('modal.button.add')}
                                    </Component.Button>
                                </Component.ModalActions>
                            </Component.Form>
                        </Component.ModalBody>
                    </>
                );
        }
    };

    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title" scroll="body">
            {modalContent()}
        </Component.Modal>
    );
};

export default memo(JobCandidatesDetails);
