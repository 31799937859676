import { MouseEventHandler } from 'react';

import { TypeStatusMarker } from 'pages/modules/ead/course/models';

import * as Component from './styled';

interface Props {
    type: string | number;
    status: string | null;
    hasClick?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
}

const Marker = ({ type, status, hasClick, onClick }: Props) => {
    if (type === '12') {
        return (
            <Component.Marker $hasClick={hasClick} $status={status} onClick={onClick}>
                <svg className="icon__info" viewBox="0 0 192 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 424.229h20V279.771H20c-11.046 0-20-8.954-20-20V212c0-11.046 8.954-20 20-20h112c11.046 0 20 8.954 20 20v212.229h20c11.046 0 20 8.954 20 20V492c0 11.046-8.954 20-20 20H20c-11.046 0-20-8.954-20-20v-47.771c0-11.046 8.954-20 20-20zM96 0C56.235 0 24 32.235 24 72s32.235 72 72 72 72-32.235 72-72S135.764 0 96 0z" />
                </svg>
            </Component.Marker>
        );
    }
    if (type === '13') {
        return (
            <Component.Marker $hasClick={hasClick} $status={status} onClick={onClick}>
                <svg className="icon__check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
                </svg>
            </Component.Marker>
        );
    }
    if (type === '11') {
        return (
            <Component.Marker $hasClick={hasClick} $status={status} onClick={onClick}>
                <svg className="icon__question" viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M204.3 32.01H96c-52.94 0-96 43.06-96 96c0 17.67 14.31 31.1 32 31.1s32-14.32 32-31.1c0-17.64 14.34-32 32-32h108.3C232.8 96.01 256 119.2 256 147.8c0 19.72-10.97 37.47-30.5 47.33L127.8 252.4C117.1 258.2 112 268.7 112 280v40c0 17.67 14.31 31.99 32 31.99s32-14.32 32-31.99V298.3L256 251.3c39.47-19.75 64-59.42 64-103.5C320 83.95 268.1 32.01 204.3 32.01zM144 400c-22.09 0-40 17.91-40 40s17.91 39.1 40 39.1s40-17.9 40-39.1S166.1 400 144 400z" />
                </svg>
            </Component.Marker>
        );
    }
    if (type === '1') {
        return (
            <Component.Marker $hasClick={hasClick} $status={status} onClick={onClick}>
                <svg className="icon__comment" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 7.1 5.8 12 12 12 2.4 0 4.9-.7 7.1-2.4L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64zm16 352c0 8.8-7.2 16-16 16H288l-12.8 9.6L208 428v-60H64c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h384c8.8 0 16 7.2 16 16v288z" />
                </svg>
            </Component.Marker>
        );
    }

    return <></>;
};

export default Marker;
