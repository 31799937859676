import styled from 'styled-components';
import FilterListIcon from '@material-ui/icons/FilterList';

import { Button as CustomButton } from 'components/button';

export const ButtonFilter = styled(CustomButton)`
    && {
        padding: 12px 30px;
        background-color: ${({ theme }: any) => theme.palette.button.default.light[0]};
        background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.default.light})`};
        margin-left: 15px;
        border: 0;

        @media (max-width: 768px) {
            width: 100%;
            margin-left: 0;
            margin-bottom: 10px;
        }

        &:hover {
            background-color: ${({ theme }: any) => theme.palette.button.default.dark[0]};
            background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.default.dark})`};
        }

        .MuiButton-label {
            color: ${({ theme }: any) => theme.palette.button.primary.main};
        }

        &.MuiButton-containedPrimary {
            background-color: ${({ theme }: any) => theme.palette.button.primary.light[0]};
            background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.primary.light})`};

            .MuiButton-label {
                color: ${({ theme }: any) => theme.palette.button.primary.main};
            }

            &:hover {
                background-color: ${({ theme }: any) => theme.palette.button.primary.dark[0]};
                background-image: ${({ theme }: any) =>
                    `linear-gradient(180deg, ${theme.palette.button.primary.dark})`};
            }
        }

        &.MuiButton-containedSecondary {
            background-color: ${({ theme }: any) => theme.palette.button.secondary.dark[0]};
            background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.secondary.dark})`};

            .MuiButton-label {
                color: ${({ theme }: any) => theme.palette.button.secondary.main};
            }
        }

        &:disabled {
            opacity: 1;
        }
    }
`;

export const IconFilter = styled(FilterListIcon)`
    font-size: 28px;
    margin-right: 10px;
`;
