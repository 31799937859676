import { ReactNode, ReactElement } from 'react';

import Notification from 'components/notification';

import { useNotificationProvider, NotificationContext } from './useNotification';

interface Props {
    children?: ReactElement | ReactNode;
}

const Notifications = ({ children }: Props) => {
    const { value } = useNotificationProvider();

    return (
        <NotificationContext.Provider value={value}>
            {children}
            <Notification
                open={value.notification.open}
                message={value.notification.message}
                status={value.notification.status}
                handleClose={value.closeNotification}
            />
        </NotificationContext.Provider>
    );
};

export default Notifications;
