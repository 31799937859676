import { useState, useCallback, useEffect, ChangeEvent, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import api from 'service/api';
import { generateNumber, formatTime } from 'utils';

import { useNotification } from 'hooks/notification';
import { useAuth } from 'hooks/auth';

import { PropsFieldsVideoDynamic, PropsFieldsErrorVideoDynamic } from 'pages/modules/ead/course/models';
import { useCourse } from 'pages/modules/ead/course/hooks/course';
import { useVideoDynamic } from './hooks/videoDynamic';

type TypeFiles = File | null;

export default function useQuizDetails() {
    const { t: translate } = useTranslation();
    const { openNotification } = useNotification();
    const { signOut } = useAuth();
    const {
        statusPaused,
        currentTime,
        showQuestions,
        showConfirm,
        msgConfirm,
        data,
        removeItem,
        onStatusPaused,
        onCurrentTime,
        onShowQuestions,
        onShowConfirm,
        onRemoveItem,
        setData,
    } = useVideoDynamic();

    const { course_id } = useParams();

    const { videoDynamic, handleCloseVideoDynamic } = useCourse();

    const initialError = useMemo(
        () => ({
            question: '',
            alternatives: [],
            correct_answer: '',
        }),
        [],
    );

    const [file, setFile] = useState<TypeFiles>(null);
    const [urlVideo, setUrlVideo] = useState<string>('');
    const [errors, setErrors] = useState<PropsFieldsErrorVideoDynamic>(initialError);
    const [loadingModal, setLoadingModal] = useState(false);
    const [loadingForm, setLoadingForm] = useState(false);
    const [loadingForm2, setLoadingForm2] = useState(false);
    const [loadingConfirm, setLoadingConfirm] = useState(false);

    const show = useCallback(
        async (loading: boolean = false) => {
            if (videoDynamic?.lessonId === null) {
                return;
            }

            setLoadingModal(loading);

            try {
                const {
                    data: { data, lesson },
                } = await api.get(`/ead/course/question/alternative?lesson_id=${videoDynamic?.lessonId}`);

                setUrlVideo(lesson?.url ?? '');

                setData(
                    data?.map((item: any) => ({
                        question_id: item.question_id,
                        fake_id: generateNumber(10000, 100000),
                        start_time: Number(item.start_time),
                        time_Formated: formatTime(Number(item.start_time), 'auto'),
                        color: item.color,
                        question: item.question,
                        type: String(item.field_id),
                        alternatives: item?.alternatives?.map((alternative: any) => ({
                            id: alternative.alternative_id,
                            alternative_id: alternative.alternative_id,
                            fake_id: generateNumber(10000, 100000),
                            alternative_description: alternative.alternative_description,
                            alternative_is_correct: alternative.alternative_is_correct === 1,
                        })),
                        response: item?.response ?? '0',
                    })) ?? [],
                );
            } catch (error: any) {
                const { data, message } = error;

                if (message === 'Unauthenticated.') {
                    signOut();
                    return;
                }

                openNotification(translate(`validation.${data?.error ?? message}`), 'error');
            } finally {
                setLoadingModal(false);
            }
        },
        [videoDynamic?.lessonId, setData, signOut, openNotification, translate],
    );

    const handleGoback = useCallback(() => {}, []);

    const handleShowQuestion = useCallback(
        (time: number) => {
            onCurrentTime(time);
            onShowQuestions(true);

            const current = {
                fake_id: generateNumber(10000, 100000),
                start_time: time,
                end_time: null,
                time_Formated: formatTime(time, 'auto'),
                type: '12',
                question: '',
                alternatives: [],
                color: '#FFFFFF',
                response: '',
            } as PropsFieldsVideoDynamic;

            setData((oldData) => [...oldData, current]);
        },
        [onCurrentTime, onShowQuestions, setData],
    );

    const handleHideQuestion = useCallback(() => {
        onShowQuestions(false);
    }, [onShowQuestions]);

    const checkIfIcanAdd = (time: number) => {
        return data.filter(
            (item) => item.start_time === time || (item.start_time < time + 60 && item.start_time > time - 60),
        ).length;
    };

    const handleChangeFile = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
        const file = files![0];

        if (!file) return;

        const url = URL.createObjectURL(file);

        setUrlVideo(url);
        setFile(file);
        onStatusPaused(null);
    };

    const handleChange = useCallback(
        ({ target }: ChangeEvent<HTMLInputElement>, field?: string, fake_id?: number) => {
            const { name, value } = target;

            setData((oldData) => [
                ...oldData.map((item) => {
                    if (item.start_time === currentTime) {
                        item = {
                            ...item,
                            [name]: value,
                        };

                        if (field === 'is_check') {
                            item.response = value;
                        }
                        if (field === 'description') {
                            item.alternatives!.map((alternative) => {
                                if (alternative.fake_id === fake_id) {
                                    alternative.alternative_description = value;
                                }
                                return alternative;
                            });
                        }
                        if (field === 'is_correct') {
                            item.alternatives!.map((alternative) => {
                                alternative.alternative_is_correct = value === String(alternative.fake_id);
                                return alternative;
                            });
                        }
                    }
                    return item;
                }),
            ]);

            if (field === 'description') {
                setErrors((oldErrors) => ({
                    ...oldErrors,
                    alternatives: [...oldErrors.alternatives!.filter((error) => error.fake_id !== fake_id)],
                }));
            }

            if (field === 'is_correct') {
                setErrors((oldErrors) => ({
                    ...oldErrors,
                    correct_answer: '',
                }));
            }

            setErrors((oldErrors) => ({
                ...oldErrors,
                [name]: '',
            }));
        },
        [setData, currentTime],
    );

    const getItemData = useCallback(() => {
        return data.find((item) => item.start_time === currentTime);
    }, [data, currentTime]);

    const handleAddAlternative = useCallback(() => {
        const newAlternatives = {
            fake_id: generateNumber(10000, 100000),
            alternative_description: '',
            alternative_is_correct: false,
        };

        setData((oldData) => [
            ...oldData.map((item) => {
                if (item.start_time === currentTime) {
                    item.alternatives?.push(newAlternatives);
                }
                return item;
            }),
        ]);
    }, [setData, currentTime]);

    const handleRemoveAlternative = useCallback(
        (is_correct: boolean, question_id: number, text: string, fake_id: number) => {
            if (is_correct) return;

            const current = data.find((item) => item.question_id === question_id);
            const alternative = current?.alternatives?.find((item) => item.fake_id === fake_id);

            if (!alternative?.alternative_id) {
                show(false);

                return;
            }

            onRemoveItem({ time: null, question_id, alternative_id: alternative!.alternative_id! });

            onShowConfirm(true, translate('modal.videoDynamicDetailsEad.removeAlternative', { text }));
        },
        [data, show, onRemoveItem, onShowConfirm, translate],
    );

    const formDataLesson = useCallback(() => {
        const formData = new FormData();
        formData.append('course_id', course_id!);
        formData.append('course_module_id', String(videoDynamic.moduleId));
        formData.append('lesson_id', String(videoDynamic.lessonId));
        formData.append('is_file', '4');

        if (file) {
            formData.append('lesson_file_name', file.name);
            formData.append('lesson_file', file);
        }

        return formData;
    }, [course_id, videoDynamic, file]);

    const handleSubmitVideo = useCallback(
        async (event: ChangeEvent<HTMLFormElement>) => {
            event.preventDefault();

            setLoadingForm(true);

            try {
                const {
                    data: { success, message },
                } = await api.post(`/ead/course/module/lesson`, formDataLesson());

                if (success) {
                    openNotification(translate(`validation.${message}`), 'success');
                    setFile(null);
                }
            } catch (error: any) {
                const { data, message } = error;

                openNotification(translate(`validation.${data?.error ?? message}`), 'error');
            } finally {
                setLoadingForm(false);
            }
        },
        [formDataLesson, openNotification, translate],
    );

    const onValidate = useCallback(() => {
        let totalError = 0;
        let msg = '';
        const customError: PropsFieldsErrorVideoDynamic = {
            question: '',
            alternatives: [],
            correct_answer: '',
        };

        for (const field of data) {
            if (field.question === '') {
                msg = translate('validation.requireInfoOrQuestion');
                customError.question = 'error';
                totalError++;
            }
            if (field.type === '11') {
                if (field.alternatives!.length < 2) {
                    msg = translate('validation.requireTwoResponse');
                    totalError++;
                }
                if (!!field.alternatives!.length && !field.alternatives?.find((item) => item.alternative_is_correct)) {
                    msg = translate('validation.selectResponseCorrect');
                    customError.correct_answer = 'error';
                    totalError++;
                }
                if (field.alternatives) {
                    for (const alternative of field?.alternatives) {
                        if (alternative.alternative_description === '') {
                            msg = translate('validation.requiredResponse');
                            customError.alternatives!.push({ fake_id: alternative.fake_id! });
                            totalError++;
                        }
                    }
                }
            }
            if (field.type === '13' && !field?.response) {
                msg = translate('validation.selectResponseCorrect');
                totalError++;
            }
        }

        if (totalError > 1) {
            msg = translate('validation.requireAllFields');
        }

        if (totalError > 0) {
            openNotification(msg, 'error');
            setErrors(customError);
        }

        return !!totalError;
    }, [data, openNotification, translate]);

    const saveQuestion = useCallback(async () => {
        let updating: boolean = false;
        try {
            const formData = new FormData();
            formData.append('is_file', '4');
            formData.append('lesson_id', String(videoDynamic.lessonId));

            // eslint-disable-next-line guard-for-in
            for (const key in data) {
                formData.append(`start_time[${key}]`, String(data[key].start_time));
                formData.append(`color[${key}]`, data[key].color);
                formData.append(`description[${key}]`, data[key].question);
                formData.append(`field_id[${key}]`, String(data[key].type!));

                if (data[key]?.question_id) {
                    formData.append(`question_id[${key}]`, String(data[key].question_id));
                    updating = true;
                }
                if (data[key].type === '13' && !!data[key]?.response) {
                    formData.append(`response[${key}]`, data[key]?.response);
                }

                // eslint-disable-next-line guard-for-in
                for (const keyAlternative in data[key].alternatives) {
                    formData.append(
                        `question[${key}][alternative][${keyAlternative}][alternative_description]`,
                        String(data[key]!.alternatives![Number(keyAlternative)]!.alternative_description!),
                    );
                    formData.append(
                        `question[${key}][alternative][${keyAlternative}][alternative_is_correct]`,
                        data[key].alternatives![Number(keyAlternative)]!.alternative_is_correct ? '1' : '0',
                    );
                    if (data[key].alternatives![Number(keyAlternative)]?.alternative_id) {
                        formData.append(
                            `question[${key}][alternative][${keyAlternative}][alternative_id]`,
                            String(data[key]!.alternatives![Number(keyAlternative)]!.alternative_id),
                        );
                        updating = true;
                    }
                }
            }

            setLoadingForm2(true);

            const {
                data: { success, message },
            } = await api.post(`/ead/course/question`, formData);

            if (success) {
                show();
                openNotification(translate(`validation.${message}`), 'success');
            }
        } catch (error: any) {
            const { data: dataError } = error;

            openNotification(translate(`validation.${dataError.error}`), 'error');
        } finally {
            setLoadingForm2(false);
            onShowQuestions(updating);
        }
    }, [videoDynamic, data, openNotification, onShowQuestions, show, translate]);

    const handleSubmitQuestion = useCallback(
        async (event: ChangeEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (file) {
                try {
                    api.post(`/ead/course/module/lesson`, formDataLesson());
                } catch (error: any) {
                    const { data, message } = error;
                    openNotification(translate(`validation.${data?.error ?? message}`), 'error');
                } finally {
                    setFile(null);
                }
            }

            if (onValidate()) {
                return;
            }

            saveQuestion();
        },
        [file, formDataLesson, openNotification, translate, onValidate, saveQuestion],
    );

    const handleCloseConfirm = useCallback(() => {
        onShowConfirm(false);
    }, [onShowConfirm]);

    const removeAlternative = useCallback(
        async (alternative_id: number) => {
            try {
                setLoadingConfirm(true);
                const {
                    data: { success, message },
                } = await api.delete(`/ead/course/question/alternative?alternative_id=${alternative_id}`);
                if (success) {
                    show(false);
                    onShowConfirm(false);
                    openNotification(translate(`validation.${message}`), 'success');
                }
            } catch (error: any) {
                const { data } = error;

                openNotification(translate(`validation.${data.error}`), 'error');
            } finally {
                setLoadingConfirm(false);
            }
        },
        [show, onShowConfirm, openNotification, translate],
    );

    const removeQuestion = useCallback(
        async (question_id: number) => {
            try {
                setLoadingConfirm(true);
                const {
                    data: { success, message },
                } = await api.delete(`/ead/course/question?question_id=${question_id}`);
                if (success) {
                    setData((oldData) => [...oldData.filter((item) => item.question_id !== question_id)]);
                    onShowConfirm(false);
                    openNotification(translate(`validation.${message}`), 'success');
                }
            } catch (error: any) {
                const { data } = error;

                openNotification(translate(`validation.${data.error}`), 'error');
            } finally {
                setLoadingConfirm(false);
            }
        },
        [setData, onShowConfirm, openNotification, translate],
    );

    const handleConfirm = useCallback(() => {
        if (removeItem?.question_id && removeItem.alternative_id) {
            removeAlternative(removeItem!.alternative_id!);
            return;
        }

        removeQuestion(Number(removeItem!.question_id!));
    }, [removeItem, removeAlternative, removeQuestion]);

    useEffect(() => {
        show(true);

        return () => {
            onStatusPaused(null);
            onShowQuestions(false);
            onShowConfirm(false);
        };
    }, [show, onShowQuestions, onStatusPaused, onShowConfirm]);

    return {
        loadingModal,
        loadingForm,
        loadingForm2,
        loadingConfirm,
        file,
        urlVideo,
        data,
        statusPaused,
        currentTime,
        showQuestions,
        showConfirm,
        msgConfirm,
        errors,
        translate,
        checkIfIcanAdd,
        handleGoback,
        handleCloseVideoDynamic,
        handleChangeFile,
        handleShowQuestion,
        handleHideQuestion,
        handleAddAlternative,
        handleRemoveAlternative,
        handleChange,
        getItemData,
        handleSubmitQuestion,
        handleSubmitVideo,
        handleCloseConfirm,
        handleConfirm,
    };
}
