import React, { memo, ChangeEvent } from 'react';

import { TypeStatus } from 'models';
import useFilterCategories from './useFilterCategories';

import * as Component from './styled';

interface PropsFields {
    search: string;
}

interface PropsFilter {
    message?: string;
    open: boolean;
    status: TypeStatus;
    fields: PropsFields;
    error: PropsFields;
    onClose: () => void;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onFilter: (event: ChangeEvent<HTMLFormElement>) => void;
    onClearFilter: () => void;
}

const FilterCategories: React.FC<PropsFilter> = ({
    message,
    open,
    fields,
    error,
    status,
    onClose,
    onChange,
    onFilter,
    onClearFilter,
}) => {
    const { translate } = useFilterCategories();

    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        <Component.ModalTitle>{translate('modal.filter.title')}</Component.ModalTitle>
                        <Component.ModalBody>
                            <Component.Form onSubmit={onFilter}>
                                <Component.FormGroup>
                                    <Component.InputTextSearch
                                        color="primary"
                                        label={translate('modal.filter.searchByCategoryName')}
                                        name="search"
                                        value={fields.search}
                                        onChange={onChange}
                                        error={!!error.search}
                                    />
                                </Component.FormGroup>
                                <Component.FormGroup>
                                    <Component.ModalActions>
                                        <Component.Button type="button" onClick={onClearFilter} color="primary">
                                            {translate('modal.button.removeFilter')}
                                        </Component.Button>
                                        <Component.Button type="submit">
                                            {translate('modal.button.send')}
                                        </Component.Button>
                                    </Component.ModalActions>
                                </Component.FormGroup>
                            </Component.Form>
                        </Component.ModalBody>
                    </>
                );
        }
    };

    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title">
            {modalContent()}
        </Component.Modal>
    );
};

export default memo(FilterCategories);
