import styled from 'styled-components';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CustomGrid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import CustomRadio from '@material-ui/core/Radio';

import Visible from 'components/visible';
import Form, { FormGroup as CustomFormGroup, InputRadio as ButtonRadio, InputText, InputSelect } from 'components/form';
import { Button as CustomButton, ButtonUpload } from 'components/button';
import Video from 'components/videoDynamicAdmin';

export { Visible, Form, ButtonRadio, InputText, InputSelect, ButtonUpload, Video, IconButton };

export const Modal = styled(Dialog)<{ size?: 'small' }>`
    && {
        .MuiPaper-root {
            min-width: ${(props) => (props.size === 'small' ? '350px' : '700px')};
            max-width: ${(props) => (props.size === 'small' ? '400px' : '900px')};
            width: 100%;
            min-height: 50px;
            background-color: #fff;
            background-image: linear-gradient(180deg, #fff, #f1f1f1, #eee);
            border-radius: 10px;
            transition: all 0.3s ease;

            @media (max-width: 768px) {
                min-width: auto;
            }
        }
    }
`;

export const ModalTitle = styled.h2<{ center?: string }>`
    color: #000;
    text-align: ${(props) => (props.center === 'true' ? 'center' : 'left')};

    .MuiTypography-root {
        font-weight: 600;
    }
`;

export const ModalText = styled(DialogContentText)<{ center?: string }>`
    color: #000;
    text-align: ${(props) => (props.center === 'true' ? 'center' : 'left')};
`;

export const ModalBody = styled(DialogContent)`
    && {
        min-height: 50px;
        position: relative;
        padding: 0;

        form {
            transition: all 0.3s ease-in-out;
            position: relative;

            &.loading-form {
                transform: scale(0.9);
                opacity: 0.8;

                &:before {
                    width: 100%;
                    height: 100%;
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 100;
                }
            }
        }
    }
`;

export const FormContent = styled.div`
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;

    h2 {
        margin: 30px 0 0;
    }
`;

export const WrapFormVideo = styled.div<{ showQuestion: boolean }>`
    display: ${(props) => (props.showQuestion ? 'none' : 'block')};
`;

export const ShowQuestion = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ContainerQuestion = styled.div`
    width: 100%;
    background-color: rgba(20, 20, 22, 0.5);
    border-radius: 8px;
    position: relative;
    z-index: 15;

    form {
        padding: 15px 20px;

        .MuiFormControl-root {
            .MuiInputBase-root {
                background-color: rgba(255, 255, 255, 0.95);

                input {
                    &::placeholder {
                        color: ${(props) => props.theme.palette.grey[900]};
                    }
                    color: ${(props) => props.theme.palette.grey[900]};
                }

                &:hover {
                    input {
                        color: ${(props) => props.theme.palette.grey[900]};
                    }
                }

                fieldset {
                    border-color: #fff;
                }

                &.Mui-error {
                    fieldset {
                        background-color: rgba(183, 28, 28, 0.2);
                        border-color: ${(props: any) => props.theme.palette.error.main};
                    }
                }
            }

            & > div:not(.Mui-focused):not(.Mui-error) {
                &:hover {
                    input {
                        color: ${(props) => props.theme.palette.grey[800]};
                    }
                }
            }
        }
    }
`;

export const HeaderQuestion = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 0 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: ${(props) => props.theme.palette.main};
`;

export const List = styled.div`
    && {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 5px;

        label {
            button {
                height: 35px;
                border-radius: 2px;
                font-size: 12px;
            }
        }
    }
`;

export const ListBackground = styled.div`
    && {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;

        label {
            margin-bottom: 0;
        }
    }
`;

export const ListRadios = styled.div`
    && {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 30px;

        label {
            margin: 0;
        }
    }
`;

export const AlternativeResponse = styled.div<{ error: boolean }>`
    transition: all 0.3s ease-in-out;
    background-color: ${(props) => (props.error ? '#d25252' : 'transparent')};
    padding: ${(props) => (props.error ? 15 : 0)}px;
`;

export const GroupResponse = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const InputColor = styled.input`
    width: 40px;
    height: 40px;
    border-radius: 6px;
`;

export const ModalStatus = styled.div`
    height: 100%;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    margin: auto;
`;

export const ModalGroupMessage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    & > div {
        button {
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
`;

export const ModalTextSuccess = styled.h3`
    font-size: 16px;
    font-weight: 400;
    text-align: center;
`;

export const Grid = styled(CustomGrid)``;

export const Loading = styled(CircularProgress).attrs({
    size: 45,
})`
    color: #2b6838;
`;

export const IconSuccess = styled(CheckCircleOutlineIcon)`
    font-size: 60px;
    color: #2b6838;
    margin-bottom: 10px;
`;

export const IconError = styled(ErrorOutlineIcon)`
    font-size: 60px;
    color: #bd3336;
    margin-bottom: 10px;
`;

export const IconAdd = styled(PlusOneIcon)`
    margin-right: 5px;
`;

export const ModalActions = styled(DialogActions)<{ center?: string }>`
    margin-bottom: 20px;

    .MuiButton-containedPrimary {
        background-color: ${({ theme }: any) => theme.palette.button.tertiary.light[0]};
        background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.tertiary.light})`};

        .MuiButton-label {
            color: ${({ theme }: any) => theme.palette.button.tertiary.main};
        }

        &:not(:disabled):hover {
            background-color: ${({ theme }: any) => theme.palette.button.tertiary.dark[0]};
            background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.tertiary.dark})`};
        }
    }
`;

export const FormGroup = styled(CustomFormGroup)`
    margin-bottom: 0;
`;

export const InputRadio = styled(CustomRadio)`
    && {
        color: ${(props) => props.theme.palette.main};
        background-color: rgba(255, 255, 255, 0.99);
        border-radius: 10px;

        svg {
            width: 22px;
            height: 22px;
        }

        &:hover {
            background-color: rgba(230, 230, 230, 0.79);
        }

        &.Mui-checked {
            color: ${(props) => props.theme.palette.main};
            cursor: default;

            &:hover {
                background-color: rgba(255, 255, 255, 0.99);
            }
        }
    }
`;

export const Button = styled(CustomButton)``;

export const IconRemove = styled(DeleteIcon)`
    && {
        color: #fff;
        font-size: 22px;
    }
`;

export const LabelNumber = styled.span`
    width: 35px;
    height: 20px;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    button {
        height: 35px;
        padding: 6px 15px;

        @media (max-width: 768px) {
            margin: 0 0 15px;
        }

        &:last-child {
            margin-right: 0;
        }

        &.MuiButton-containedPrimary {
            background-color: ${({ theme }: any) => theme.palette.button.tertiary.light[0]};
            background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.tertiary.light})`};

            .MuiButton-label {
                color: ${({ theme }: any) => theme.palette.button.tertiary.main};
            }

            &:not(:disabled):hover {
                background-color: ${({ theme }: any) => theme.palette.button.tertiary.dark[0]};
                background-image: ${({ theme }: any) =>
                    `linear-gradient(180deg, ${theme.palette.button.tertiary.dark})`};
            }
        }
    }
`;

export const Wrap = styled.div`
    && {
        padding: 10px 20px 0;
        background-color: #eee;
        border-radius: 5px;
        margin-bottom: 30px;
        border: 1px solid #666;

        &:nth-child(odd) {
            background-color: #ededed;
        }
    }
`;

export const Label = styled.label`
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 10px;
    display: block;
`;

export const Group = styled.div<{ isCorrect: string }>`
    && {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background-color: ${(props) => (props.isCorrect === 'true' ? '#daefde' : 'transparent')};
        padding: 5px;
        border-radius: 4px;
        transition: all 0.3s ease-in-out;
        margin-bottom: -5px;
        position: relative;

        .MuiFormControl-root {
            width: calc(100% - 135px);
        }

        button {
            width: 60px;
            min-width: 60px;
            max-width: 60px;
            margin-left: 10px;

            &.MuiButton-containedPrimary {
                background-color: ${({ theme }: any) => theme.palette.button.tertiary.light[0]};
                background-image: ${({ theme }: any) =>
                    `linear-gradient(180deg, ${theme.palette.button.tertiary.light})`};

                .MuiButton-label {
                    color: ${({ theme }: any) => theme.palette.button.tertiary.main};
                }

                &:not(:disabled):hover {
                    background-color: ${({ theme }: any) => theme.palette.button.tertiary.dark[0]};
                    background-image: ${({ theme }: any) =>
                        `linear-gradient(180deg, ${theme.palette.button.tertiary.dark})`};
                }

                &.Mui-disabled {
                    opacity: 0.4;
                }
            }
        }
    }
`;

export const AllAlternatives = styled.div<{ error: string }>`
    background-color: ${(props) => (props.error === 'true' ? '#f1dddd' : 'transparent')};
    padding: ${(props) => (props.error === 'true' ? '10px 10px 1px' : '0')};
    margin-bottom: ${(props) => (props.error === 'true' ? '10px' : '0')};
    transition: all 0.3s ease-in-out;
`;

export const LabelError = styled.span`
    color: #932528;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
`;

export const Confirme = styled.div<{ loading: boolean }>`
    width: 100%;
    max-width: 460px;
    min-height: 180px;
    background-color: rgba(255, 255, 255, 0.94);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    gap: 30px;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: all 0.3s ease-in-out;
    transform: ${(props) => (props.loading ? 'translate(-50%, -50%) scale(0.9)' : 'translate(-50%, -50%)')};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
    z-index: 55;

    &:before {
        width: 100%;
        height: 100%;
        content: '';
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 10px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: ${(props) => (props.loading ? 10 : -5)};
    }
`;

export const ConfirmeMask = styled.div`
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(25, 25, 25, 0.35);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
`;

export const Text = styled.span`
    color: ${(props) => props.theme.palette.primary.dark};
    font-size: 1rem;
    font-weight: 500;
    text-align: center;

    .MuiTypography-root {
        font-weight: 600;
    }
    margin: 0;
`;
