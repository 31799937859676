import { useState, useContext, createContext, useCallback, useMemo } from 'react';

type Activated = {
    nameModule?: string;
    menu: string | undefined;
    submenu: string;
};

interface Context {
    openDrawer: boolean;
    activated: Activated | null;
    onOpenDrawer(value: boolean): void;
    onActivated(nameModule: string, menu: string | undefined, submenu: string): void;
}

export const DrawerContext = createContext<Context>({
    openDrawer: false,
    activated: null,
    onOpenDrawer: (open: boolean) => {},
    onActivated: (nameModule: string, menu: string | undefined, submenu: string) => {},
});

export function useDrawer() {
    const context = useContext(DrawerContext);
    return context;
}

export function useDrawerProvider() {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [activated, setActivated] = useState<Activated | null>(null);

    const onOpenDrawer = useCallback((open: boolean) => {
        setOpenDrawer(open);
    }, []);

    const onActivated = useCallback((nameModule: string, menu: string | undefined, submenu: string) => {
        setActivated({ nameModule, menu, submenu });
    }, []);

    const value = useMemo(
        () => ({
            openDrawer,
            activated,
            onOpenDrawer,
            onActivated,
        }),
        [openDrawer, activated, onOpenDrawer, onActivated],
    );

    return {
        value,
    };
}
