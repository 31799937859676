import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export default function useModerator() {
    const { t: translate } = useTranslation();

    const goToPageRoom = useCallback((link: string) => {
        window.open(link, '_blank');
    }, []);

    return {
        translate,
        goToPageRoom,
    };
}
