import React, { memo } from 'react';

import { PropsSelect } from 'models';

import * as Component from './styled';

interface Props {
    color?: 'primary' | 'secondary';
    label?: string;
    name?: string;
    options: PropsSelect[];
    value: string[];
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
    onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const InputSelectMultiple: React.FC<Props> = ({
    color,
    options,
    label,
    name,
    value,
    error,
    helperText,
    disabled,
    onChange,
}) => (
    <Component.ThemeProvider theme={Component.themeSelect}>
        <Component.FormControlCustom color={color} variant="outlined">
            {label && <Component.InputLabelCustom error={error}>{label}</Component.InputLabelCustom>}
            <Component.InputSelectCustom
                multiple
                disabled={disabled ?? false}
                color={color}
                label={label}
                name={name}
                onChange={onChange}
                value={value}
                error={error}
                variant="outlined"
                renderValue={(selected) => (
                    <Component.WrapChip>
                        {(selected as string[]).map((value) => (
                            <Component.OptionChip
                                key={value}
                                label={options?.find((item) => item.value === value)?.title}
                            />
                        ))}
                    </Component.WrapChip>
                )}
                MenuProps={MenuProps}
            >
                {options?.map((item) => (
                    <Component.OptionSelect key={item.value} value={item.value}>
                        {item.title}
                    </Component.OptionSelect>
                ))}
            </Component.InputSelectCustom>
            {helperText && <Component.FormHelperText error={error}>{helperText}</Component.FormHelperText>}
        </Component.FormControlCustom>
    </Component.ThemeProvider>
);

export default memo(InputSelectMultiple);
