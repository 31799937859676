import { ReactNode } from 'react';
import { useAuthProvider, AuthContext } from './useAuth';

interface Props {
    children?: ReactNode;
}

const Auth = ({ children }: Props) => {
    const { value } = useAuthProvider();

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default Auth;
