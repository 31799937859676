import React, { memo } from 'react';

import { PropsModalDefault } from 'models';

import useIndicateSupplierLgpd from './useIndicateSupplierLgpd';

import * as Component from './styled';

interface Props extends PropsModalDefault {
    email: string;
    error: boolean;
    onClose: () => void;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onConfirm: (event: React.ChangeEvent<HTMLFormElement>) => void;
}

const IndicateSupplierLgpd: React.FC<Props> = ({
    message,
    open,
    email,
    error,
    status,
    onClose,
    onChange,
    onConfirm,
}) => {
    const { translate } = useIndicateSupplierLgpd();

    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        <Component.ModalTitle>{translate('modal.indicateSupplier.titleLgpd')}</Component.ModalTitle>
                        <Component.ModalBody>
                            <Component.Form onSubmit={onConfirm}>
                                <Component.FormGroup>
                                    <Component.InputText
                                        color="primary"
                                        label={translate('modal.indicateSupplier.email')}
                                        name="email"
                                        value={email}
                                        onChange={onChange}
                                        error={error}
                                    />
                                </Component.FormGroup>
                                <Component.FormGroup>
                                    <Component.ModalActions>
                                        <Component.Button onClick={onClose} color="primary">
                                            {translate('modal.button.cancel')}
                                        </Component.Button>
                                        <Component.Button type="submit">
                                            {translate('modal.button.send')}
                                        </Component.Button>
                                    </Component.ModalActions>
                                </Component.FormGroup>
                            </Component.Form>
                        </Component.ModalBody>
                    </>
                );
        }
    };

    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title">
            {modalContent()}
        </Component.Modal>
    );
};

export default memo(IndicateSupplierLgpd);
