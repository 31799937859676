import React, { memo, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import * as Component from './styled';

type Error = {
    title: string;
    message: string;
    company: string;
    courses: string;
};

interface Props {
    open: boolean;
    status: 'loading' | 'success' | 'error' | '';
    message?: string;
    fields: any;
    error: Error;
    levelAccess?: number;
    main?: 0 | 1;
    onChange: (event: ChangeEvent<any>) => void;
    onConfirm: (event: React.ChangeEvent<HTMLFormElement>) => void;
    onGoback: () => void;
    onClose: () => void;
}

const NotificationsEadDetails: React.FC<Props> = ({
    open,
    status,
    message,
    fields,
    error,
    levelAccess,
    main,
    onChange,
    onConfirm,
    onGoback,
    onClose,
}) => {
    const { t: translate } = useTranslation();
    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                            <Component.ModalActions>
                                <Component.Button color="primary" onClick={onGoback}>
                                    {translate('modal.button.goback')}
                                </Component.Button>
                            </Component.ModalActions>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        <Component.ModalTitle>{translate('modal.notificationsDetails.title.add')}</Component.ModalTitle>
                        <Component.ModalBody>
                            <Component.Form onSubmit={onConfirm}>
                                <Component.Grid container spacing={3}>
                                    <Component.Grid item xs={12}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('notifications.form.title')}
                                            name="title"
                                            value={fields.title}
                                            onChange={onChange}
                                            error={!!error.title}
                                        />
                                    </Component.Grid>
                                    <Component.Grid xs={12} md={12} xl={12} item>
                                        <Component.InputText
                                            multiline
                                            minRows={4}
                                            color="primary"
                                            label={translate('notifications.form.message')}
                                            name="message"
                                            value={fields.message}
                                            onChange={onChange}
                                            error={!!error.message}
                                        />
                                    </Component.Grid>
                                    <Component.Visible
                                        condition={levelAccess === 1 || (levelAccess === 2 && main === 1)}
                                    >
                                        <Component.Grid xs={12} md={12} xl={12} item>
                                            <Component.InputSelect
                                                color="primary"
                                                label={translate('notifications.form.selectCompany')}
                                                name="company"
                                                options={fields.optionsCompanies}
                                                value={fields.company}
                                                onChange={onChange}
                                                error={!!error.company}
                                            />
                                        </Component.Grid>
                                    </Component.Visible>
                                    <Component.Grid xs={12} md={12} xl={12} item>
                                        <Component.InputSelectMultiple
                                            color="primary"
                                            label={translate('notifications.form.selectCourses')}
                                            name="courses"
                                            options={fields.optionsCourses}
                                            value={fields.courses}
                                            onChange={onChange}
                                            error={!!error.courses}
                                            disabled={!fields.optionsCourses.length}
                                        />
                                    </Component.Grid>
                                </Component.Grid>
                                <Component.ModalActions>
                                    <Component.Button onClick={onClose} color="primary">
                                        {translate('modal.button.cancel')}
                                    </Component.Button>
                                    <Component.Button type="submit">
                                        {translate(`modal.button.${fields.id ? 'update' : 'add'}`)}
                                    </Component.Button>
                                </Component.ModalActions>
                            </Component.Form>
                        </Component.ModalBody>
                    </>
                );
        }
    };
    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title" scroll="body">
            {modalContent()}
        </Component.Modal>
    );
};
export default memo(NotificationsEadDetails);
