import { useContext, createContext, Dispatch, SetStateAction, useState, useCallback, useMemo } from 'react';

import {
    StatusPausedVideoDynamic,
    PropsFieldsVideoDynamic,
    PropsRemoveQuestionVideoDynamic,
} from 'pages/modules/ead/course/models';

interface Context {
    statusPaused: StatusPausedVideoDynamic | null;
    currentTime: number | null;
    showQuestions: boolean;
    showConfirm: boolean;
    msgConfirm: string;
    data: PropsFieldsVideoDynamic[];
    removeItem: PropsRemoveQuestionVideoDynamic | null;
    onStatusPaused(value: StatusPausedVideoDynamic | null): void;
    onCurrentTime(value: number | null): void;
    onShowQuestions(value: boolean): void;
    onShowConfirm(value: boolean, msg?: string): void;
    onRemoveItem(value: PropsRemoveQuestionVideoDynamic | null): void;
    setData: Dispatch<SetStateAction<PropsFieldsVideoDynamic[]>>;
}

export const VideoDynamicContext = createContext({} as Context);

export function useVideoDynamic() {
    const context = useContext(VideoDynamicContext);
    return context;
}

export function useVideoDynamicProvider() {
    const [statusPaused, setStatusPaused] = useState<StatusPausedVideoDynamic | null>(null);
    const [currentTime, setCurrentTime] = useState<number | null>(null);
    const [showQuestions, setShowQuestions] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [msgConfirm, setMsgConfirm] = useState('');
    const [data, setData] = useState<PropsFieldsVideoDynamic[]>([]);
    const [removeItem, setRemoveItem] = useState<PropsRemoveQuestionVideoDynamic | null>(null);

    const onStatusPaused = useCallback((value: StatusPausedVideoDynamic | null) => {
        setStatusPaused(value);
    }, []);

    const onCurrentTime = useCallback((value: number | null) => {
        setCurrentTime(value);
    }, []);

    const onShowQuestions = useCallback((value: boolean) => {
        setShowQuestions(value);
    }, []);

    const onRemoveItem = useCallback((value: PropsRemoveQuestionVideoDynamic | null) => {
        setRemoveItem(value);
    }, []);

    const onShowConfirm = useCallback((value: boolean, msg?: string) => {
        setMsgConfirm('');
        setShowConfirm(value);
        setMsgConfirm(msg ?? '');
    }, []);

    const value = useMemo(
        () => ({
            statusPaused,
            currentTime,
            showQuestions,
            showConfirm,
            msgConfirm,
            data,
            removeItem,
            onStatusPaused,
            onCurrentTime,
            onShowQuestions,
            onShowConfirm,
            onRemoveItem,
            setData,
        }),
        [
            statusPaused,
            currentTime,
            showQuestions,
            showConfirm,
            msgConfirm,
            data,
            removeItem,
            onStatusPaused,
            onCurrentTime,
            onShowQuestions,
            onShowConfirm,
            onRemoveItem,
            setData,
        ],
    );

    return {
        value,
    };
}
