import { useState, useRef, useMemo, useCallback, SyntheticEvent, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { formatTime, convertTimeToSeconds } from 'utils';

import { useVideoDynamic } from 'components/modal/videoDynamicDetails/hooks/videoDynamic';

import theme from 'assets/styled/themes';

export interface PropsPlayer {
    isPlaying: boolean;
    currentTime: string;
    duration: number;
    durationFormated: string;
    progress: number;
    visualizedTime: number;
    isMuted: boolean;
    volume: number;
}

export default function useVideo() {
    const { t: translate } = useTranslation();
    const { data, setData, onStatusPaused, onCurrentTime, onShowQuestions, onRemoveItem, onShowConfirm } =
        useVideoDynamic();

    const videoRef = useRef<HTMLVideoElement>(null);
    const sliderRef = useRef<HTMLInputElement>(null);
    const sliderVolumeRef = useRef<HTMLInputElement>(null);

    const initialPlayer = useMemo(
        () => ({
            isPlaying: false,
            currentTime: '00:00',
            duration: 0,
            durationFormated: '00:00',
            progress: 0,
            visualizedTime: 0,
            isMuted: false,
            volume: 1,
        }),
        [],
    );

    const [player, setPlayer] = useState<PropsPlayer>(initialPlayer);

    const onLoadedMetadata = useCallback((event: SyntheticEvent<HTMLVideoElement>) => {
        const { duration } = event.currentTarget;
        const timeCurrent = Math.floor(videoRef.current!.currentTime);
        const progressValue = (timeCurrent / duration) * 100;
        const progressVolume = (event.currentTarget.volume / 1) * 100;

        if (convertTimeToSeconds(String(timeCurrent))) {
            videoRef.current!.currentTime = convertTimeToSeconds(String(timeCurrent));
        }
        setPlayer((oldPlayer) => {
            if (videoRef.current) {
                videoRef.current.muted = false;
                videoRef.current.volume = 1;
            }

            return {
                ...oldPlayer,
                ...{
                    duration,
                    progress: 0,
                    durationFormated: formatTime(Math.round(duration), 'auto'),
                    visualizedTime: 0,
                    currentTime: formatTime(timeCurrent, 'auto'),
                    isMuted: false,
                    volume: 1,
                },
            };
        });

        sliderRef.current!.style.background = `linear-gradient(to right, #f7a925 ${progressValue}%, ${theme.palette.grey[100]} ${progressValue}%)`;
        sliderVolumeRef.current!.style.background = `linear-gradient(to right, #f7a925 ${progressVolume}%, ${theme.palette.grey[100]} ${progressVolume}%)`;
    }, []);

    const onTimeUpdate = useCallback(() => {
        const videoElem = videoRef.current;
        const timeCurrent = Math.floor(videoElem!.currentTime);
        const progressValue = (timeCurrent / videoElem!.duration) * 100;

        setPlayer((oldPlayer) => ({
            ...oldPlayer,
            ...{
                progress: progressValue,
                visualizedTime: oldPlayer.visualizedTime > timeCurrent ? oldPlayer.visualizedTime : timeCurrent,
                currentTime: formatTime(videoElem!.currentTime, 'auto'),
            },
        }));

        onStatusPaused({
            time: timeCurrent,
            timeFormated: formatTime(timeCurrent, 'auto'),
        });

        sliderRef.current!.style.background = `linear-gradient(to right, #f7a925 ${progressValue}%, ${theme.palette.grey[100]} ${progressValue}%)`;
    }, [onStatusPaused]);

    const onVolumeChange = useCallback((event: ChangeEvent<HTMLVideoElement>) => {
        setPlayer((oldPlayer) => ({
            ...oldPlayer,
            ...{
                volume: event.target.muted ? 0 : event.target.volume,
                isMuted: event.target.muted || (!event.target.muted && event.target.volume === 0),
            },
        }));
    }, []);

    const onPlay = useCallback(() => {
        setPlayer((oldPlayer) => ({ ...oldPlayer, isPlaying: true }));
    }, []);

    const onPause = useCallback(async () => {
        const videoElem = videoRef.current;
        const timeCurrent = Math.floor(videoElem!.currentTime);

        onStatusPaused({
            time: timeCurrent,
            timeFormated: formatTime(timeCurrent, 'auto'),
        });

        setPlayer((oldPlayer) => ({ ...oldPlayer, isPlaying: false }));
    }, [onStatusPaused]);

    const onMarkerBar = useCallback((pos: number) => {
        if (!videoRef.current) {
            return '0';
        }

        return String((pos / Number(videoRef.current.duration)) * 100);
    }, []);

    const handleTogglePlay = useCallback(() => {
        setPlayer((oldPlayer) => {
            if (oldPlayer.isPlaying) {
                videoRef.current!.pause();

                return {
                    ...oldPlayer,
                    isPlaying: false,
                };
            }

            videoRef.current!.play();

            return {
                ...oldPlayer,
                isPlaying: true,
            };
        });
    }, []);

    const handleChangeProgress = useCallback((event: ChangeEvent<{}>, value: number | number[]) => {
        event.preventDefault();
        const manualChange = Number(value);

        videoRef.current!.currentTime = (videoRef.current!.duration / 100) * manualChange;

        setPlayer((oldPlayer) => ({
            ...oldPlayer,
            progress: manualChange,
        }));
    }, []);

    const handleChangeVolume = useCallback((event: ChangeEvent<{}>, value: number | number[]) => {
        event.preventDefault();
        const volume = parseFloat(Number(value).toString());
        const progressValue = (volume / 1) * 100;

        videoRef.current!.volume = volume;
        videoRef.current!.muted = true;

        if (volume === 0) {
            videoRef.current!.muted = true;
            setPlayer((oldPlayer) => ({
                ...oldPlayer,
                ...{
                    volume,
                    isMuted: volume <= 0,
                },
            }));

            return;
        }

        videoRef.current!.muted = false;

        setPlayer((oldPlayer) => ({
            ...oldPlayer,
            ...{
                volume,
                isMuted: false,
            },
        }));

        sliderVolumeRef.current!.style.background = `linear-gradient(to top, #f7a925 ${progressValue}%, ${theme.palette.grey[100]} ${progressValue}%)`;
    }, []);

    const handleEditContent = useCallback(
        (time: number) => {
            onShowQuestions(true);
            onCurrentTime(time);

            onStatusPaused({
                time,
                timeFormated: formatTime(time, 'auto'),
            });
        },
        [onShowQuestions, onCurrentTime, onStatusPaused],
    );

    const handleOpenModalConfirm = useCallback(
        (time: number) => {
            const current = data.find((item) => item.start_time === time);

            if (!current?.question_id) {
                setData((oldData) => [...oldData.filter((item) => item.start_time !== time)]);

                return;
            }

            onRemoveItem({ time, question_id: current!.question_id });

            onShowConfirm(
                true,
                translate('modal.videoDynamicDetailsEad.removeTime', { time: formatTime(time, 'auto') }),
            );
        },
        [data, setData, onShowConfirm, onRemoveItem, translate],
    );

    return {
        videoRef,
        sliderRef,
        sliderVolumeRef,
        player,
        data,
        translate,
        onLoadedMetadata,
        onTimeUpdate,
        onVolumeChange,
        onPlay,
        onPause,
        onMarkerBar,
        handleTogglePlay,
        handleChangeProgress,
        handleChangeVolume,
        handleEditContent,
        handleOpenModalConfirm,
    };
}
