import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import * as Component from './styled';

type PropsUser = {
    id: number | null;
    id_fake: number;
    name: string;
    email: string;
    isDelete: boolean;
};

type Attachment = {
    ext: string;
    url: string;
    file: any;
    file_name: string;
};

type Error = {
    description?: string;
    hearing_date?: string;
    hearing_time?: string;
    users: PropsUser[];
};

interface Props {
    open: boolean;
    status: 'loading' | 'success' | 'error' | '';
    message?: string;
    fields: any;
    error: Error;
    levelAccess?: number;
    onChange: (event: any, id?: number, name?: string) => void;
    onAddUser: () => void;
    onRemoveUser: (id_fake: number, id: number | null) => void;
    onConfirm: () => void;
    onGoback: () => void;
    onClose: () => void;
}

const SyndicationDetails: React.FC<Props> = ({
    open,
    status,
    message,
    fields,
    error,
    levelAccess,
    onChange,
    onAddUser,
    onRemoveUser,
    onConfirm,
    onGoback,
    onClose,
}) => {
    const { t: translate } = useTranslation();

    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                            <Component.ModalActions>
                                <Component.Button color="primary" onClick={onGoback}>
                                    {translate('modal.button.goback')}
                                </Component.Button>
                            </Component.ModalActions>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        <Component.ModalTitle>
                            {fields.id !== ''
                                ? translate('modal.syndicationDetails.title.update')
                                : translate('modal.syndicationDetails.title.add')}
                        </Component.ModalTitle>
                        <Component.ModalBody>
                            <Component.Form>
                                <Component.Grid container spacing={3}>
                                    <Component.Grid item xs={12}>
                                        <Component.InputText
                                            multiline
                                            color="primary"
                                            label={translate('modal.syndicationDetails.form.description')}
                                            name="description"
                                            value={fields.description}
                                            onChange={onChange}
                                            error={Boolean(error.description)}
                                            helperText={error.description}
                                            disabled={fields.status === 'finished'}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12} md={6}>
                                        <Component.InputDateTime
                                            type="date"
                                            color="primary"
                                            label={translate('modal.syndicationDetails.form.dataSyndication')}
                                            name="hearing_date"
                                            value={fields.hearing_date}
                                            onChange={onChange}
                                            error={Boolean(error.hearing_date)}
                                            helperText={error.hearing_date}
                                            disabled={fields.status === 'finished'}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12} md={6}>
                                        <Component.InputDateTime
                                            type="time"
                                            color="primary"
                                            label={translate('modal.syndicationDetails.form.time')}
                                            name="hearing_time"
                                            value={fields.hearing_time}
                                            onChange={onChange}
                                            error={Boolean(error.hearing_time)}
                                            helperText={error.hearing_time}
                                            disabled={fields.status === 'finished'}
                                        />
                                    </Component.Grid>
                                </Component.Grid>
                                {fields?.users?.map((item: PropsUser) => (
                                    <Component.Visible key={item.id_fake} condition={Boolean(!item.isDelete)}>
                                        <Component.Grid container spacing={3}>
                                            <Component.Grid item xs={12} md={4}>
                                                <Component.InputText
                                                    color="primary"
                                                    label={translate('modal.syndicationDetails.form.name')}
                                                    name={`name_${item.id}`}
                                                    value={item.name}
                                                    onChange={(event) => onChange(event, item.id_fake, 'name')}
                                                    error={Boolean(
                                                        error.users.find((user) => user.id === item.id_fake)?.name,
                                                    )}
                                                    helperText={
                                                        error.users.find((user) => user.id === item.id_fake)?.name
                                                    }
                                                    disabled={fields.status === 'finished'}
                                                />
                                            </Component.Grid>
                                            <Component.Grid item xs={12} md={6}>
                                                <Component.InputText
                                                    type="email"
                                                    color="primary"
                                                    label={translate('modal.syndicationDetails.form.email')}
                                                    name={`email_${item.id_fake}`}
                                                    value={item.email}
                                                    onChange={(event) => onChange(event, item.id_fake, 'email')}
                                                    error={Boolean(
                                                        error.users.find((user) => user.id === item.id_fake)?.email,
                                                    )}
                                                    helperText={
                                                        error.users.find((user) => user.id === item.id_fake)?.email
                                                    }
                                                    disabled={fields.status === 'finished'}
                                                />
                                            </Component.Grid>
                                            <Component.Grid item xs={12} md={2}>
                                                <Component.ButtonRemove
                                                    fullWidth
                                                    onClick={() => onRemoveUser(item.id_fake, item.id)}
                                                    disabled={fields.status === 'finished'}
                                                >
                                                    <Component.IconDelete />
                                                </Component.ButtonRemove>
                                            </Component.Grid>
                                        </Component.Grid>
                                    </Component.Visible>
                                ))}
                                <Component.Visible condition={fields.status !== 'finished'}>
                                    <Component.Grid container spacing={3}>
                                        <Component.Grid item>
                                            <Component.Button color="secondary" onClick={onAddUser}>
                                                <Component.IconAdd />
                                                {translate('modal.syndicationDetails.form.user')}
                                            </Component.Button>
                                        </Component.Grid>
                                    </Component.Grid>
                                </Component.Visible>
                                <Component.Visible condition={fields.status === 'finished'}>
                                    <Component.Grid container spacing={3}>
                                        <Component.Grid item xs={12}>
                                            <Component.Label>
                                                {translate('modal.syndicationDetails.form.finishing')}
                                            </Component.Label>
                                        </Component.Grid>
                                        <Component.Grid item xs={12}>
                                            <Component.InputText
                                                color="primary"
                                                label={translate('modal.syndicationDetails.form.observation')}
                                                name="observation"
                                                value={fields.observation}
                                                onChange={onChange}
                                                disabled
                                            />
                                        </Component.Grid>
                                        <Component.Grid item xs={12}>
                                            <Component.ListAttachments>
                                                {fields.attachments?.map((attachment: Attachment) => (
                                                    <Component.Attachment
                                                        key={attachment.url}
                                                        ext={attachment.ext}
                                                        url={attachment.url}
                                                    />
                                                ))}
                                            </Component.ListAttachments>
                                        </Component.Grid>
                                    </Component.Grid>
                                </Component.Visible>
                                <Component.ModalActions>
                                    <Component.Button onClick={onClose} color="primary">
                                        {translate('modal.button.cancel')}
                                    </Component.Button>
                                    <Component.Visible condition={fields.status !== 'finished'}>
                                        <Component.Button onClick={onConfirm}>
                                            {fields.id !== ''
                                                ? translate('modal.button.update')
                                                : translate('modal.button.add')}
                                        </Component.Button>
                                    </Component.Visible>
                                </Component.ModalActions>
                            </Component.Form>
                        </Component.ModalBody>
                    </>
                );
        }
    };

    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title" scroll="body">
            {modalContent()}
        </Component.Modal>
    );
};

export default memo(SyndicationDetails);
