import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import * as Component from './styled';

interface PropsSelect {
    title: string;
    value: string;
}

interface PropsItems {
    options: PropsSelect[] | [];
    selected: string;
    error?: boolean;
}

interface Props {
    title?: string;
    text?: string;
    message?: string;
    open: boolean;
    groups: PropsItems;
    departaments: PropsItems;
    status: 'loading' | 'success' | 'error' | '';
    onChangeGroup: (event?: any) => void;
    onChangeDepartament: (event?: any) => void;
    onClose: () => void;
    onConfirm: (event: React.ChangeEvent<HTMLFormElement>) => void;
}

const ConfirmClassification: React.FC<Props> = ({
    title,
    text,
    message,
    open,
    groups,
    departaments,
    status,
    onChangeGroup,
    onChangeDepartament,
    onClose,
    onConfirm,
}) => {
    const { t: translate } = useTranslation();

    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        <Component.ModalTitle>{title}</Component.ModalTitle>
                        <Component.ModalBody>
                            <Component.ModalText>{text}</Component.ModalText>
                            <Component.Form onSubmit={onConfirm}>
                                {groups?.options.length > 0 && (
                                    <Component.FormGroup>
                                        <Component.InputSelect
                                            color="primary"
                                            name="groups"
                                            label={translate('modal.selectGroup')}
                                            options={groups.options}
                                            value={groups.selected}
                                            onChange={onChangeGroup}
                                            error={groups.error}
                                        />
                                    </Component.FormGroup>
                                )}
                                {departaments?.options.length > 0 && (
                                    <Component.FormGroup>
                                        <Component.InputSelect
                                            color="primary"
                                            name="departaments"
                                            label={translate('modal.selectDepartament')}
                                            options={departaments.options}
                                            value={departaments.selected}
                                            onChange={onChangeDepartament}
                                            error={departaments.error}
                                        />
                                    </Component.FormGroup>
                                )}
                                <Component.ModalActions>
                                    <Component.Button onClick={onClose} color="primary">
                                        {translate('modal.button.cancel')}
                                    </Component.Button>
                                    <Component.Button type="submit">
                                        {translate('modal.button.confirm')}
                                    </Component.Button>
                                </Component.ModalActions>
                            </Component.Form>
                        </Component.ModalBody>
                    </>
                );
        }
    };

    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title">
            {modalContent()}
        </Component.Modal>
    );
};

export default memo(ConfirmClassification);
