import React, { memo } from 'react';

import Profile from 'assets/images/gravatar.png';

import { shortName } from 'utils';

import useMenuSettings from './useMenuSettings';

import * as Component from './styled';

const MenuSettings: React.FC = () => {
    const { profile, open, anchorEl, translate, handleOpen, handleClose, goToPage, onLogout } = useMenuSettings();
    return (
        <Component.Main>
            <Component.Button onClick={handleOpen} color={open ? 'secondary' : 'primary'}>
                <Component.PhotoProfile src={profile?.profilePicture?.url ?? Profile} />
                <Component.IconSettings />
            </Component.Button>
            <Component.Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Component.InnerMenu>
                    <Component.Visible condition={profile !== null}>
                        <Component.MenuItemText variant="h3">
                            {shortName(profile?.name as string)}
                        </Component.MenuItemText>
                        <Component.MenuItemText variant="subtitle2">{profile?.levelAccessName}</Component.MenuItemText>
                    </Component.Visible>
                    <Component.Divisor />
                    <Component.Visible condition={profile?.firstAccess !== 1}>
                        <Component.MenuItem onClick={() => goToPage('/profile')}>
                            <Component.IconPerson /> {translate('navbar.profile')}
                        </Component.MenuItem>
                    </Component.Visible>
                    <Component.Divisor />
                    <Component.MenuItem onClick={onLogout}>
                        <Component.IconPower /> {translate('navbar.logout')}
                    </Component.MenuItem>
                </Component.InnerMenu>
            </Component.Menu>
        </Component.Main>
    );
};

export default memo(MenuSettings);
