import React, { memo, ReactNode } from 'react';

import * as Component from './styled';

interface Props {
    children: ReactNode;
    loading?: boolean;
    disabled?: boolean;
    onSubmit?(event: React.ChangeEvent<HTMLFormElement>): void;
}

const Form: React.FC<Props> = ({ children, onSubmit, loading, disabled }) => (
    <Component.FormCustom
        className={loading ? 'loading-form' : ''}
        onSubmit={onSubmit}
        autoComplete="off"
        method="post"
        disabled={disabled ?? false}
    >
        {children}
    </Component.FormCustom>
);

export default memo(Form);
