import styled from 'styled-components';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import {
    Drawer,
    Accordion as AccordionCustom,
    AccordionSummary as AccordionSummaryCustom,
    AccordionDetails as AccordionDetailsCustom,
    List as ListCustom,
    ListItem as ListItemCustom,
    ListItemText as ListItemTextCustom,
    Typography,
} from '@material-ui/core';

import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PermDataSettingOutlinedIcon from '@material-ui/icons/PermDataSettingOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import CustomExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StoreIcon from '@material-ui/icons/Store';

import CustomVisible from 'components/visible';

import CustomItemMenuModule from './itemMenuModule';

interface PropsItemList {
    active?: string;
}

const themeWhite = createTheme({
    palette: {
        primary: {
            main: '#fff',
            dark: '#fff',
            light: '#fff',
        },
    },
});

export { ThemeProvider, themeWhite };

export const Visible = styled(CustomVisible)``;

export const ItemMenuModule = styled(CustomItemMenuModule)``;

export const ExpandMoreIcon = styled(CustomExpandMoreIcon)``;

export const IconClint = styled(StoreIcon)``;

export const DrawerCustom = styled(Drawer).attrs({
    className: 'drawer__main',
})`
    width: 260px;
    transition: all 0.3s ease-in-out;

    @media (max-width: 768px) {
        height: calc(100% - 80px);
        position: fixed;
        top: 80px;
        z-index: ${(props) => (props.open ? 10 : -1)};
    }

    & > div[class*='MuiPaper-root'] {
        width: 260px;
        top: 105px;
        border-right: none;
        background-color: transparent;

        @media (max-width: 768px) {
            top: 80px;
        }
    }
`;

export const DrawerInner = styled.div`
    width: 100%;
    height: 100%;
    padding: 0 20px;
    overflow-y: auto;
    padding-bottom: 100px;

    @media (max-width: 768px) {
        height: calc(100vh - 80px);
        background-color: #333;
    }
`;

export const List = styled(ListCustom)``;

export const Accordion = styled(AccordionCustom)`
    padding: 0;
    box-shadow: none;
    background-color: transparent;

    &:before {
        display: none;
    }

    &:after {
        top: -1px;
        left: 0;
        right: 0;
        height: 1px;
        content: '';
        opacity: 1;
        position: absolute;
        transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: ${(props) => props.theme.palette.divider};
    }

    &.Mui-expanded {
        margin: 0;
    }
`;

export const AccordionSummary = styled(AccordionSummaryCustom)`
    padding: 0;

    [class*='MuiSvgIcon-root'] {
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        [class*='MuiButtonBase-root'] {
            background-color: ${(props) => props.theme.palette.drawer.primary};

            [class*='MuiTypography-root'] {
                color: ${(props) => props.theme.palette.primary.light};
            }

            [class*='MuiSvgIcon-root'] {
                fill: ${(props) => props.theme.palette.primary.light};
            }
        }
    }

    &.Mui-expanded {
        min-height: 30px;

        [class*='MuiButtonBase-root'] {
            height: 50px;
            background-color: ${(props) => props.theme.palette.drawer.primary};

            [class*='MuiTypography-root'] {
                color: ${(props) => props.theme.palette.primary.light};
            }

            [class*='MuiSvgIcon-root'] {
                fill: ${(props) => props.theme.palette.primary.light};
            }

            [class*='MuiSvgIcon-root']:not(:first-child) {
                transform: rotate(-180deg);
            }
        }
    }

    .Mui-expanded {
        margin: 12px 0;
    }
`;

export const AccordionDetails = styled(AccordionDetailsCustom)`
    padding: 0 0 0 16px;
    margin-bottom: 10px;

    [class*='MuiList-root'] {
        width: 100%;

        [class*='MuiListItem-root'] {
            padding-top: 3px;
            padding-bottom: 3px;
            transition: all 0.3s ease-in-out;
            margin-bottom: 3px;
        }
    }
`;

export const ListItem = styled(ListItemCustom)<PropsItemList>`
    border-radius: 10px;
    opacity: ${(props) => (props.active === 'true' ? '0.5' : '1')};
    background-color: ${(props) => (props.active === 'true' ? props.theme.palette.drawer.secondary : 'transparent')};
    cursor: ${(props) => (props.active === 'true' ? 'default' : 'point')};

    &:hover {
        background-color: ${(props) => props.theme.palette.drawer.secondary};
    }

    [class*='MuiSvgIcon-root'] {
        fill: ${(props) => props.theme.palette.primary.light};

        &:first-child {
            margin-right: 15px;
        }
    }
`;

export const ListItemText = styled(ListItemTextCustom)`
    color: ${(props) => props.theme.palette.primary.light};
`;

export const Subtitle = styled(Typography).attrs({
    component: 'h3',
})`
    font-weight: 500;
`;

export const IconPermDataSettingOutlined = styled(PermDataSettingOutlinedIcon)``;

export const IconPerson = styled(AccountCircleIcon)``;

export const IconCompany = styled(BusinessIcon)``;

export const IconUsers = styled(PeopleAltOutlinedIcon)``;

export const IconSettings = styled(SettingsOutlinedIcon)``;
