import { red, amber, grey } from '@material-ui/core/colors';
import { unstable_createMuiStrictModeTheme as createTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createPalette' {
    interface PaletterOptionsLoading {
        bg: string;
        bgInner: string;
        dark: string;
        light: string;
    }

    interface PaletteOptionsButton {
        default: { main: string; light: string[]; dark: string[] };
        primary: { main: string; light: string[]; dark: string[] };
        secondary: { main: string; light: string[]; dark: string[] };
        tertiary: { main: string; light: string[]; dark: string[] };
        quaternary: { main: string; light: string[]; dark: string[] };
    }

    interface DrawerOption {
        primary: string;
        primaryDark: string;
        secondary: string;
    }

    interface PaletteOptions {
        main?: string;
        loading?: PaletterOptionsLoading;
        button?: PaletteOptionsButton;
        drawer?: DrawerOption;
    }
}

// Customiza o tema para esse app
const theme = createTheme({
    palette: {
        primary: {
            main: grey[500],
            light: grey[300],
            dark: grey[800],
        },
        secondary: {
            main: amber[700],
        },
        error: {
            main: red[900],
        },
        background: {
            default: grey[900],
            paper: 'rgba(86, 86, 86, 1)',
        },
        divider: grey[800],
        main: '#e47816',
        loading: {
            bg: grey[900],
            bgInner: 'rgba(86,86,86,1)',
            dark: grey[800],
            light: grey[50],
        },
        button: {
            default: {
                main: '#fff',
                light: ['#2b6838', '#3d7a44'],
                dark: ['#1b4824', '#46874d'],
            },
            primary: {
                main: '#414141',
                light: ['#fff', '#f1f1f1'],
                dark: ['#eee', '#e1e1e1'],
            },
            secondary: {
                main: '#cbcbcb',
                light: ['#222222', '#414141'],
                dark: ['#212121', '#313131'],
            },
            tertiary: {
                main: '#fff',
                light: ['#bd3336', '#b15252'],
                dark: ['#932528', '#bd3336'],
            },
            quaternary: {
                main: '#000',
                light: ['#ffa000', '#f7a925'],
                dark: ['#bf7a06', '#ffa000'],
            },
        },
        drawer: {
            primary: '#e47816',
            primaryDark: '#ad590d',
            secondary: '#424242',
        },
    },
});

export default theme;
