import axios from 'axios';

export { axios };

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
    (response) => response.data,
    async (error) => {
        if (error.response.status === 401) {
            localStorage.removeItem(`@${process.env.REACT_APP_NAME}:access_token`);
        }
        return Promise.reject(error.response.data);
    },
);

export default api;
