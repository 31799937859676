import { ReactNode, ReactElement } from 'react';
import { useThemeProvider, ThemeContext } from './useTheme';

interface Props {
    children?: ReactElement | ReactNode;
}

const Theme = ({ children }: Props) => {
    const { value } = useThemeProvider();

    return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

export default Theme;
