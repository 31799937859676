import { ReactNode } from 'react';
import { useDrawerProvider, DrawerContext } from './useDrawer';

interface ChildrenProps {
    children?: ReactNode;
}

const Drawer = ({ children }: ChildrenProps) => {
    const { value } = useDrawerProvider();

    return <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>;
};

export default Drawer;
