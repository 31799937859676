import React, { memo } from 'react';

import * as Component from './styled';

interface Props {
    label?: string;
    name?: string;
    value: string;
    checked: boolean;
    disabled?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputRadio: React.FC<Props> = ({ label, name, value, checked, disabled, onChange }) => (
    <Component.GroupRadio>
        <Component.ButtonCustom color={checked ? 'secondary' : 'primary'} disabled={disabled}>
            <input type="radio" name={name} value={value} onChange={onChange} />
            {label}
        </Component.ButtonCustom>
    </Component.GroupRadio>
);

export default memo(InputRadio);
