import styled from 'styled-components';
import {
    Accordion as AccordionCustom,
    AccordionSummary as AccordionSummaryCustom,
    AccordionDetails as AccordionDetailsCustom,
    List as ListCustom,
    ListItem as ListItemCustom,
    ListItemText as ListItemTextCustom,
} from '@material-ui/core';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import CustomExpandMoreIcon from '@material-ui/icons/ExpandMore';

import CustomVisible from 'components/visible';

import imgChannel from 'assets/images/megaphone.svg';
import imgEad from 'assets/images/elearning.svg';
import imgLgpd from 'assets/images/lgpd.svg';
import imgDueDiligence from 'assets/images/suppliers.svg';
import imgCodeOfEthics from 'assets/images/codeOfEthics.svg';
import imgSyndication from 'assets/images/syndication.svg';

interface PropsItemList {
    active?: string;
}

export const Visible = styled(CustomVisible)``;

export const ExpandMoreIcon = styled(CustomExpandMoreIcon)``;

export const List = styled(ListCustom)``;

export const Accordion = styled(AccordionCustom)`
    padding: 0;
    box-shadow: none;
    background-color: transparent;

    &:before {
        display: none;
    }

    &:after {
        top: -1px;
        left: 0;
        right: 0;
        height: 1px;
        content: '';
        opacity: 1;
        position: absolute;
        transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: ${(props) => props.theme.palette.divider};
    }

    &.Mui-expanded {
        margin: 0;
    }
`;

export const AccordionSummary = styled(AccordionSummaryCustom)`
    padding: 0;

    [class*='MuiSvgIcon-root'] {
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        [class*='MuiButtonBase-root'] {
            background-color: ${(props) => props.theme.palette.drawer.primary};

            [class*='MuiTypography-root'] {
                color: ${(props) => props.theme.palette.primary.light};
            }

            [class*='MuiSvgIcon-root'] {
                fill: ${(props) => props.theme.palette.primary.light};
            }
        }
    }

    &.Mui-expanded {
        min-height: 30px;

        [class*='MuiButtonBase-root'] {
            height: 50px;
            background-color: ${(props) => props.theme.palette.drawer.primary};

            [class*='MuiTypography-root'] {
                color: ${(props) => props.theme.palette.primary.light};
            }

            [class*='MuiSvgIcon-root'] {
                fill: ${(props) => props.theme.palette.primary.light};
            }

            [class*='MuiSvgIcon-root']:not(:first-child) {
                transform: rotate(-180deg);
            }
        }
    }

    .Mui-expanded {
        margin: 12px 0;
    }
`;

export const AccordionDetails = styled(AccordionDetailsCustom)`
    padding: 0 0 0 16px;
    margin-bottom: 10px;

    [class*='MuiList-root'] {
        width: 100%;

        [class*='MuiListItem-root'] {
            padding-top: 3px;
            padding-bottom: 3px;
            transition: all 0.3s ease-in-out;
            margin-bottom: 3px;
        }
    }
`;

export const ListItem = styled(ListItemCustom)<PropsItemList>`
    border-radius: 10px;
    opacity: ${(props) => (props.active === 'true' ? '0.5' : '1')};
    background-color: ${(props) => (props.active === 'true' ? props.theme.palette.drawer.secondary : 'transparent')};
    cursor: ${(props) => (props.active === 'true' ? 'default' : 'point')};

    &:hover {
        background-color: ${(props) => props.theme.palette.drawer.secondary};
    }

    [class*='MuiSvgIcon-root'] {
        fill: ${(props) => props.theme.palette.primary.light};

        &:first-child {
            margin-right: 15px;
        }
    }
`;

export const ListItemText = styled(ListItemTextCustom)`
    color: ${(props) => props.theme.palette.primary.light};
`;

export const IconPerson = styled(AccountCircleIcon)``;

export const IconCompany = styled(BusinessIcon)``;

export const IconUsers = styled(PeopleAltOutlinedIcon)``;

export const ReportingChannel = styled.img.attrs({
    src: imgChannel,
})`
    width: 28px;
    margin-right: 10px;
`;

export const Ead = styled.img.attrs({
    src: imgEad,
})`
    width: 28px;
    margin-right: 10px;
`;

export const Lgpd = styled.img.attrs({
    src: imgLgpd,
})`
    width: 28px;
    margin-right: 10px;
`;

export const DueDiligence = styled.img.attrs({
    src: imgDueDiligence,
})`
    width: 28px;
    margin-right: 10px;
`;

export const CodeOfEthic = styled.img.attrs({
    src: imgCodeOfEthics,
})`
    width: 28px;
    margin-right: 10px;
`;

export const Syndication = styled.img.attrs({
    src: imgSyndication,
})`
    width: 28px;
    margin-right: 10px;
`;
