import { useState, useContext, createContext, useCallback, useMemo } from 'react';
import fscreen from 'fscreen';

const themeDefault = 'default';
const haveItDefault = {
    name: '',
    content: {},
};

type HaveIt = {
    name: string;
    content: unknown;
};

interface ContextProps {
    themeSelected: string;
    haveIt: HaveIt;
    fullscreen: boolean;
    onChangeTheme: (value: string) => void;
    onChangeHaveIt: (name: string, content: unknown) => void;
    onToggleFullscreen(): void;
    onExitFullscreen(): void;
}

export const ThemeContext = createContext<ContextProps>({
    themeSelected: themeDefault,
    haveIt: haveItDefault,
    fullscreen: false,
    onChangeTheme: () => {},
    onChangeHaveIt: () => {},
    onToggleFullscreen: () => {},
    onExitFullscreen: () => {},
});

export function useTheme() {
    const context = useContext(ThemeContext);
    return context;
}

export function useThemeProvider() {
    const [themeSelected, setThemeSelected] = useState<string>(themeDefault);
    const [haveIt, setHaveIt] = useState<HaveIt>(haveItDefault);
    const [fullscreen, setFullscreen] = useState(false);

    const onChangeTheme = useCallback((value: string) => {
        setThemeSelected(value);
    }, []);

    const onChangeHaveIt = useCallback((name: string, content: unknown) => {
        setHaveIt({ name, content });
    }, []);

    const onToggleFullscreen = useCallback(() => {
        setFullscreen((oldFullscreen) => {
            if (oldFullscreen) {
                fscreen.exitFullscreen();
            }
            fscreen.requestFullscreen(document.documentElement);

            return !oldFullscreen;
        });
    }, []);

    const onExitFullscreen = useCallback(() => {
        fscreen.exitFullscreen();
        setFullscreen(false);
    }, []);

    fscreen.onfullscreenchange = () => {
        if (!fscreen.fullscreenElement) {
            setFullscreen(false);
        }
    };

    const value = useMemo(
        () => ({
            themeSelected,
            haveIt,
            fullscreen,
            onChangeTheme,
            onChangeHaveIt,
            onToggleFullscreen,
            onExitFullscreen,
        }),
        [themeSelected, haveIt, fullscreen, onChangeTheme, onChangeHaveIt, onToggleFullscreen, onExitFullscreen],
    );

    return {
        value,
    };
}
