import React, { memo } from 'react';

import useVideoDynamicDetails from './useVideoDynamicDetails';

import * as Component from './styled';

interface Props {
    open: boolean;
    status: 'loading' | 'confirm' | 'success' | 'error' | 'saveConfig' | '';
    message?: string;
}

const VideoDynamicDetails: React.FC<Props> = ({ open, status, message }) => {
    const {
        loadingModal,
        loadingForm,
        loadingForm2,
        loadingConfirm,
        file,
        urlVideo,
        statusPaused,
        showQuestions,
        showConfirm,
        msgConfirm,
        errors,
        translate,
        checkIfIcanAdd,
        handleGoback,
        handleCloseVideoDynamic,
        handleChangeFile,
        handleShowQuestion,
        handleHideQuestion,
        handleAddAlternative,
        handleRemoveAlternative,
        handleChange,
        getItemData,
        handleConfirm,
        handleSubmitQuestion,
        handleSubmitVideo,
        handleCloseConfirm,
    } = useVideoDynamicDetails();

    if (loadingModal) {
        return (
            <Component.Modal open={open} aria-labelledby="alert-dialog-title" scroll="body">
                <Component.ModalStatus>
                    <Component.Loading color="secondary" />
                </Component.ModalStatus>
            </Component.Modal>
        );
    }

    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.Modal
                        open={open}
                        onClose={handleCloseVideoDynamic}
                        aria-labelledby="alert-dialog-title"
                        scroll="body"
                    >
                        <Component.ModalStatus>
                            <Component.Loading color="secondary" />
                        </Component.ModalStatus>
                    </Component.Modal>
                );
            case 'success':
                return (
                    <Component.Modal
                        open={open}
                        onClose={handleCloseVideoDynamic}
                        aria-labelledby="alert-dialog-title"
                        scroll="body"
                    >
                        <Component.ModalStatus>
                            <Component.ModalGroupMessage>
                                <Component.IconSuccess />
                                <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                            </Component.ModalGroupMessage>
                        </Component.ModalStatus>
                    </Component.Modal>
                );
            case 'error':
                return (
                    <Component.Modal
                        open={open}
                        onClose={handleCloseVideoDynamic}
                        aria-labelledby="alert-dialog-title"
                        scroll="body"
                    >
                        <Component.ModalStatus>
                            <Component.ModalGroupMessage>
                                <Component.IconError />
                                <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                                <Component.ModalActions>
                                    <Component.Button color="primary" onClick={handleGoback}>
                                        {translate('modal.button.goback')}
                                    </Component.Button>
                                </Component.ModalActions>
                            </Component.ModalGroupMessage>
                        </Component.ModalStatus>
                    </Component.Modal>
                );

            default:
                return (
                    <Component.Modal
                        open={open}
                        onClose={handleCloseVideoDynamic}
                        aria-labelledby="alert-dialog-title"
                        scroll="body"
                    >
                        <Component.ModalBody>
                            <Component.WrapFormVideo showQuestion={showQuestions}>
                                <Component.Form loading={loadingForm} onSubmit={handleSubmitVideo}>
                                    <Component.FormContent>
                                        <Component.ModalTitle center="true">
                                            {translate(`modal.videoDynamicDetailsEad.title.add`)}
                                        </Component.ModalTitle>
                                        <Component.ButtonUpload
                                            color={file ? 'primary' : 'secondary'}
                                            label={translate('modal.videoDynamicDetailsEad.button.attachment')}
                                            name="video"
                                            accept=".mp4"
                                            type="mp4"
                                            onChange={handleChangeFile}
                                        />
                                        <Component.Visible condition={!!urlVideo}>
                                            <Component.Video src={urlVideo} />
                                            <Component.ModalActions>
                                                <Component.Visible
                                                    condition={
                                                        !!statusPaused &&
                                                        statusPaused.time! > 10 &&
                                                        !checkIfIcanAdd(statusPaused.time!)
                                                    }
                                                >
                                                    <Component.Button
                                                        color="secondary"
                                                        size="small"
                                                        onClick={() => handleShowQuestion(statusPaused!.time!)}
                                                    >
                                                        {translate('modal.videoDynamicDetailsEad.addTime', {
                                                            time: statusPaused?.timeFormated,
                                                        })}
                                                    </Component.Button>
                                                </Component.Visible>
                                                <Component.Visible condition={!!file}>
                                                    <Component.Button type="submit">
                                                        {translate('modal.button.save')}
                                                    </Component.Button>
                                                </Component.Visible>
                                            </Component.ModalActions>
                                        </Component.Visible>
                                    </Component.FormContent>
                                </Component.Form>
                            </Component.WrapFormVideo>
                            <Component.Visible condition={showQuestions}>
                                <Component.ShowQuestion>
                                    <Component.ContainerQuestion>
                                        <Component.HeaderQuestion>
                                            {translate('modal.videoDynamicDetailsEad.addTime', {
                                                time: statusPaused?.timeFormated,
                                            })}
                                        </Component.HeaderQuestion>
                                        <Component.Form loading={loadingForm2} onSubmit={handleSubmitQuestion}>
                                            <Component.FormGroup>
                                                <Component.List>
                                                    <Component.ButtonRadio
                                                        label={translate('modal.videoDynamicDetailsEad.button.info')}
                                                        name="type"
                                                        value="12"
                                                        checked={getItemData()?.type === '12'}
                                                        onChange={handleChange}
                                                    />
                                                    <Component.ButtonRadio
                                                        label={translate('modal.videoDynamicDetailsEad.button.check')}
                                                        name="type"
                                                        value="13"
                                                        checked={getItemData()?.type === '13'}
                                                        onChange={handleChange}
                                                    />
                                                    <Component.ButtonRadio
                                                        label={translate('modal.videoDynamicDetailsEad.button.quiz')}
                                                        name="type"
                                                        value="11"
                                                        checked={getItemData()?.type === '11'}
                                                        onChange={handleChange}
                                                    />
                                                    <Component.ButtonRadio
                                                        label={translate('modal.videoDynamicDetailsEad.button.comment')}
                                                        name="type"
                                                        value="1"
                                                        checked={getItemData()?.type === '1'}
                                                        onChange={handleChange}
                                                    />
                                                </Component.List>
                                            </Component.FormGroup>
                                            <Component.FormGroup>
                                                <Component.ListBackground>
                                                    <Component.InputColor
                                                        type="color"
                                                        id={`color__${getItemData()?.fake_id}`}
                                                        name="color"
                                                        value={getItemData()?.color}
                                                        onChange={handleChange}
                                                    />
                                                    <Component.Label htmlFor={`color__${getItemData()?.fake_id}`}>
                                                        {translate('modal.videoDynamicDetailsEad.selectColor')}
                                                    </Component.Label>
                                                </Component.ListBackground>
                                            </Component.FormGroup>
                                            <Component.FormGroup>
                                                <Component.Label>
                                                    {translate('modal.videoDynamicDetailsEad.question')}
                                                </Component.Label>
                                                <Component.InputText
                                                    multiline
                                                    minRows={5}
                                                    maxRows={5}
                                                    label=""
                                                    name="question"
                                                    value={getItemData()?.question ?? ''}
                                                    onChange={handleChange}
                                                    error={!!errors.question}
                                                />
                                            </Component.FormGroup>
                                            <Component.Visible condition={getItemData()?.type === '13'}>
                                                <Component.FormGroup>
                                                    <Component.Label>
                                                        {translate('modal.videoDynamicDetailsEad.responses')}
                                                    </Component.Label>
                                                </Component.FormGroup>
                                                <Component.FormGroup>
                                                    <Component.ListRadios>
                                                        <Component.GroupResponse>
                                                            <Component.InputRadio
                                                                id="yes"
                                                                name="alternative_is_correct"
                                                                value="1"
                                                                checked={getItemData()?.response === '1'}
                                                                onChange={(e) => handleChange(e, 'is_check')}
                                                            />
                                                            <Component.Label htmlFor="yes">
                                                                {translate('modal.videoDynamicDetailsEad.yes')}
                                                            </Component.Label>
                                                        </Component.GroupResponse>
                                                        <Component.GroupResponse>
                                                            <Component.InputRadio
                                                                id="no"
                                                                name="alternative_is_correct"
                                                                value="2"
                                                                checked={getItemData()?.response === '2'}
                                                                onChange={(e) => handleChange(e, 'is_check')}
                                                            />
                                                            <Component.Label htmlFor="no">
                                                                {translate('modal.videoDynamicDetailsEad.no')}
                                                            </Component.Label>
                                                        </Component.GroupResponse>
                                                    </Component.ListRadios>
                                                </Component.FormGroup>
                                            </Component.Visible>
                                            <Component.Visible condition={getItemData()?.type === '11'}>
                                                <Component.FormGroup>
                                                    <Component.Label>
                                                        {translate('modal.videoDynamicDetailsEad.responses')}
                                                    </Component.Label>
                                                </Component.FormGroup>
                                                <Component.Visible condition={!!getItemData()?.alternatives?.length}>
                                                    <Component.FormGroup>
                                                        <Component.AlternativeResponse error={!!errors.correct_answer}>
                                                            {getItemData()?.alternatives?.map((item, index) => (
                                                                <Component.GroupResponse key={item.fake_id}>
                                                                    <Component.LabelNumber>
                                                                        ( {index + 1} )
                                                                    </Component.LabelNumber>
                                                                    <Component.InputRadio
                                                                        name="alternative_is_correct"
                                                                        value={item.fake_id}
                                                                        checked={item.alternative_is_correct}
                                                                        onChange={(e) =>
                                                                            handleChange(e, 'is_correct', item.fake_id)
                                                                        }
                                                                    />
                                                                    <Component.InputText
                                                                        label=""
                                                                        size="small"
                                                                        placeholder={translate(
                                                                            'modal.videoDynamicDetailsEad.placeholderAlternative',
                                                                        )}
                                                                        name={`alternative_description_${item.fake_id}`}
                                                                        value={item.alternative_description!}
                                                                        onChange={(e) =>
                                                                            handleChange(e, 'description', item.fake_id)
                                                                        }
                                                                        error={
                                                                            !!errors.alternatives?.find(
                                                                                (alternative) =>
                                                                                    alternative.fake_id ===
                                                                                    item.fake_id,
                                                                            )
                                                                        }
                                                                    />
                                                                    <Component.IconButton
                                                                        onClick={() =>
                                                                            handleRemoveAlternative(
                                                                                !!item.alternative_is_correct!,
                                                                                getItemData()!.question_id!,
                                                                                item.alternative_description!,
                                                                                item.fake_id!,
                                                                            )
                                                                        }
                                                                    >
                                                                        <Component.IconRemove />
                                                                    </Component.IconButton>
                                                                </Component.GroupResponse>
                                                            ))}
                                                        </Component.AlternativeResponse>
                                                    </Component.FormGroup>
                                                </Component.Visible>
                                                <Component.Visible
                                                    condition={
                                                        !!getItemData()?.alternatives &&
                                                        getItemData()!.alternatives!.length !== 4
                                                    }
                                                >
                                                    <Component.Button
                                                        size="small"
                                                        onClick={handleAddAlternative}
                                                        color="primary"
                                                    >
                                                        {translate('modal.videoDynamicDetailsEad.button.addResponse')}
                                                    </Component.Button>
                                                </Component.Visible>
                                            </Component.Visible>
                                            <Component.ModalActions center="true">
                                                <Component.Button onClick={handleHideQuestion} color="secondary">
                                                    {translate('modal.button.goback')}
                                                </Component.Button>
                                                <Component.Button type="submit">
                                                    {translate('modal.button.save')}
                                                </Component.Button>
                                            </Component.ModalActions>
                                        </Component.Form>
                                    </Component.ContainerQuestion>
                                </Component.ShowQuestion>
                            </Component.Visible>
                            <Component.Visible condition={showConfirm}>
                                <Component.Confirme loading={loadingConfirm}>
                                    <Component.ModalTitle>{translate('modal.button.confirm')}</Component.ModalTitle>
                                    {msgConfirm && <Component.Text>{msgConfirm}</Component.Text>}
                                    <Component.ModalActions>
                                        <Component.Button color="primary" type="button" onClick={handleCloseConfirm}>
                                            {translate('modal.button.cancel')}
                                        </Component.Button>
                                        <Component.Button type="button" onClick={handleConfirm}>
                                            {translate('modal.button.confirm')}
                                        </Component.Button>
                                    </Component.ModalActions>
                                </Component.Confirme>
                                <Component.ConfirmeMask />
                            </Component.Visible>
                        </Component.ModalBody>
                    </Component.Modal>
                );
        }
    };

    return modalContent();
};

export default memo(VideoDynamicDetails);
