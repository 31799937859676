import React, { memo } from 'react';

import useNavbar from './useNavbar';

import * as Component from './styled';

const Navbar: React.FC = () => {
    const { openDrawer, activated, profile, haveIt, fields, translate, onOpenDrawer, handleChangeCustomer } =
        useNavbar();
    return (
        <Component.Navbar open={openDrawer}>
            <Component.ToolBar>
                <Component.GroupLogo>
                    <Component.Logo />
                    {profile?.firstAccess !== 1 && (
                        <Component.ButtonOpen edge="start" onClick={() => onOpenDrawer(!openDrawer)}>
                            {!openDrawer ? <Component.IconMenuOpen /> : <Component.IconMenuClose />}
                        </Component.ButtonOpen>
                    )}
                </Component.GroupLogo>
                <Component.NameProject>{activated?.nameModule}</Component.NameProject>
                <Component.GroupOptions>
                    <Component.Visible condition={profile?.levelAccess === 1}>
                        <Component.WrapInputSelect>
                            {fields.customers.length > 0 && (
                                <Component.InputSelect
                                    color="secondary"
                                    label={translate('home.selectCustomer')}
                                    options={fields.customers}
                                    value={fields?.customerSelected ?? ''}
                                    onChange={handleChangeCustomer}
                                />
                            )}
                        </Component.WrapInputSelect>
                    </Component.Visible>
                    <Component.CustomContent name={haveIt.name} content={haveIt.content} />
                    <Component.MenuSettings />
                </Component.GroupOptions>
            </Component.ToolBar>
        </Component.Navbar>
    );
};

export default memo(Navbar);
