import React, { memo } from 'react';

import useQuizDetails from './useQuizDetails';

import * as Component from './styled';

interface Props {
    open: boolean;
    status: 'loading' | 'confirm' | 'success' | 'error' | 'saveConfig' | '';
    message?: string;
    title?: string;
    text?: string;
}

const QuizDetails: React.FC<Props> = ({ open, status, message, title, text }) => {
    const {
        loadingModal,
        configQuiz,
        fields,
        errors,
        translate,
        handleGoback,
        handleCloseQuiz,
        handleAddQuestion,
        handleAddAlternative,
        handleRemoveQuestion,
        handleRemoveAlternative,
        handleChangeConfig,
        handleChange,
        handleConfirm,
        handleSubmit,
        handleSubmitConfig,
    } = useQuizDetails();

    if (loadingModal) {
        return (
            <Component.Modal open={open} aria-labelledby="alert-dialog-title" scroll="body">
                <Component.ModalStatus>
                    <Component.Loading color="secondary" />
                </Component.ModalStatus>
            </Component.Modal>
        );
    }

    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.Modal
                        open={open}
                        onClose={handleCloseQuiz}
                        aria-labelledby="alert-dialog-title"
                        scroll="body"
                    >
                        <Component.ModalStatus>
                            <Component.Loading color="secondary" />
                        </Component.ModalStatus>
                    </Component.Modal>
                );
            case 'success':
                return (
                    <Component.Modal
                        open={open}
                        onClose={handleCloseQuiz}
                        aria-labelledby="alert-dialog-title"
                        scroll="body"
                    >
                        <Component.ModalStatus>
                            <Component.ModalGroupMessage>
                                <Component.IconSuccess />
                                <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                            </Component.ModalGroupMessage>
                        </Component.ModalStatus>
                    </Component.Modal>
                );
            case 'saveConfig':
                return (
                    <Component.Modal open={open} aria-labelledby="alert-dialog-title" scroll="body" size="small">
                        <Component.ModalTitle center="true">
                            {translate('modal.quizDetailsEad.title.saveConfig')}
                        </Component.ModalTitle>
                        <Component.ModalBody>
                            <Component.Form onSubmit={handleSubmitConfig}>
                                <Component.FormGroup>
                                    <Component.InputText
                                        type="number"
                                        color="primary"
                                        label={translate('modal.quizDetailsEad.form.totalQuestionView')}
                                        name="question_limit_view"
                                        value={configQuiz.question_limit_view.value}
                                        onChange={handleChangeConfig}
                                        error={Boolean(configQuiz.question_limit_view.error)}
                                        helperText={configQuiz.question_limit_view.error}
                                    />
                                </Component.FormGroup>
                                <Component.FormGroup>
                                    <Component.InputText
                                        type="number"
                                        color="primary"
                                        label={translate('modal.quizDetailsEad.form.percentageOfCorrectAnswers')}
                                        name="percent_hits"
                                        value={configQuiz.percent_hits.value}
                                        onChange={handleChangeConfig}
                                        error={Boolean(configQuiz.percent_hits.error)}
                                        helperText={configQuiz.percent_hits.error}
                                    />
                                </Component.FormGroup>
                                <Component.ModalActions>
                                    <Component.Button type="submit">
                                        {translate('modal.quizDetailsEad.button.save')}
                                    </Component.Button>
                                </Component.ModalActions>
                            </Component.Form>
                        </Component.ModalBody>
                    </Component.Modal>
                );
            case 'error':
                return (
                    <Component.Modal
                        open={open}
                        onClose={handleCloseQuiz}
                        aria-labelledby="alert-dialog-title"
                        scroll="body"
                    >
                        <Component.ModalStatus>
                            <Component.ModalGroupMessage>
                                <Component.IconError />
                                <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                                <Component.ModalActions>
                                    <Component.Button color="primary" onClick={handleGoback}>
                                        {translate('modal.button.goback')}
                                    </Component.Button>
                                </Component.ModalActions>
                            </Component.ModalGroupMessage>
                        </Component.ModalStatus>
                    </Component.Modal>
                );
            case 'confirm':
                return (
                    <Component.Modal open={open} aria-labelledby="alert-dialog-title" scroll="body" size="small">
                        <Component.ModalTitle center="true">{title}</Component.ModalTitle>
                        <Component.ModalBody>
                            {text && <Component.ModalText center="true">{text}</Component.ModalText>}
                        </Component.ModalBody>
                        <Component.ModalActions center="true">
                            <Component.Button onClick={handleGoback} color="primary">
                                {translate('modal.button.cancel')}
                            </Component.Button>
                            <Component.Button onClick={handleConfirm}>
                                {translate('modal.button.confirm')}
                            </Component.Button>
                        </Component.ModalActions>
                    </Component.Modal>
                );

            default:
                return (
                    <Component.Modal
                        open={open}
                        onClose={handleCloseQuiz}
                        aria-labelledby="alert-dialog-title"
                        scroll="body"
                    >
                        <Component.ModalTitle>{translate(`modal.quizDetailsEad.title.add`)}</Component.ModalTitle>
                        <Component.ModalBody>
                            <Component.Form onSubmit={handleSubmit}>
                                {fields?.map((item, index) => (
                                    <Component.Question
                                        key={item.fake_id}
                                        errors={errors}
                                        questionNumber={index}
                                        {...item}
                                        onChange={handleChange}
                                        onAddAlternative={handleAddAlternative}
                                        onRemoveQuestion={handleRemoveQuestion}
                                        onRemoveAternative={handleRemoveAlternative}
                                    />
                                ))}
                                <Component.ModalActions>
                                    <Component.Button onClick={handleAddQuestion} color="secondary">
                                        <Component.IconAdd />
                                        {translate('modal.quizDetailsEad.button.addQuestion')}
                                    </Component.Button>
                                    <Component.Button onClick={handleCloseQuiz} color="primary">
                                        {translate('modal.button.cancel')}
                                    </Component.Button>
                                    <Component.Button type="submit">
                                        {translate('modal.quizDetailsEad.button.save')}
                                    </Component.Button>
                                </Component.ModalActions>
                            </Component.Form>
                        </Component.ModalBody>
                    </Component.Modal>
                );
        }
    };

    return modalContent();
};

export default memo(QuizDetails);
