import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import Img from 'components/image';

export const Wrapper = styled(IconButton)`
    && {
        display: flex;
        background: rgba(230, 230, 230, 0.7);
        padding: 0;
        margin: 0;
        border-radius: 0;
    }
`;

export const WrapperCustom = styled(IconButton)`
    && {
        display: flex;
        padding: 0;
        margin: 0;
        border-radius: 0;

        a {
            background-color: rgba(230, 230, 230, 0.7);
            padding: 10px;
            min-width: auto;
            max-width: auto;
            height: auto;
        }
    }
`;

export const Image = styled(Img)`
    max-width: 200px;
    height: auto;
`;

export const FileImage = styled.a`
    text-decoration: none;
    background-color: rgba(230, 230, 230, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const File = styled.a`
    min-width: 200px;
    max-width: 200px;
    height: 90px;
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const IconFile = styled(FileCopyIcon)`
    color: #ccc;
    font-size: 40px;
`;

export const TextExt = styled.span`
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    color: red;
`;
