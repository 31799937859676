import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import es from './es';
import en from './en-US';
import ptBR from './pt-BR';

i18n.use(initReactI18next).init({
    resources: {
        'pt-BR': {
            translation: {
                global: ptBR.global,
                login: ptBR.login,
                resetPassword: ptBR.resetPassword,
                registrationSupplier: ptBR.registrationSupplier,
                lgpdQuiz: ptBR.lgpdQuiz,
                home: ptBR.home,
                navbar: ptBR.navbar,
                modal: ptBR.modal,
                format: ptBR.format,
                classification: ptBR.classification,
                table: ptBR.table,
                status: ptBR.status,
                complaints: ptBR.complaints,
                complaintsDetails: ptBR.complaintsDetails,
                settings: ptBR.settings,
                profile: ptBR.profile,
                companies: ptBR.companies,
                clients: ptBR.clients,
                units: ptBR.units,
                users: ptBR.users,
                validation: ptBR.validation,
                page404: ptBR.page404,
                notPermission: ptBR.notPermission,
                suppliers: ptBR.suppliers,
                questionnaires: ptBR.questionnaires,
                quiz: ptBR.quiz,
                categories: ptBR.categories,
                ead: ptBR.ead,
                course: ptBR.course,
                classContent: ptBR.classContent,
                myCourses: ptBR.myCourses,
                dashboardEad: ptBR.dashboardEad,
                externalEad: ptBR.externalEad,
                jobCandidates: ptBR.jobCandidates,
                syndication: ptBR.syndication,
                questions: ptBR.questions,
                questionsLgpd: ptBR.questionsLgpd,
                groupLgpd: ptBR.groupLgpd,
                departaments: ptBR.departaments,
                notifications: ptBR.notifications,
                viewByCourse: ptBR.viewByCourse,
                indicatorsByCourse: ptBR.indicatorsByCourse,
            },
        },
        en: {
            translation: {
                global: en.global,
                login: en.login,
                navbar: en.navbar,
                resetPassword: en.resetPassword,
                registrationSupplier: en.registrationSupplier,
                lgpdQuiz: en.lgpdQuiz,
                home: en.home,
                modal: en.modal,
                format: en.format,
                classification: en.classification,
                table: en.table,
                status: en.status,
                complaints: en.complaints,
                complaintsDetails: en.complaintsDetails,
                settings: en.settings,
                profile: en.profile,
                companies: en.companies,
                clients: en.clients,
                units: en.units,
                users: en.users,
                validation: en.validation,
                page404: en.page404,
                notPermission: en.notPermission,
                suppliers: en.suppliers,
                questionnaires: en.questionnaires,
                quiz: en.quiz,
                categories: en.categories,
                ead: en.ead,
                course: en.course,
                classContent: en.classContent,
                myCourses: en.myCourses,
                dashboardEad: en.dashboardEad,
                externalEad: en.externalEad,
                jobCandidates: en.jobCandidates,
                syndication: en.syndication,
                questions: en.questions,
                questionsLgpd: en.questionsLgpd,
                groupLgpd: en.groupLgpd,
                departaments: en.departaments,
                notifications: en.notifications,
                viewByCourse: en.viewByCourse,
                indicatorsByCourse: en.indicatorsByCourse,
            },
        },
        es: {
            translation: {
                global: es.global,
                login: es.login,
                resetPassword: es.resetPassword,
                registrationSupplier: es.registrationSupplier,
                lgpdQuiz: es.lgpdQuiz,
                home: es.home,
                navbar: es.navbar,
                modal: es.modal,
                format: es.format,
                classification: es.classification,
                table: es.table,
                status: es.status,
                complaints: es.complaints,
                complaintsDetails: es.complaintsDetails,
                settings: es.settings,
                profile: es.profile,
                companies: es.companies,
                clients: es.clients,
                units: es.units,
                users: es.users,
                validation: es.validation,
                page404: es.page404,
                notPermission: es.notPermission,
                suppliers: es.suppliers,
                questionnaires: es.questionnaires,
                quiz: es.quiz,
                categories: es.categories,
                ead: es.ead,
                course: es.course,
                classContent: es.classContent,
                myCourses: es.myCourses,
                dashboardEad: es.dashboardEad,
                externalEad: es.externalEad,
                jobCandidates: es.jobCandidates,
                syndication: es.syndication,
                questions: es.questions,
                questionsLgpd: es.questionsLgpd,
                groupLgpd: es.groupLgpd,
                departaments: es.departaments,
                notifications: es.notifications,
                viewByCourse: es.viewByCourse,
                indicatorsByCourse: es.indicatorsByCourse,
            },
        },
    },
    lng: window.localStorage.i18nextLng || 'pt-BR',
    fallbackLng: window.localStorage.i18nextLng || 'pt-BR',
    interpolation: {
        escapeValue: false,
    },
});

document.documentElement.lang = i18n.language;

export default i18n;
