import React, { memo } from 'react';

import { nameInitials } from 'utils';

import * as Component from './styled';

interface Props {
    label: string;
    description?: string;
    active?: boolean;
    disabled?: boolean;
    onClick?: () => void;
}

const ButtonRadioUser: React.FC<Props> = ({ label, description, active, disabled, onClick }) => (
    <Component.ButtonCustom color={active ? 'secondary' : 'primary'} disabled={disabled} onClick={onClick}>
        <Component.Wrapper>
            <Component.Avatar>{nameInitials(label)}</Component.Avatar>
            <Component.Group>
                <Component.Label>{label}</Component.Label>
                {description && <Component.Description>{description}</Component.Description>}
            </Component.Group>
        </Component.Wrapper>
    </Component.ButtonCustom>
);

export default memo(ButtonRadioUser);
