import styled from 'styled-components';

import PlusOneIcon from '@material-ui/icons/PlusOne';
import DeleteIcon from '@material-ui/icons/Delete';

import CustomRadio from '@material-ui/core/Radio';

import {
    FormGroup as CustomFormGroup,
    InputText as CustomInput,
    InputSelect as CustomInputSelect,
} from 'components/form';
import { Button as CustomButton } from 'components/button';

export const IconAdd = styled(PlusOneIcon)`
    margin-right: 5px;
`;

export const FormGroup = styled(CustomFormGroup)`
    margin-bottom: 0;
`;

export const InputText = styled(CustomInput)``;

export const InputSelect = styled(CustomInputSelect)``;

export const InputRadio = styled(CustomRadio)`
    && {
        margin-left: 10px;
        color: ${(props) => props.theme.palette.button.default.light[0]};
        border-radius: 10px;

        svg {
            width: 35px;
            height: 35px;
        }

        &.Mui-checked {
            color: ${(props) => props.theme.palette.button.default.light[0]};
        }
    }
`;

export const Button = styled(CustomButton)``;

export const IconRemove = styled(DeleteIcon)`
    && {
        color: #fff;
        font-size: 22px;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    button {
        height: 35px;
        padding: 6px 15px;

        @media (max-width: 768px) {
            margin: 0 0 15px;
        }

        &:last-child {
            margin-right: 0;
        }

        &.MuiButton-containedPrimary {
            background-color: ${({ theme }: any) => theme.palette.button.tertiary.light[0]};
            background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.tertiary.light})`};

            .MuiButton-label {
                color: ${({ theme }: any) => theme.palette.button.tertiary.main};
            }

            &:not(:disabled):hover {
                background-color: ${({ theme }: any) => theme.palette.button.tertiary.dark[0]};
                background-image: ${({ theme }: any) =>
                    `linear-gradient(180deg, ${theme.palette.button.tertiary.dark})`};
            }
        }
    }
`;

export const Wrap = styled.div`
    && {
        padding: 10px 20px 0;
        background-color: #eee;
        border-radius: 5px;
        margin-bottom: 30px;
        border: 1px solid #666;

        &:nth-child(odd) {
            background-color: #ededed;
        }
    }
`;

export const Label = styled.label`
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
`;

export const Group = styled.div<{ isCorrect: string }>`
    && {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background-color: ${(props) => (props.isCorrect === 'true' ? '#daefde' : 'transparent')};
        padding: 5px;
        border-radius: 4px;
        transition: all 0.3s ease-in-out;
        margin-bottom: -5px;
        position: relative;

        .MuiFormControl-root {
            width: calc(100% - 135px);
        }

        button {
            width: 60px;
            min-width: 60px;
            max-width: 60px;
            margin-left: 10px;

            &.MuiButton-containedPrimary {
                background-color: ${({ theme }: any) => theme.palette.button.tertiary.light[0]};
                background-image: ${({ theme }: any) =>
                    `linear-gradient(180deg, ${theme.palette.button.tertiary.light})`};

                .MuiButton-label {
                    color: ${({ theme }: any) => theme.palette.button.tertiary.main};
                }

                &:not(:disabled):hover {
                    background-color: ${({ theme }: any) => theme.palette.button.tertiary.dark[0]};
                    background-image: ${({ theme }: any) =>
                        `linear-gradient(180deg, ${theme.palette.button.tertiary.dark})`};
                }

                &.Mui-disabled {
                    opacity: 0.4;
                }
            }
        }
    }
`;

export const AllAlternatives = styled.div<{ error: string }>`
    background-color: ${(props) => (props.error === 'true' ? '#f1dddd' : 'transparent')};
    padding: ${(props) => (props.error === 'true' ? '10px 10px 1px' : '0')};
    margin-bottom: ${(props) => (props.error === 'true' ? '10px' : '0')};
    transition: all 0.3s ease-in-out;
`;

export const LabelError = styled.span`
    color: #932528;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
`;
