import styled from 'styled-components';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Form, { FormGroup, InputTextSearch, InputSelect, InputDateTime, Button } from 'components/form';

export { Form, FormGroup, InputTextSearch, InputSelect, InputDateTime, Switch, FormControlLabel, Button };

export const Modal = styled(Dialog)`
    .MuiPaper-root {
        min-width: 480px;
        min-height: 150px;
        background-color: #fff;
        background-image: linear-gradient(180deg, #fff, #f1f1f1, #eee);
        border-radius: 10px;

        @media (max-width: 768px) {
            min-width: auto;
        }
    }
`;

export const ModalTitle = styled(DialogTitle)`
    color: #000;

    .MuiTypography-root {
        font-weight: 600;
    }
`;

export const ModalText = styled(DialogContentText)`
    text-align: center;
    color: #000;
`;

export const ModalBody = styled(DialogContent)`
    && {
        min-height: 150px;
    }
`;

export const ModalActions = styled(DialogActions)`
    && {
        display: flex;
        justify-content: center;
        padding: 8px 0;

        @media (max-width: 768px) {
            padding: 8px 0;
        }

        @media (max-width: 600px) {
            flex-direction: column;
        }

        button {
            width: 100%;
            padding: 12px 25px;
            margin: 0 0 15px;

            &:first-child {
                margin-right: 15px;

                @media (max-width: 600px) {
                    margin-right: 0;
                }
            }

            @media (max-width: 600px) {
                margin-left: 0;
                margin-right: 0;
            }

            .MuiButton-label {
                color: ${({ theme }: any) => theme.palette.button.default.main};
            }

            &.MuiButton-containedPrimary {
                background-color: ${({ theme }: any) => theme.palette.button.tertiary.light[0]};
                background-image: ${({ theme }: any) =>
                    `linear-gradient(180deg, ${theme.palette.button.tertiary.light})`};

                .MuiButton-label {
                    color: ${({ theme }: any) => theme.palette.button.tertiary.main};
                }

                &:not(:disabled):hover {
                    background-color: ${({ theme }: any) => theme.palette.button.tertiary.dark[0]};
                    background-image: ${({ theme }: any) =>
                        `linear-gradient(180deg, ${theme.palette.button.tertiary.dark})`};
                }
            }

            &.MuiButton-containedSecondary {
                background-color: ${({ theme }: any) => theme.palette.button.secondary.light[0]};
                background-image: ${({ theme }: any) =>
                    `linear-gradient(180deg, ${theme.palette.button.secondary.light})`};

                .MuiButton-label {
                    color: ${({ theme }: any) => theme.palette.button.secondary.main};
                }

                &:not(:disabled):hover {
                    background-color: ${({ theme }: any) => theme.palette.button.secondary.dark[0]};
                    background-image: ${({ theme }: any) =>
                        `linear-gradient(180deg, ${theme.palette.button.secondary.dark})`};
                }
            }
        }
    }
`;

export const ModalStatus = styled.div`
    height: 100%;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    margin: auto;
`;

export const ModalGroupMessage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ModalTextSuccess = styled.h3`
    font-size: 16px;
    font-weight: 400;
    text-align: center;
`;

export const Loading = styled(CircularProgress).attrs({
    size: 45,
})`
    color: #2b6838;
`;

export const IconSuccess = styled(CheckCircleOutlineIcon)`
    font-size: 60px;
    color: #2b6838;
    margin-bottom: 10px;
`;

export const IconError = styled(ErrorOutlineIcon)`
    font-size: 60px;
    color: #bd3336;
    margin-bottom: 10px;
`;

export const Label = styled.label`
    font-size: 16px;
    font-weight: 600;
`;
