import { ReactNode } from 'react';
import { useCourseProvider, CourseContext } from './useCourse';

interface ChildrenProps {
    children?: ReactNode;
}

const Course = ({ children }: ChildrenProps) => {
    const { value } = useCourseProvider();

    return <CourseContext.Provider value={value}>{children}</CourseContext.Provider>;
};

export default Course;
