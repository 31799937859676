import styled from 'styled-components';
import { Avatar, Popover, MenuItem as MenuItemCustom, Typography } from '@material-ui/core';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PermDataSettingOutlinedIcon from '@material-ui/icons/PermDataSettingOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';

import CustomVisible from 'components/visible';
import { Button as CustomButton } from 'components/button';

export const Visible = styled(CustomVisible)``;

export const Button = styled(CustomButton)``;

export const Main = styled.div`
    && {
        button {
            width: 100px;
            border-radius: 50px;
            box-shadow: none;
            padding: 6px 10px;
            background-color: #fff;
            background-image: linear-gradient(180deg, #fff, #f1f1f1, #eee);

            span {
                display: flex;
                justify-content: space-between;

                svg {
                    fill: ${(props) => props.theme.palette.primary.dark};

                    &:last-child {
                        margin-right: 5px;
                    }
                }

                .MuiAvatar-root {
                    svg {
                        margin-right: 0;
                    }
                }
            }

            &:hover,
            &:focus {
                background-color: #eee;
                background-image: linear-gradient(180deg, #eee, #e1e1e1, #eee);

                svg {
                    fill: ${(props) => props.theme.palette.primary.dark};
                }
            }
        }
    }
`;

export const IconSettings = styled(SettingsOutlinedIcon)``;

export const PhotoProfile = styled(Avatar)``;

export const Menu = styled(Popover)`
    [class*='MuiPaper-root'] {
        background-image: linear-gradient(180deg, #fff, #f1f1f1, #eee);
        border-radius: 10px;
        margin-top: 10px;
    }
`;

export const InnerMenu = styled.div`
    width: 334px;
    padding: 20px;
`;

export const Divisor = styled.div`
    border-bottom: 1px solid #eee;
    margin: 10px 0;
`;

export const MenuItemText = styled(Typography)`
    &[class*='MuiTypography-h3'] {
        font-size: 20px;
        text-transform: capitalize;
    }
`;

export const MenuItem = styled(MenuItemCustom)`
    &:not(:last-child) {
        margin-bottom: 10px;
    }

    svg {
        margin-right: 10px;
    }
`;

export const IconHome = styled(HomeOutlinedIcon)``;

export const IconPower = styled(PowerSettingsNewIcon)``;

export const IconPermDataSettingOutlined = styled(PermDataSettingOutlinedIcon)``;

export const IconPerson = styled(AccountCircleIcon)``;

export const IconCompany = styled(BusinessIcon)``;

export const IconUsers = styled(PeopleAltOutlinedIcon)``;
