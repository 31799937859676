import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import CustomAvatar from '@material-ui/core/Avatar';

export const ButtonCustom = styled(Button)`
    && {
        width: 100%;
        height: 50px;
        border-radius: 10px;
        padding: 12px 6px;
        font-weight: 700;
        background-color: ${({ theme }: any) => theme.palette.button.default.light[0]};
        background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.default.light})`};
        border: 0;

        &:not(:disabled):hover {
            background-color: ${({ theme }: any) => theme.palette.button.default.dark[0]};
            background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.default.dark})`};
        }

        .MuiButton-label {
            color: ${({ theme }: any) => theme.palette.button.default.main};
        }

        &.MuiButton-textPrimary {
            background-color: ${({ theme }: any) => theme.palette.button.primary.light[0]};
            background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.primary.light})`};

            .MuiButton-label {
                color: ${({ theme }: any) => theme.palette.button.primary.main};
            }

            &:not(:disabled):hover {
                background-color: ${({ theme }: any) => theme.palette.button.primary.dark[0]};
                background-image: ${({ theme }: any) =>
                    `linear-gradient(180deg, ${theme.palette.button.primary.dark})`};
            }
        }

        &.MuiButton-textSecondary {
            background-color: ${({ theme }: any) => theme.palette.button.secondary.light[0]};
            background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.secondary.light})`};

            .MuiButton-label {
                color: ${({ theme }: any) => theme.palette.button.secondary.main};
            }

            &:not(:disabled):hover {
                background-color: ${({ theme }: any) => theme.palette.button.secondary.dark[0]};
                background-image: ${({ theme }: any) =>
                    `linear-gradient(180deg, ${theme.palette.button.secondary.dark})`};
            }
        }
    }
`;

export const Wrapper = styled('div')`
    width: 100%;
    display: flex;
`;

export const Avatar = styled(CustomAvatar)`
    width: 35px;
    height: 35px;
    margin-right: 10px;
    font-size: 0.98rem;
    color: #fff;
    background-color: ${({ theme }: any) => theme.palette.background.paper};
`;

export const Group = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
`;

export const Label = styled('span')`
    display: block;
    font-size: 18px;
    line-height: 1.3;
    text-transform: capitalize;
`;

export const Description = styled('span')`
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    text-transform: capitalize;
`;
