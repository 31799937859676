import React, { memo } from 'react';

import pdf from 'assets/images/pdf.png';
import excel from 'assets/images/excel.png';
import powerpoint from 'assets/images/powerpoint.png';
import word from 'assets/images/word.png';

import * as Component from './styled';

interface PropsAttachment {
    ext: string;
    url: string;
}

const Attachment: React.FC<PropsAttachment> = ({ ext, url }) => {
    if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'svg' || ext === 'gif') {
        return (
            <Component.Wrapper>
                <Component.FileImage href={url} target="_blank">
                    <Component.Image src={url} />
                </Component.FileImage>
            </Component.Wrapper>
        );
    }
    if (ext === 'xlsx' || ext === 'xlsm' || ext === 'xlsb' || ext === 'xltx') {
        return (
            <Component.WrapperCustom>
                <Component.File href={url} target="_blank">
                    <Component.Image src={excel} />
                </Component.File>
            </Component.WrapperCustom>
        );
    }
    if (ext === 'doc' || ext === 'docm' || ext === 'docx') {
        return (
            <Component.WrapperCustom>
                <Component.File href={url} target="_blank">
                    <Component.Image src={word} />
                </Component.File>
            </Component.WrapperCustom>
        );
    }
    if (ext === 'pptx' || ext === 'pptm' || ext === 'ppt') {
        return (
            <Component.WrapperCustom>
                <Component.File href={url} target="_blank">
                    <Component.Image src={powerpoint} />
                </Component.File>
            </Component.WrapperCustom>
        );
    }
    if (ext === 'pdf') {
        return (
            <Component.WrapperCustom>
                <Component.File href={url} target="_blank">
                    <Component.Image src={pdf} />
                </Component.File>
            </Component.WrapperCustom>
        );
    }

    if (ext) {
        return (
            <Component.Wrapper>
                <Component.File href={url} target="_blank">
                    <Component.TextExt>{ext}</Component.TextExt>
                </Component.File>
            </Component.Wrapper>
        );
    }

    return null;
};

export default memo(Attachment);
