import React, { memo } from 'react';

import { PropsSelect } from 'models';
import useFilter from './useFilter';

import * as Component from './styled';

interface PropsFields {
    search: string;
    companies?: PropsSelect[] | [];
    company?: PropsSelect | '';
    classification: string;
    status: string;
}

interface PropsFilter {
    message?: string;
    open: boolean;
    status: 'loading' | 'success' | 'error' | '';
    fields: PropsFields;
    error: PropsFields;
    onClose: () => void;
    onChange: (event: any) => void;
    onChangeCompanyText: (event: any) => void;
    onChangeCompany: (event: any) => void;
    onFilter: () => void;
    onClearFilter: () => void;
}

const Filter: React.FC<PropsFilter> = ({
    message,
    open,
    fields,
    error,
    status,
    onClose,
    onChange,
    onChangeCompanyText,
    onChangeCompany,
    onFilter,
    onClearFilter,
}) => {
    const { optionClassification, optionStatus, translate } = useFilter();

    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        <Component.ModalTitle>{translate('modal.filter.title')}</Component.ModalTitle>
                        <Component.ModalBody>
                            <Component.Form>
                                <Component.FormGroup>
                                    <Component.InputText
                                        type="number"
                                        color="primary"
                                        label={translate('modal.filter.search')}
                                        name="search"
                                        value={fields.search}
                                        onChange={onChange}
                                        error={Boolean(error.search)}
                                    />
                                </Component.FormGroup>
                                <Component.FormGroup>
                                    <Component.InputSearch
                                        color="primary"
                                        freeSolo
                                        size="medium"
                                        label={translate('modal.filter.searchCompany')}
                                        name="company"
                                        noOptionsText={translate('modal.suppliersDetails.form.userNotFound')}
                                        options={fields?.companies ?? []}
                                        value={(fields?.company && fields?.company?.title) ?? ''}
                                        onChange={(_event: any, value: any) => onChangeCompany(value)}
                                        onChangeText={onChangeCompanyText}
                                        error={Boolean(error.company)}
                                    />
                                </Component.FormGroup>
                                <Component.FormGroup>
                                    <Component.InputSelect
                                        color="primary"
                                        label={translate('modal.filter.selectClassification')}
                                        name="classification"
                                        options={optionClassification}
                                        value={fields.classification}
                                        onChange={onChange}
                                        error={Boolean(error.classification)}
                                    />
                                </Component.FormGroup>
                                <Component.FormGroup>
                                    <Component.InputSelect
                                        color="primary"
                                        label={translate('modal.filter.selectStatus')}
                                        name="status"
                                        options={optionStatus}
                                        value={fields.status}
                                        onChange={onChange}
                                        error={Boolean(error.status)}
                                    />
                                </Component.FormGroup>
                                <Component.FormGroup>
                                    <Component.ModalActions>
                                        <Component.Button onClick={onClearFilter} color="primary">
                                            {translate('modal.button.removeFilter')}
                                        </Component.Button>
                                        <Component.Button onClick={onFilter}>
                                            {translate('modal.button.send')}
                                        </Component.Button>
                                    </Component.ModalActions>
                                </Component.FormGroup>
                            </Component.Form>
                        </Component.ModalBody>
                    </>
                );
        }
    };

    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title">
            {modalContent()}
        </Component.Modal>
    );
};

export default memo(Filter);
