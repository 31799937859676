import React, { memo } from 'react';

import useContractDetails from './useContractDetails';

import * as Component from './styled';

interface Props {
    open: boolean;
    status: 'loading' | 'success' | 'error' | '';
    message?: string;
    supplierId: string;
    confirmedEthical: number | null;
    onConfirm: (event: React.ChangeEvent<HTMLFormElement>) => void;
    onGoback: () => void;
    onClose: () => void;
}

const ContractDetails: React.FC<Props> = ({
    open,
    status,
    message,
    supplierId,
    confirmedEthical,
    onConfirm,
    onGoback,
    onClose,
}) => {
    const { confirmedDownload, onOpenFile, translate } = useContractDetails();

    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                            <Component.ModalActions>
                                <Component.Button color="primary" onClick={onGoback}>
                                    {translate('modal.button.goback')}
                                </Component.Button>
                            </Component.ModalActions>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        <Component.ModalTitle>{translate('modal.contractDetails.title')}</Component.ModalTitle>
                        <Component.ModalBody>
                            <Component.Form onSubmit={onConfirm}>
                                <Component.Grid container spacing={3}>
                                    <Component.Grid item xs={12}>
                                        <Component.ModalText>
                                            {translate('modal.contractDetails.description')}
                                        </Component.ModalText>
                                    </Component.Grid>
                                    <Component.Grid item xs={12}>
                                        <Component.Button
                                            className="button__download--contract"
                                            fullWidth
                                            onClick={() => onOpenFile('shortVersion', supplierId, confirmedEthical)}
                                        >
                                            <Component.IconDownload />
                                            {translate('modal.contractDetails.button.shortVersion')}
                                        </Component.Button>
                                    </Component.Grid>
                                    <Component.Grid item xs={12}>
                                        <Component.Button
                                            className="button__download--contract"
                                            fullWidth
                                            onClick={() => onOpenFile('completeVersion', supplierId, confirmedEthical)}
                                        >
                                            <Component.IconDownload />
                                            {translate('modal.contractDetails.button.completeVersion')}
                                        </Component.Button>
                                    </Component.Grid>
                                </Component.Grid>
                                {confirmedEthical === null && confirmedDownload && (
                                    <Component.ModalActions>
                                        <Component.ModalText>Deseja aceitar os termos de uso?</Component.ModalText>
                                        <Component.Button onClick={onClose} color="primary">
                                            {translate('modal.contractDetails.button.doNotAccept')}
                                        </Component.Button>
                                        <Component.Button type="submit">
                                            {translate('modal.contractDetails.button.toAccept')}
                                        </Component.Button>
                                    </Component.ModalActions>
                                )}
                            </Component.Form>
                        </Component.ModalBody>
                    </>
                );
        }
    };

    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title" scroll="body">
            {modalContent()}
        </Component.Modal>
    );
};

export default memo(ContractDetails);
