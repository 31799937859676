import styled from 'styled-components';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CustomMeetingRoomIcon from '@material-ui/icons/MeetingRoom';

import { Button } from 'components/button';

export { Button };

export const MeetingRoomIcon = styled(CustomMeetingRoomIcon)`
    margin-right: 10px;
`;

export const Modal = styled(Dialog)`
    .MuiPaper-root {
        min-width: 400px;
        min-height: 150px;
        background-color: #fff;
        background-image: linear-gradient(180deg, #fff, #f1f1f1, #eee);
        border-radius: 10px;

        @media (max-width: 768px) {
            min-width: auto;
        }
    }
`;

export const ModalTitle = styled(DialogTitle)`
    text-align: center;
    color: #000;

    .MuiTypography-root {
        font-weight: 600;
    }
`;

export const ModalText = styled(DialogContentText)`
    text-align: center;
    color: #000;
`;

export const ModalBody = styled(DialogContent)``;

export const UserInfo = styled(DialogContentText)`
    text-align: center;
    color: #000;
    font-size: 16px;

    &:not(:last-child) {
        margin-bottom: 5px;
    }
`;

export const Strong = styled.strong``;

export const ModalActions = styled(DialogActions)`
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
        padding: 8px 0;
    }

    button {
        padding: 12px 25px;
        margin: 0 10px 15px;

        @media (max-width: 768px) {
            margin: 0 0 15px;
        }

        .MuiButton-label {
            color: ${({ theme }: any) => theme.palette.button.default.main};
        }

        &.MuiButton-containedPrimary {
            background-color: ${({ theme }: any) => theme.palette.button.tertiary.light[0]};
            background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.tertiary.light})`};

            .MuiButton-label {
                color: ${({ theme }: any) => theme.palette.button.tertiary.main};
            }

            &:not(:disabled):hover {
                background-color: ${({ theme }: any) => theme.palette.button.tertiary.dark[0]};
                background-image: ${({ theme }: any) =>
                    `linear-gradient(180deg, ${theme.palette.button.tertiary.dark})`};
            }
        }

        &.MuiButton-containedSecondary {
            background-color: ${({ theme }: any) => theme.palette.button.secondary.light[0]};
            background-image: ${({ theme }: any) => `linear-gradient(180deg, ${theme.palette.button.secondary.light})`};

            .MuiButton-label {
                color: ${({ theme }: any) => theme.palette.button.secondary.main};
            }

            &:not(:disabled):hover {
                background-color: ${({ theme }: any) => theme.palette.button.secondary.dark[0]};
                background-image: ${({ theme }: any) =>
                    `linear-gradient(180deg, ${theme.palette.button.secondary.dark})`};
            }
        }
    }
`;

export const ModalStatus = styled.div`
    height: 100%;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    margin: auto;
`;

export const ModalGroupMessage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ModalTextSuccess = styled.h3`
    font-size: 16px;
    font-weight: 400;
    text-align: center;
`;

export const Loading = styled(CircularProgress).attrs({
    size: 45,
})`
    color: #2b6838;
`;

export const IconSuccess = styled(CheckCircleOutlineIcon)`
    font-size: 60px;
    color: #2b6838;
    margin-bottom: 10px;
`;

export const IconError = styled(ErrorOutlineIcon)`
    font-size: 60px;
    color: #bd3336;
    margin-bottom: 10px;
`;

export const Filename = styled.div`
    text-transform: uppercase;
    color: #bd3336;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
`;

export const GroupGenaratorUser = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
        max-width: 280px;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
`;
