import styled from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Button from '@material-ui/core/Button';

export const ButtonOrder = styled(Button)`
    && {
        span {
            color: #fff;
            font-weight: 600;
        }
    }
`;

export const Group = styled.div<{ order: 'asc' | 'desc' | undefined }>`
    && {
        width: 12px;
        height: 20px;
        margin-right: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;

        svg {
            color: #777;

            &:first-child {
                color: ${(props) => (props.order === 'asc' ? '#fff' : '#777')};
            }
            &:last-child {
                color: ${(props) => (props.order === 'desc' ? '#fff' : '#777')};
            }
        }
    }
`;

export const IconUp = styled(ArrowDropUpIcon)`
    font-size: 22px;
    position: absolute;
    top: 0;
    left: 0;
`;

export const IconDown = styled(ArrowDropDownIcon)`
    font-size: 22px;
    position: absolute;
    bottom: 0;
    left: 0;
`;
