import React, { memo, ReactNode } from 'react';

interface Props {
    children: ReactNode;
    condition: boolean;
}

const Visible: React.FC<Props> = ({ condition, children }) => {
    if (condition) {
        return <>{children}</>;
    }
    return null;
};

export default memo(Visible);
