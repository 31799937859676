import React, { memo, ReactNode } from 'react';

import useLayout from './useLayout';

import * as Component from './styled';

interface Props {
    children: ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
    const { themeSelected, fullscreen, profile, openDrawer, location, PageName } = useLayout();

    if (PageName(location.pathname) === 'registration-lgpd-quiz') {
        return (
            <Component.Main myTheme={themeSelected} fullscreen={false}>
                <Component.ContainerHome>{children}</Component.ContainerHome>
            </Component.Main>
        );
    }

    if (profile?.firstAccess === 1 && PageName(location.pathname) !== process.env.REACT_APP_BASE_URL) {
        return (
            <Component.Main myTheme={themeSelected} fullscreen={false}>
                <Component.Navbar />
                <Component.Container>{children}</Component.Container>
            </Component.Main>
        );
    }

    if (PageName(location.pathname) !== process.env.REACT_APP_BASE_URL) {
        return (
            <Component.Main myTheme={themeSelected} fullscreen={fullscreen}>
                <Component.Navbar />
                <Component.Drawer />
                <Component.Container className={!openDrawer ? 'container__main container__full' : 'container__main'}>
                    {children}
                </Component.Container>
            </Component.Main>
        );
    }

    return (
        <Component.Main myTheme={themeSelected} fullscreen={false}>
            <Component.ContainerHome>{children}</Component.ContainerHome>
        </Component.Main>
    );
};

export default memo(Layout);
