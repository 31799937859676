import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import * as Component from './styled';

type Error = {
    name?: string;
    fantasy_name?: string;
    email?: string;
    document?: string;
    address?: string;
    number?: string;
    city?: string;
    state?: string;
    postal_code?: string;
};

interface Props {
    open: boolean;
    status: 'loading' | 'success' | 'error' | '';
    message?: string;
    fields: any;
    error: Error;
    onChange: (event: any) => void;
    onChangeMultiple: (event: any) => void;
    onConfirm: (event: React.ChangeEvent<HTMLFormElement>) => void;
    onGoback: () => void;
    onClose: () => void;
}

const ClientDetails: React.FC<Props> = ({
    open,
    status,
    message,
    fields,
    error,
    onChange,
    onChangeMultiple,
    onConfirm,
    onGoback,
    onClose,
}) => {
    const { t: translate } = useTranslation();

    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                            <Component.ModalActions>
                                <Component.Button color="primary" onClick={onGoback}>
                                    {translate('modal.button.goback')}
                                </Component.Button>
                            </Component.ModalActions>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        <Component.ModalTitle>
                            {fields.id !== ''
                                ? translate('modal.clientDetails.title.update')
                                : translate('modal.clientDetails.title.add')}
                        </Component.ModalTitle>
                        <Component.ModalBody>
                            <Component.Form onSubmit={onConfirm}>
                                <Component.Grid container spacing={3}>
                                    <Component.Grid item xs={12}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.clientDetails.form.name')}
                                            name="name"
                                            value={fields.name}
                                            onChange={onChange}
                                            error={Boolean(error.name)}
                                            helperText={error.name}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.clientDetails.form.fantasy_name')}
                                            name="fantasy_name"
                                            value={fields.fantasy_name}
                                            onChange={onChange}
                                            error={Boolean(error.fantasy_name)}
                                            helperText={error.fantasy_name}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.clientDetails.form.email')}
                                            name="email"
                                            value={fields.email}
                                            onChange={onChange}
                                            error={Boolean(error.email)}
                                            helperText={error.email}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.clientDetails.form.document')}
                                            name="document"
                                            value={fields.document}
                                            onChange={onChange}
                                            error={Boolean(error.document)}
                                            helperText={error.document}
                                            mask="cnpj"
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.clientDetails.form.address')}
                                            name="address"
                                            value={fields.address}
                                            onChange={onChange}
                                            error={Boolean(error.address)}
                                            helperText={error.address}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12} md={4}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.clientDetails.form.number')}
                                            name="number"
                                            value={fields.number}
                                            onChange={onChange}
                                            error={Boolean(error.number)}
                                            helperText={error.number}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12} md={8}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.clientDetails.form.city')}
                                            name="city"
                                            value={fields.city}
                                            onChange={onChange}
                                            error={Boolean(error.city)}
                                            helperText={error.city}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12} md={8}>
                                        <Component.InputSelect
                                            color="primary"
                                            label={translate('modal.clientDetails.form.state')}
                                            name="state"
                                            options={fields.states}
                                            value={fields.state}
                                            onChange={onChange}
                                            error={Boolean(error.state)}
                                            helperText={error.state}
                                        />
                                    </Component.Grid>
                                    <Component.Grid item xs={12} md={4}>
                                        <Component.InputText
                                            color="primary"
                                            label={translate('modal.clientDetails.form.postal_code')}
                                            name="postal_code"
                                            value={fields.postal_code}
                                            onChange={onChange}
                                            error={Boolean(error.postal_code)}
                                            helperText={error.postal_code}
                                            mask="cep"
                                        />
                                    </Component.Grid>

                                    <Component.Grid item xs={12}>
                                        <Component.InputSelectMultiple
                                            color="primary"
                                            name="modules"
                                            label={translate('modal.userDetails.form.modules')}
                                            options={fields.optionsModules}
                                            value={fields.modules}
                                            onChange={onChangeMultiple}
                                        />
                                    </Component.Grid>
                                </Component.Grid>
                                <Component.ModalActions>
                                    <Component.Button onClick={onClose} color="primary">
                                        {translate('modal.button.cancel')}
                                    </Component.Button>
                                    <Component.Button type="submit">
                                        {fields.id !== ''
                                            ? translate('modal.button.update')
                                            : translate('modal.button.add')}
                                    </Component.Button>
                                </Component.ModalActions>
                            </Component.Form>
                        </Component.ModalBody>
                    </>
                );
        }
    };

    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title" scroll="body">
            {modalContent()}
        </Component.Modal>
    );
};

export default memo(ClientDetails);
