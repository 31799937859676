import styled from 'styled-components';

import CustomNavbar from 'components/navbar/Navbar';
import CustomDrawer from 'components/drawer';

import Bg from 'assets/images/bg_login.png';

interface MainProps {
    myTheme: string;
    fullscreen: boolean;
}

function imageBg(props: any) {
    if (props.myTheme === 'default') {
        return `
    background-image: ${`url(${Bg})`};
    background-size: cover;
    background-position: center;

    &:before {
        width: 100%;
        height: 100%;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
    }
`;
    }
    return `
                background-color: ${props.theme.palette.background.default};
            `;
}

export const Navbar = styled(CustomNavbar)``;

export const Drawer = styled(CustomDrawer)``;

export const Main = styled.main.attrs((props: any) => ({
    className: props.fullscreen === true ? 'main__fullscreen' : '',
}))<MainProps>`
    width: 100%;
    height: 100%;
    display: flex;
    ${(props) => imageBg(props)};

    &.main__fullscreen {
        .navbar__main,
        .drawer__main {
            visibility: hidden;
            opacity: 0;
        }
        .container__main {
            width: 100%;
            height: 100%;
            margin: 0;

            .content__fullscreen {
                width: 100%;
                height: 100%;
                position: fixed;
                top: 0;
                left: 0;
                margin-top: 0;
                z-index: 2000;

                .container__classroom--ead {
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: 10;

                    &.container__classroom--ead-videodynamic {
                        margin-top: 0;
                        video,
                        article {
                            height: calc(100vh - 60px);
                        }
                    }
                }
                .container__classroom--ead-full {
                    width: 100%;
                    height: 100vh;
                    margin-top: 0;
                    top: 0;
                    left: 0;
                    z-index: 10;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    & > div {
                        margin: auto;

                        @media (max-width: 991px) {
                            width: 100%;
                        }
                    }

                    .footer__action--fullscreen {
                        width: 100%;
                    }
                }

                .content__classroom--ead {
                    visibility: hidden;
                    opacity: 0;
                }
            }
            .container__full {
                .content__fullscreen {
                    width: 100%;
                    height: 100%;
                    position: fixed;
                    top: 0;
                    left: 0;
                    z-index: 2000;

                    .container__classroom--ead {
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        z-index: 10;
                    }
                    .container__classroom--ead-full {
                        width: 100%;
                        height: 100vh;
                        top: 0;
                        left: 0;
                        z-index: 10;
                    }

                    .content__classroom--ead {
                        visibility: hidden;
                        opacity: 0;
                    }
                }
            }
            .container__countdown--bar {
                width: auto;
                padding: 5px;
                background-color: rgba(228, 120, 22, 0.75);
                border-top-left-radius: 0;
                top: 0;
                left: 0;
                z-index: 2005;
            }
        }
    }
`;

export const Container = styled.div`
    width: calc(100% - 260px);
    height: calc(100vh - 80px);
    transition: all 0.3s ease-in-out;
    position: relative;
    flex-grow: 1;
    background-color: ${(props) => props.theme.palette.background.paper};
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 80px;
    border-top-left-radius: 10px;

    @media (max-width: 768px) {
        padding-bottom: 100px;
        border-top-left-radius: 0;
    }

    .container__classroom--ead {
        width: calc(100% - 740px);
        left: 260px;

        @media (max-width: 1366px) {
            width: calc(100% - 640px);
        }

        @media (max-width: 768px) {
            width: 100%;
            left: 0;
        }
    }

    &.container__full {
        margin-left: -260px;

        @media (max-width: 768px) {
            margin-left: 0;
        }

        .container__classroom--ead {
            width: calc(100% - 480px);
            left: 0;

            @media (max-width: 1366px) {
                width: calc(100% - 380px);
            }

            @media (max-width: 768px) {
                width: 100%;
                left: 0;
            }
        }
    }
`;

export const ContainerHome = styled.div`
    width: calc(100% - 260px);
    height: 100vh;
    transition: all 0.3s ease-in-out;
    position: relative;
    flex-grow: 1;
    background-color: ${(props) => props.theme.palette.background.paper};
    padding: 0;
    overflow-y: auto;
    margin-top: 0;
    transition: opacity 0.3s ease-in-out;
`;
