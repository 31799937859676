import styled, { keyframes } from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import CustomFullscreenIcon from '@material-ui/icons/Fullscreen';
import CustomFullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import CustomVolumeUpIcon from '@material-ui/icons/VolumeUp';
import CustomVolumeDownIcon from '@material-ui/icons/VolumeDown';
import CustomVolumeOffIcon from '@material-ui/icons/VolumeOff';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CustomSlider from '@material-ui/core/Slider';

import Visible from 'components/visible';
import { Button } from 'components/button';
import Marker from 'components/marker';

export { Paper, Popper, Grow, ClickAwayListener, IconButton, Visible, Button, Marker };

interface PropsMarkerSlider {
    left: string;
}

const AnimateDescription = keyframes`
  0% {
    letter-spacing: 1px;
    transform: translateX(0px);
  }

  40% {
    letter-spacing: 2px;
    transform: translateX(26px);
  }

  80% {
    letter-spacing: 1px;
    transform: translateX(32px);
  }

  90% {
    letter-spacing: 2px;
    transform: translateX(0px);
  }

  100% {
    letter-spacing: 1px;
    transform: translateX(0px);
  }
`;

const AnimateProgress1 = keyframes`
  0% {
    width: 16px;
    transform: translateX(0px);
  }

  40% {
    width: 100%;
    transform: translateX(0px);
  }

  80% {
    width: 16px;
    transform: translateX(64px);
  }

  90% {
    width: 100%;
    transform: translateX(0px);
  }

  100% {
    width: 16px;
    transform: translateX(0px);
  }
`;

const AnimateProgress2 = keyframes`
  0% {
    transform: translateX(0px);
    width: 16px;
  }

  40% {
    transform: translateX(0%);
    width: 80%;
  }

  80% {
    width: 100%;
    transform: translateX(0px);
  }

  90% {
    width: 80%;
    transform: translateX(15px);
  }
  100% {
    transform: translateX(0px);
    width: 16px;
  }
`;

const videoAnimation = keyframes`
 0% { opacity: 0; }
 100% { opacity: 1; }
`;

export const Container = styled.div`
    width: 100%;
    max-width: 767px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    animation-name: ${videoAnimation};
    animation-duration: 1s;
    animation-iteration-count: one;
`;

export const WrapLoading = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ContentLoading = styled.div`
    width: 80px;
    height: 50px;
    position: relative;
`;

export const DescriptionLoading = styled.p`
    top: 0;
    padding: 0;
    margin: 0;
    color: ${(props) => props.theme.palette.loading.light};
    animation: ${AnimateDescription} 3.5s ease both infinite;
    font-size: 12px;
    letter-spacing: 1px;
`;

export const ProgressLoading = styled.span`
    background-color: ${(props) => props.theme.palette.loading.dark};
    border-radius: 50px;
    display: block;
    height: 16px;
    width: 16px;
    bottom: 0;
    position: absolute;
    transform: translateX(64px);
    animation: ${AnimateProgress1} 3.5s ease both infinite;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-color: ${(props) => props.theme.palette.loading.light};
        border-radius: inherit;
        animation: ${AnimateProgress2} 3.5s ease both infinite;
    }
`;

export const Wrap = styled.div`
    width: 700px;
    position: relative;
    overflow: hidden;
    background-color: #000;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 60px;

    video {
        width: 100%;
    }
`;

export const Video = styled.video`
    border: 1px solid #000;
    transition: all 0.3s ease-in-out;
`;

export const Controls = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 0 20px;
    background-color: ${(props) => props.theme.palette.main};
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;
`;

export const WrapSlider = styled.div`
    width: calc(100% - 260px);
    height: 8px;
    background-color: ${(props) => props.theme.palette.secondary};
    position: relative;
    display: flex;
    transition: all 0.3s ease;
    border-radius: 2px;
`;

export const IconPlay = styled(PlayArrowIcon)`
    color: #fff;
    font-size: 25px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

export const IconPause = styled(PauseIcon)`
    color: #fff;
    font-size: 25px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

export const Slider = styled(CustomSlider)`
    && {
        padding: 3px 0;

        .MuiSlider-rail {
            background: transparent;
            height: 4px;
        }
        .MuiSlider-track {
            background: transparent;
            height: 4px;
        }
        .MuiSlider-thumb {
            width: 12px;
            height: 12px;
            background: rgba(255, 255, 255, 1);
            position: relative;
            z-index: 3;
        }

        &:hover {
            .MuiSlider-thumb {
                opacity: 1;
            }
        }
    }
`;

export const SliderVertical = styled(CustomSlider)`
    && {
        width: 4px;
        height: 45px;
        padding: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;

        .MuiSlider-root {
            padding: 0 3px;
        }
        .MuiSlider-rail {
            background: transparent;
        }
        .MuiSlider-track {
            background: transparent;
        }
        .MuiSlider-thumb {
            background: rgba(255, 255, 255, 1);
        }

        &:hover {
            .MuiSlider-thumb {
                opacity: 1;
            }
        }
    }
`;

export const FullscreenIcon = styled(CustomFullscreenIcon)`
    color: #fff;
    font-size: 25px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

export const FullscreenExitIcon = styled(CustomFullscreenExitIcon)`
    color: #fff;
    font-size: 25px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

export const Volumes = styled.div`
    width: 120px;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        width: 100px;
    }
`;

export const VolumeUpIcon = styled(CustomVolumeUpIcon)`
    color: #fff;
    font-size: 25px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

export const VolumeDownIcon = styled(CustomVolumeDownIcon)`
    color: #fff;
    font-size: 25px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

export const VolumeOffIcon = styled(CustomVolumeOffIcon)`
    color: #fff;
    font-size: 25px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

export const Footer = styled.footer<{ color?: string }>`
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;

    @media (max-width: 768px) {
        justify-content: center;
    }

    button {
        text-transform: uppercase;
        color: ${(props) => props.theme.palette.getContrastText(props.color)};
        margin-right: 30px;

        &:not(:last-child) {
            margin-right: 10px;
        }

        .MuiButton-label {
            font-size: 12px;
            font-weight: 400;
            color: ${(props) => props.theme.palette.getContrastText(props.color)};

            svg {
                color: ${(props) => props.theme.palette.getContrastText(props.color)};
            }
        }

        &.button__footer--ead {
            background-color: #111;
            transition: all 0.3s ease-in-out;

            svg {
                color: ${(props) => props.theme.palette.getContrastText(props.color)};
            }

            &:hover {
                background-color: #222;
            }
        }
    }
`;

export const IconNextFooter = styled(ArrowRightAltIcon)`
    font-size: 18px;
    margin-right: 5px;
`;

export const FullscreenFooterIcon = styled(CustomFullscreenIcon)`
    font-size: 25px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

export const FullscreenExitFooterIcon = styled(CustomFullscreenExitIcon)`
    font-size: 25px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

export const Time = styled.span`
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    padding-left: 20px;
    display: block;
`;

export const MarkerSlider = styled.div<PropsMarkerSlider>`
    width: 8px;
    height: 8px;
    background-color: #555;
    position: absolute;
    top: 0;
    left: ${(props) => props.left}%;
    z-index: 2;

    &:hover {
        .menu--item-options {
            visibility: visible;
            opacity: 1;
        }
    }
`;

export const MenuOptions = styled.div.attrs({
    className: 'menu--item-options',
})`
    background-color: ${(props) => props.theme.palette.grey[900]};
    border-bottom: 3px solid ${(props) => props.theme.palette.grey[900]};
    position: absolute;
    bottom: -33px;
    left: 50%;
    transition: opacity 0.3s ease;
    transform: translate(-50%, -50%);
    visibility: hidden;
    opacity: 0;
    z-index: 1000;

    &:before {
        width: 0;
        height: 0;
        content: '';
        position: absolute;
        bottom: -25px;
        left: 50%;
        transform: translateX(-50%);
        border-width: 15px;
        border-style: solid;
        border-color: transparent;
        border-top-color: ${(props) => props.theme.palette.grey[900]};
        z-index: -1;
    }

    &:hover {
        visibility: visible;
        opacity: 1;
    }
`;

export const ItemHeader = styled.div`
    width: 100%;
    padding: 5px 10px;
    text-align: center;
    color: #fff;
    font-size: 0.8rem;
    font-weight: 600;
    background-color: ${(props) => props.theme.palette.main};
    margin-bottom: 5px;
    cursor: default;
`;

export const ItemButton = styled(IconButton)`
    && {
        width: 100%;
        border-radius: 0;
        font-size: 0.9rem;
        font-weight: 600;
        padding: 5px 10px;
        border-radius: 0;
        color: #fff;
        background-color: ${(props) => props.theme.palette.grey[900]};
        transition: all 0.3s ease-in-out;

        &:hover {
            color: #999;
        }

        &:last-child {
            margin-bottom: 5px;
        }
    }
`;

export const WrapVolume = styled.div`
    position: relative;

    &:hover {
        button {
            opacity: 0.3;
        }
        span {
            opacity: 1;
        }
    }
`;
