import { useLocation } from 'react-router-dom';

import { useAuth } from 'hooks/auth';
import { useTheme } from 'hooks/theme';
import { useDrawer } from 'hooks/drawer';

const PageName = (value: string) => {
    const params = value.split('/')[1];

    if (params === '' || params === 'registration-supplier') {
        return '/';
    }

    return params;
};

export default function useLayout() {
    const { themeSelected, fullscreen } = useTheme();
    const { profile } = useAuth();
    const { openDrawer } = useDrawer();
    const location = useLocation();

    return {
        themeSelected,
        fullscreen,
        profile,
        openDrawer,
        location,
        PageName,
    };
}
