import styled, { keyframes } from 'styled-components';
import { AppBar, Toolbar, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import CustomMenuSettings from 'components/menuSettings';
import CustomCourseProgress from 'components/courseProgress';
import CustomVisible from 'components/visible';
import { InputSelect as CustomSelect } from 'components/form';

import LogoImg from 'assets/images/logo-quality.svg';
import CustomContentHeader from './customContent';

interface PropsNavbar {
    open: boolean;
}

export const Visible = styled(CustomVisible)``;

export const InputSelect = styled(CustomSelect)``;

export const MenuSettings = styled(CustomMenuSettings)``;

export const CourseProgress = styled(CustomCourseProgress)``;

export const CustomContent = styled(CustomContentHeader)``;

export const WrapInputSelect = styled.div`
    width: 100%;
    max-width: 440px;
    margin-right: 20px;

    [class*='MuiFormControl-root-'] {
        & > [class*='MuiInputBase-root-'] {
            & > fieldset,
            & > fieldset:hover {
                border: 2px solid ${(props) => props.theme.palette.primary.light};
                border-radius: 15px;
            }
            & > fieldset,
            & > fieldset:hover {
                input {
                    color: ${(props) => props.theme.palette.primary.light};
                }
            }
        }
    }
`;

export const Navbar = styled(AppBar).attrs({
    className: 'navbar__main',
    position: 'fixed',
})<PropsNavbar>`
    box-shadow: none;
    background: transparent;

    &:before {
        width: ${(props) => (props.open ? `calc(100% - 260px)` : '100%')};
        height: 100%;
        content: '';
        transition: all 0.3s ease-in-out;
        position: absolute;
        top: 0;
        right: 0;
    }

    & > div {
        padding: 15px 24px;
    }
`;

export const ToolBar = styled(Toolbar)``;

export const GroupLogo = styled.div`
    width: 230px;
    height: 100%;
    margin-right: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
        width: 100%;
        margin-right: 0;
    }
`;

export const NameProject = styled.h3`
    width: 100%;
    max-width: 330px;
    height: 100%;
    font-size: 18px;
    font-size: 600;
    color: #fff;
    transition: all 0.3s ease-in-out;
    animation-name: ${(props: any) => (!props.show ? FadeAnimation : 'none')};
    animation-duration: 2s;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const GroupOptions = styled.div`
    width: calc(100% - 560px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 16px;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const Logo = styled.img.attrs({
    src: LogoImg,
})`
    max-width: 140px;
    height: auto;

    @media (max-width: 768px) {
        max-width: 200px;
    }

    @media (max-width: 340px) {
        max-width: 180px;
    }
`;

export const ButtonOpen = styled(IconButton)`
    [class*='MuiSvgIcon-root'] {
        fill: ${(props) => props.theme.palette.primary.main};
    }

    &:hover {
        background-color: ${(props) => props.theme.palette.primary.dark};

        [class*='MuiSvgIcon-root'] {
            fill: ${(props) => props.theme.palette.primary.light};
        }
    }
`;

export const IconMenuOpen = styled(MenuIcon)``;

export const IconMenuClose = styled(CloseIcon)``;

const FadeAnimation = keyframes`
 0% { opacity: 0; visibility: hidden;}
 100% { opacity: 1; visibility: visible;}
`;
