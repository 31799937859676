import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import * as Component from './styled';

interface Props {
    inner?: boolean;
}

const Loading: React.FC<Props> = ({ inner = false }) => {
    const { t: translate } = useTranslation();
    return (
        <Component.Container inner={inner}>
            <Component.Content>
                <Component.Description>{translate('modal.loading')}</Component.Description>
                <Component.Progress />
            </Component.Content>
        </Component.Container>
    );
};

export default memo(Loading);
