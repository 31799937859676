import styled from 'styled-components';
import CustomFullscreenIcon from '@material-ui/icons/Fullscreen';
import CustomFullscreenExitIcon from '@material-ui/icons/FullscreenExit';

import { Button } from 'components/button';

export const ButtonFullscreen = styled(Button).attrs({ className: 'button__fullscreen--ead' })`
    && {
        width: auto;
        background: transparent;
        box-shadow: none;
        margin-bottom: 0;
        position: absolute;
        right: 30px;
        transition: all 0.3s ease-in-out;

        @media (max-width: 768px) {
            position: static;
            margin-right: 10px;
        }

        svg {
            margin-left: 5px;
            transition: all 0.3s ease-in-out;
        }

        &:hover {
            background: transparent;
            box-shadow: none;
            opacity: 0.9;

            svg {
                opacity: 0.9;
            }
        }
    }
`;

export const FullscreenIcon = styled(CustomFullscreenIcon)`
    font-size: 25px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

export const FullscreenExitIcon = styled(CustomFullscreenExitIcon)`
    font-size: 25px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;
