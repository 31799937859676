import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import * as Component from './styled';

interface PropsConfirm {
    title?: string;
    text?: string;
    message?: string;
    attachment?: string | null;
    open: boolean;
    status: 'loading' | 'success' | 'error' | '';
    onClose: () => void;
    onConfirm: (event: React.ChangeEvent<HTMLFormElement>) => void;
}

const Confirm: React.FC<PropsConfirm> = ({ title, text, attachment, message, open, status, onClose, onConfirm }) => {
    const { t: translate } = useTranslation();

    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        {title && <Component.ModalTitle>{title}</Component.ModalTitle>}
                        <Component.ModalBody>
                            {text && <Component.ModalText>{text}</Component.ModalText>}
                            {attachment && <Component.Filename>{attachment}</Component.Filename>}
                        </Component.ModalBody>
                        <Component.ModalActions>
                            <Component.Button onClick={onClose} color="primary">
                                {translate('modal.button.cancel')}
                            </Component.Button>
                            <Component.Button onClick={onConfirm}>{translate('modal.button.confirm')}</Component.Button>
                        </Component.ModalActions>
                    </>
                );
        }
    };

    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title">
            {modalContent()}
        </Component.Modal>
    );
};

export default memo(Confirm);
