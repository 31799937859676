import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import api from 'service/api';

import { useNotification } from 'hooks/notification';

import shortVersion from 'assets/terms/short_version.pdf';
import completeVersion from 'assets/terms/complete_version.pdf';

export default function useContractDetails() {
    const { t: translate } = useTranslation();
    const { openNotification } = useNotification();
    const [confirmedDownload, setConfirmedDownload] = useState(false);

    const onOpenFile = useCallback(
        async (version: string, supplierId: string, confirmedEthical: number | null) => {
            if (confirmedEthical !== null) {
                window.open(version === 'shortVersion' ? shortVersion : completeVersion, '_blank');
                return;
            }

            try {
                const {
                    data: { success, message },
                } = await api.put(`/diligence/supplier/confirmed_download_ethical_code`, {
                    supplier_id: supplierId,
                });

                if (success) {
                    openNotification(translate(`validation.${message}`), 'success');
                    setConfirmedDownload(true);
                    window.open(version === 'shortVersion' ? shortVersion : completeVersion, '_blank');
                }
            } catch (error: any) {
                const { data } = error;
                openNotification(translate(`validation.${data.error}`), 'error');
            }
        },
        [openNotification, translate],
    );

    return {
        confirmedDownload,
        onOpenFile,
        translate,
    };
}
