import React, { Suspense, lazy } from 'react';
import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';

import { setPrefix, linkRedirectPage } from 'utils';

import Loading from 'components/loading';

const Layout = lazy(() => import('components/layout'));

const Home = lazy(() => import('pages/home'));

const RegistrationSupplier = lazy(() => import('pages/registrationSupplier'));

// Rotas do módulo de Complaint
const Complaints = lazy(() => import('pages/modules/complaints/home'));
const Details = lazy(() => import('pages/modules/complaints/details'));

// Rotas do módulo de Due Diligencia
const Suppliers = lazy(() => import('pages/modules/dueDiligence/home'));
const Questionnaires = lazy(() => import('pages/modules/dueDiligence/questionnaires'));
const Quiz = lazy(() => import('pages/modules/dueDiligence/quiz'));
const Questions = lazy(() => import('pages/modules/dueDiligence/questions'));

// Rotas do módulo de LGPD
const Lgpd = lazy(() => import('pages/modules/lgpd/home'));
const QuestionnairesLgpd = lazy(() => import('pages/modules/lgpd/questionnaires'));
const QuizLgpd = lazy(() => import('pages/modules/lgpd/quiz'));

// Rotas do módulo de EAD
const Ead = lazy(() => import('pages/modules/ead/home'));
const Classes = lazy(() => import('pages/modules/ead/classroom'));
const MyCourses = lazy(() => import('pages/modules/ead/myCourses'));

// Rotas do módulo de Syndication
const Syndication = lazy(() => import('pages/modules/syndication'));

// Rotas das páginas compartilhadas do sistema
const Categories = lazy(() => import('pages/categories'));
const Profile = lazy(() => import('pages/profile'));
const Page404 = lazy(() => import('pages/404'));

// Rotas publicas
const ExternalQuiz = lazy(() => import('pages/modules/lgpd/externalQuiz'));

type Modules = {
    id: string | number;
    name: string;
};

interface Props {
    modules: Modules[] | [];
}

const Customer: React.FC<Props> = ({ modules }) => (
    <Suspense fallback={<Loading />}>
        <Router>
            <Layout>
                <Routes>
                    {modules?.length === 0 && (
                        <Route path="/" element={<Navigate to={setPrefix('profile')} replace />} />
                    )}
                    {modules?.length === 1 && (
                        <Route path="/" element={<Navigate to={linkRedirectPage(modules[0].id as number)} replace />} />
                    )}
                    {modules?.length > 1 && <Route path={setPrefix('/')} element={<Home />} />}
                    <Route
                        path={setPrefix('/registration-supplier/:uuid/:user_id')}
                        element={<RegistrationSupplier />}
                    />
                    {modules.map((item) => {
                        return (
                            <React.Fragment key={item.id}>
                                {item.name === 'Compliance' && (
                                    <>
                                        <Route path={setPrefix('/complaints')} element={<Complaints />} />
                                        <Route path={setPrefix('/complaints/details/:id')} element={<Details />} />
                                    </>
                                )}
                                {item.name === 'Due Diligence' && (
                                    <>
                                        <Route path={setPrefix('/due-diligence')} element={<Suppliers />} />
                                        <Route
                                            path={setPrefix('/due-diligence/questionnaires/:supplier_id')}
                                            element={<Questionnaires />}
                                        />
                                        <Route
                                            path={setPrefix('/due-diligence/questionnaires/:supplier_id/quiz/:quiz_id')}
                                            element={<Quiz />}
                                        />
                                    </>
                                )}
                                {item.name === 'LGPD' && (
                                    <>
                                        <Route path={setPrefix('/lgpd')} element={<Lgpd />} />
                                        <Route
                                            path={setPrefix('/lgpd/questionnaires/:supplier_id')}
                                            element={<QuestionnairesLgpd />}
                                        />
                                        <Route
                                            path={setPrefix('/lgpd/questionnaires/:supplier_id/quiz/:quiz_id')}
                                            element={<QuizLgpd />}
                                        />
                                    </>
                                )}
                                {item.name === 'EAD' && (
                                    <>
                                        <Route path={setPrefix('/ead')} element={<Ead />} />
                                        <Route path={setPrefix('/ead/course/view/:course_id')} element={<Classes />} />
                                        <Route
                                            path={setPrefix('/ead/course/view/:course_id/:module_id/:lesson_id')}
                                            element={<Classes />}
                                        />
                                        <Route path={setPrefix('/ead/category/:category_id')} element={<Ead />} />
                                        <Route path={setPrefix('/ead/my-courses')} element={<MyCourses />} />
                                    </>
                                )}
                                {item.name === 'Syndication' && (
                                    <Route path={setPrefix('/syndication')} element={<Syndication />} />
                                )}
                            </React.Fragment>
                        );
                    })}
                    <Route path={setPrefix('/:module_name/categories')} element={<Categories />} />
                    <Route path={setPrefix('/profile')} element={<Profile />} />
                    <Route path={setPrefix('/registration-lgpd-quiz/:user_id/:uuid')} element={<ExternalQuiz />} />
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </Layout>
        </Router>
    </Suspense>
);

export default Customer;
