import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CancelIcon from '@material-ui/icons/Cancel';

import Img from 'components/image';
import { Button } from 'components/button';

export { Button };

export const Container = styled.div.attrs({ className: 'container__attachments' })`
    display: flex;
    position: relative;
`;

export const Wrapper = styled(IconButton)`
    && {
        display: flex;
        background: #fff;
        padding: 0;
        margin: 0;
    }
`;

export const Image = styled(Img)`
    max-width: 200px;
    height: auto;
`;

export const FileImage = styled.a`
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const File = styled.a`
    min-width: 200px;
    max-width: 200px;
    height: 90px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const IconFile = styled(FileCopyIcon)`
    color: #ccc;
    font-size: 40px;
`;

export const ButtonCancel = styled(IconButton)`
    color: #fff;
    background-color: #fff;
    padding: 0;
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 1;

    &:hover {
        background-color: #fff;
    }
`;

export const IconCancel = styled(CancelIcon)`
    color: ${(props) => props.theme.palette.button.tertiary.light[0]};
    font-size: 30px;
`;

export const GroupFile = styled.div`
    position: relative;
`;

export const FileName = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    text-decoration: none;
`;

export const NameAttachment = styled.span`
    font-size: 14px;
    font-weight: 600;
    color: ${(props) => props.theme.palette.primary.dark};
`;
