import React, { Suspense, lazy } from 'react';
import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';

import { setPrefix } from 'utils';

import Loading from 'components/loading';

const Layout = lazy(() => import('components/layout'));

const Suppliers = lazy(() => import('pages/modules/dueDiligence/home'));
const RegistrationSupplier = lazy(() => import('pages/registrationSupplier'));
const Questionnaires = lazy(() => import('pages/modules/dueDiligence/questionnaires'));
const Quiz = lazy(() => import('pages/modules/dueDiligence/quiz'));
const Profile = lazy(() => import('pages/profile'));
const Page404 = lazy(() => import('pages/404'));

// Rotas publicas
const ExternalQuiz = lazy(() => import('pages/modules/lgpd/externalQuiz'));

const Supplier: React.FC = () => (
    <Suspense fallback={<Loading />}>
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<Navigate to={setPrefix('/due-diligence')} replace />} />
                    <Route
                        path={setPrefix('/registration-supplier/:uuid/:user_id')}
                        element={<RegistrationSupplier />}
                    />
                    <Route path={setPrefix('/due-diligence')} element={<Suppliers />} />
                    <Route
                        path={setPrefix('/due-diligence/questionnaires/:supplier_id')}
                        element={<Questionnaires />}
                    />
                    <Route
                        path={setPrefix('/due-diligence/questionnaires/:supplier_id/quiz/:quiz_id')}
                        element={<Quiz />}
                    />
                    <Route path={setPrefix('/profile')} element={<Profile />} />
                    <Route path={setPrefix('/registration-lgpd-quiz/:user_id/:uuid')} element={<ExternalQuiz />} />
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </Layout>
        </Router>
    </Suspense>
);

export default Supplier;
