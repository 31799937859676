import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function useFilter() {
    const { t: translate } = useTranslation();

    const optionClassification = useMemo(
        () => [
            { title: translate('classification.integrity'), value: 'integrity' },
            { title: translate('classification.quality'), value: 'quality' },
        ],
        [translate],
    );

    const optionStatus = useMemo(
        () => [
            { title: translate('status.under_analysis'), value: '2' },
            { title: translate('status.pending'), value: '3' },
            { title: translate('status.finished'), value: '4' },
            { title: translate('status.open'), value: '5' },
        ],
        [translate],
    );

    return {
        optionStatus,
        optionClassification,
        translate,
    };
}
