import React, { memo } from 'react';

import * as Component from './styled';

interface Props {
    type?: 'text' | 'email' | 'number' | 'password' | 'date' | 'time';
    size?: 'small' | 'medium';
    mask?: string | null;
    variant?: any;
    color?: 'primary' | 'secondary';
    className?: string;
    multiline?: boolean;
    minRows?: number;
    maxRows?: number;
    label: string;
    placeholder?: string;
    name?: string;
    value: string;
    error?: any;
    helperText?: any;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputMaskCpf: React.FC = (props: any) => {
    const { inputRef, ...other } = props;
    return (
        <Component.MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
};

const InputMaskCnpj: React.FC = (props: any) => {
    const { inputRef, ...other } = props;
    return (
        <Component.MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '/',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
            ]}
            placeholderChar={'\u2000'}
        />
    );
};

const InputMaskCep: React.FC = (props: any) => {
    const { inputRef, ...other } = props;
    return (
        <Component.MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
};

const InputMaskDate: React.FC = (props: any) => {
    const { inputRef, ...other } = props;
    return (
        <Component.MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
};

const InputMaskTime: React.FC = (props: any) => {
    const { inputRef, ...other } = props;
    return (
        <Component.MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
};

const inputMask = (mask: string) => {
    if (mask === 'cpf') {
        return InputMaskCpf;
    }
    if (mask === 'cnpj') {
        return InputMaskCnpj;
    }
    if (mask === 'cep') {
        return InputMaskCep;
    }
    if (mask === 'date') {
        return InputMaskDate;
    }
    if (mask === 'time') {
        return InputMaskTime;
    }
};

const InputText: React.FC<Props> = ({
    type,
    size,
    mask,
    multiline,
    minRows,
    maxRows,
    variant,
    className,
    color,
    label,
    placeholder,
    name,
    value,
    error,
    helperText,
    disabled,
    onChange,
}) => {
    if (mask) {
        return (
            <Component.InputCustom
                type={type ?? 'text'}
                size={size ?? 'medium'}
                multiline={!!multiline}
                maxRows={maxRows ?? ''}
                minRows={minRows ?? ''}
                variant={variant ?? 'outlined'}
                className={className ?? ''}
                color={color}
                label={label}
                name={name}
                value={value}
                onChange={onChange}
                error={error}
                helperText={helperText}
                autoComplete="off"
                disabled={disabled}
                InputProps={{
                    inputComponent: inputMask(mask),
                }}
            />
        );
    }

    return (
        <Component.InputCustom
            type={type ?? 'text'}
            size={size ?? 'medium'}
            multiline={!!multiline}
            maxRows={maxRows ?? ''}
            minRows={minRows ?? ''}
            variant={variant ?? 'outlined'}
            className={className ?? ''}
            color={color}
            label={label}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            error={error}
            helperText={helperText}
            autoComplete="off"
            disabled={disabled}
        />
    );
};

export default memo(InputText);
