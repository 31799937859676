import styled from 'styled-components';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';

export const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 20px;
`;

export const Label = styled.span`
    display: block;
    color: #fff;
    font-size: 14px;
    font-size: 600;
`;

export const Group = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 8px;
`;

export const Progress = styled.svg.attrs({
    viewBox: '0 0 42 42',
})`
    width: 45px;
    height: 45px;
`;

export const Circle = styled.circle.attrs({
    cx: '21',
    cy: '21',
    r: '15.91549430918954',
})<{ strokeDasharray?: string }>`
    fill: transparent;
    stroke: ${(props) => (props.strokeDasharray ? '#1db954' : '#6b6b6b')};
    stroke-dashoffset: ${(props) => (props.strokeDasharray ? 25 : 0)};
    stroke-width: 2px;
`;

export const IconTrophy = styled(EmojiEventsIcon)`
    color: #5a5a5a;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
