import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import * as Component from './styled';

interface Props {
    value: number;
}

const CourseProgress: React.FC<Props> = ({ value }) => {
    const { t: translate } = useTranslation();
    return (
        <Component.Container>
            <Component.Label>{translate('navbar.progress')}</Component.Label>
            <Component.Group>
                <Component.Progress>
                    <Component.Circle />
                    <Component.Circle strokeDasharray={`${value} ${100 - value}`} />
                </Component.Progress>
                <Component.IconTrophy />
            </Component.Group>
        </Component.Container>
    );
};

export default memo(CourseProgress);
