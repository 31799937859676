import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { PropsQuiz, PropsErrorQuiz } from 'pages/modules/ead/course/models';

import * as Component from './styled';

interface PropsQuestion extends PropsQuiz {
    questionNumber: number;
    errors: PropsErrorQuiz[];
    onChange(
        event: React.ChangeEvent<HTMLInputElement>,
        name: string,
        fake_question_id: number,
        fake_alternative_id?: number,
    ): void;
    onAddAlternative(fake_question_id: number): void;
    onRemoveQuestion(question_id: number | null, fake_question_id: number, text: string): void;
    onRemoveAternative(
        is_correct: boolean,
        fake_question_id: number,
        fake_alternative_id: number,
        alternative_id: number | null,
        text: string,
    ): void;
}

const Question: React.FC<PropsQuestion> = ({
    questionNumber,
    id,
    fake_id,
    question,
    type,
    alternatives,
    errors,
    onChange,
    onAddAlternative,
    onRemoveQuestion,
    onRemoveAternative,
}) => {
    const { t: translate } = useTranslation();

    return (
        <Component.Wrap>
            <Component.Header>
                <Component.Label>
                    {translate('modal.quizDetailsEad.form.numberQuestion', {
                        number: questionNumber + 1,
                    })}
                </Component.Label>
                <Component.Button
                    color="primary"
                    onClick={() =>
                        onRemoveQuestion(
                            id,
                            fake_id,
                            translate('modal.quizDetailsEad.form.numberQuestion', { number: questionNumber + 1 }),
                        )
                    }
                >
                    <Component.IconRemove />
                    {translate('modal.quizDetailsEad.button.removeQuestion')}
                </Component.Button>
            </Component.Header>
            <Component.FormGroup>
                <Component.InputText
                    color="primary"
                    label={translate('modal.quizDetailsEad.form.question')}
                    name={`question_${fake_id}`}
                    multiline
                    minRows={4}
                    maxRows={8}
                    value={question}
                    onChange={(event) => onChange(event, 'question', fake_id)}
                    error={Boolean(errors?.find((error) => error.id === fake_id)?.question)}
                    helperText={errors?.find((error) => error.id === fake_id)?.question}
                />
            </Component.FormGroup>
            <Component.FormGroup>
                <Component.InputSelect
                    color="primary"
                    label={translate('modal.quizDetailsEad.form.typesOfAnswers')}
                    name={`type_${fake_id}`}
                    options={[
                        {
                            title: translate('modal.quizDetailsEad.form.optionAlternatives'),
                            value: '11',
                        },
                    ]}
                    value={type}
                    onChange={(event: React.ChangeEvent<any>) => onChange(event, 'type', fake_id)}
                />
            </Component.FormGroup>
            <Component.AllAlternatives
                error={
                    errors?.find((error) => error.id === fake_id)?.is_correct !== undefined &&
                    errors?.find((error) => error.id === fake_id)?.is_correct !== ''
                        ? 'true'
                        : 'false'
                }
            >
                {errors?.find((error) => error.id === fake_id)?.is_correct !== undefined &&
                    errors?.find((error) => error.id === fake_id)?.is_correct !== '' && (
                        <Component.LabelError>
                            {errors?.find((error) => error.id === fake_id)?.is_correct}
                        </Component.LabelError>
                    )}
                {alternatives?.map((item, index) => (
                    <Component.FormGroup key={item.fake_id}>
                        <Component.Group isCorrect={String(item.is_correct)}>
                            <Component.InputText
                                color="primary"
                                label={translate('modal.quizDetailsEad.form.alternative', { number: index + 1 })}
                                name={`description_${item.fake_id}`}
                                value={item.description}
                                onChange={(event) => onChange(event, 'description', fake_id, item.fake_id)}
                                error={Boolean(
                                    errors
                                        ?.find((error) => error.id === fake_id)
                                        ?.alternatives?.find((alternative) => alternative.id === item.fake_id)
                                        ?.alternative,
                                )}
                            />
                            <Component.InputRadio
                                color="primary"
                                name="is_correct"
                                checked={item.is_correct}
                                value={item.fake_id}
                                onChange={(event: any) => onChange(event, 'is_correct', fake_id, item.fake_id)}
                            />
                            <Component.Button
                                color="primary"
                                onClick={() =>
                                    onRemoveAternative(
                                        item.is_correct,
                                        fake_id,
                                        item.fake_id,
                                        item.id,
                                        item.description ||
                                            translate('modal.quizDetailsEad.form.alternative', {
                                                number: index + 1,
                                            }),
                                    )
                                }
                                disabled={item.is_correct}
                            >
                                <Component.IconRemove />
                            </Component.Button>
                        </Component.Group>
                    </Component.FormGroup>
                ))}
            </Component.AllAlternatives>
            <Component.FormGroup>
                <Component.Button color="secondary" onClick={() => onAddAlternative(fake_id)}>
                    <Component.IconAdd />
                    {translate('modal.quizDetailsEad.form.addAlternative')}
                </Component.Button>
            </Component.FormGroup>
        </Component.Wrap>
    );
};

export default memo(Question);
