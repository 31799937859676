import React, { memo, ChangeEvent } from 'react';

import { TypeStatus } from 'models';
import useFilterSyndication from './useFilterSyndication';

import * as Component from './styled';

interface PropsFields {
    description: string;
    status: string;
    period: boolean;
    startDate: string;
    endDate: string;
}

interface PropsFieldsError {
    description: string;
    status: string;
    startDate: string;
    endDate: string;
}

interface PropsFilter {
    message?: string;
    open: boolean;
    status: TypeStatus;
    fields: PropsFields;
    error: PropsFieldsError;
    onClose: () => void;
    onChange: (event: ChangeEvent<any>, checkbox?: boolean) => void;
    onFilter: (event: ChangeEvent<HTMLFormElement>) => void;
    onClearFilter: () => void;
}

const FilterSyndication: React.FC<PropsFilter> = ({
    message,
    open,
    fields,
    error,
    status,
    onClose,
    onChange,
    onFilter,
    onClearFilter,
}) => {
    const { translate } = useFilterSyndication();

    const modalContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <Component.ModalStatus>
                        <Component.Loading color="secondary" />
                    </Component.ModalStatus>
                );
            case 'success':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconSuccess />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );
            case 'error':
                return (
                    <Component.ModalStatus>
                        <Component.ModalGroupMessage>
                            <Component.IconError />
                            <Component.ModalTextSuccess>{message}</Component.ModalTextSuccess>
                        </Component.ModalGroupMessage>
                    </Component.ModalStatus>
                );

            default:
                return (
                    <>
                        <Component.ModalTitle>{translate('modal.filter.title')}</Component.ModalTitle>
                        <Component.ModalBody>
                            <Component.Form onSubmit={onFilter}>
                                <Component.FormGroup>
                                    <Component.InputTextSearch
                                        color="primary"
                                        label={translate('modal.filter.searchDescription')}
                                        name="description"
                                        value={fields.description}
                                        onChange={onChange}
                                        error={!!error.description}
                                    />
                                </Component.FormGroup>
                                <Component.FormGroup>
                                    <Component.InputSelect
                                        color="primary"
                                        label={translate('modal.filter.selectStatus')}
                                        name="status"
                                        options={[
                                            { title: translate('status.open'), value: '22' },
                                            { title: translate('status.in_progress'), value: '20' },
                                            { title: translate('status.finished'), value: '21' },
                                        ]}
                                        value={fields.status}
                                        onChange={(event) => onChange(event, false)}
                                        error={Boolean(error.status)}
                                    />
                                </Component.FormGroup>
                                <Component.FormGroup>
                                    <Component.FormControlLabel
                                        control={
                                            <Component.Switch
                                                color="secondary"
                                                checked={fields.period}
                                                onChange={(event) => onChange(event, true)}
                                                name="period"
                                            />
                                        }
                                        label={translate('modal.filter.selectPeriod')}
                                    />
                                </Component.FormGroup>
                                <Component.FormGroup>
                                    <Component.InputDateTime
                                        type="date"
                                        color="primary"
                                        label={translate(`modal.filter.${fields.period ? 'start' : 'dataSyndication'}`)}
                                        name="startDate"
                                        value={fields.startDate}
                                        onChange={onChange}
                                        error={!!error.startDate}
                                    />
                                </Component.FormGroup>
                                {fields.period && (
                                    <Component.FormGroup>
                                        <Component.InputDateTime
                                            type="date"
                                            color="primary"
                                            label={translate('modal.filter.end')}
                                            name="endDate"
                                            value={fields.endDate}
                                            onChange={onChange}
                                            error={!!error.endDate}
                                        />
                                    </Component.FormGroup>
                                )}
                                <Component.FormGroup>
                                    <Component.ModalActions>
                                        <Component.Button type="button" onClick={onClearFilter} color="primary">
                                            {translate('modal.button.removeFilter')}
                                        </Component.Button>
                                        <Component.Button type="submit">
                                            {translate('modal.button.send')}
                                        </Component.Button>
                                    </Component.ModalActions>
                                </Component.FormGroup>
                            </Component.Form>
                        </Component.ModalBody>
                    </>
                );
        }
    };

    return (
        <Component.Modal open={open} onClose={onClose} aria-labelledby="alert-dialog-title">
            {modalContent()}
        </Component.Modal>
    );
};

export default memo(FilterSyndication);
